import axiosInstance from './axiosInstance';

class GroupService {
  async addGroup(groupDetail) {
    try {
      const response = await axiosInstance.post('/api/v1/group/createGroup', groupDetail);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getAllGroups(page, size) {
    try {
      const response = await axiosInstance.get(`/api/v1/group/getAllGroups?pageNo=${page}&pageSize=${size}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getGroupById(id) {
    try {
      const response = await axiosInstance.get(`/api/v1/group/getGroupById?groupId=${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }
}
export default new GroupService();
