import axios from 'axios';

const socialLogin = async (requestDto) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/social/token`, requestDto);
    localStorage.setItem('token', response.data.accessToken);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

export default socialLogin;
