import axiosInstance from './axiosInstance';

class ProfileService {
  async addProfile(profileDetail) {
    try {
      const response = await axiosInstance.post('/api/v1/profile/addProfile', profileDetail);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getAllProfile(page, size) {
    try {
      const response = await axiosInstance.get(`/api/v1/profile/getAllProfile?pageNumber=${page}&pageSize=${size}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getProfile(id) {
    try {
      const response = await axiosInstance.get(`/api/v1/profile/getProfile/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }
}

export default new ProfileService();
