import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highstock';

// Import our demo components
import { CircularProgress } from '@mui/material';
import PieChart from 'highcharts-react-official';
import PropTypes from 'prop-types';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';

const stockOptions = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    width: 450,
  },
  title: {
    text: 'Automation Summary',
    align: 'center',
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  credits: false,
  exporting: false,
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  colors: ['green', 'red', 'yellow', 'orange'],
};

const suitesOption = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    width: 70,
    height: 70,
    margin: [0, 0, 0, 0],
    spacing: [0, 0, 0, 0],
  },
  title: {
    text: '',
    align: 'center',
  },
  tooltip: false,
  credits: false,
  exporting: false,
  plotOptions: {
    pie: {
      innerSize: '75%',
      dataLabels: {
        enabled: false,
      },
      showInLegend: false,
      cutout: '10%',
      size: '100%',
    },
  },
  colors: ['#5FD726', '#FF0000', '#FFC72B', '#000000'], // Green, Red, Yellow, Black
};

function DonutChart(props) {
  const {
    donutChartData,
    donutChartPlayWrightData,
    donutChartCypressData,
    donutChartSuitesData,
    dashbaordsuitesdata,
    loading,
  } = props;

  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });

  const [chartData, setChartData] = useState(null);
  let data = [];
  if (JSON.stringify(donutChartSuitesData) !== '{}') {
    data = [
      { name: 'Passed', y: donutChartSuitesData?.passed || 0 },
      { name: 'Failed', y: donutChartSuitesData?.failed || 0 },
      { name: 'Skipped', y: donutChartSuitesData?.skipped || 0 },
      { name: 'Ignored', y: donutChartSuitesData?.ignored || 0 },
    ];
  } else if (JSON.stringify(donutChartPlayWrightData) !== '{}') {
    data = [
      { name: 'Passed', y: donutChartPlayWrightData?.passed || 0 },
      { name: 'Failed', y: donutChartPlayWrightData?.failed || 0 },
      { name: 'Flaky', y: donutChartPlayWrightData?.flaky || 0 },
      { name: 'Skip', y: donutChartPlayWrightData?.skipped || 0 },
    ];
  } else if (JSON.stringify(donutChartCypressData) !== '{}') {
    data = [
      {
        name: 'Passed', y: donutChartCypressData?.passed, sliced: true, selected: true,
      },
      { name: 'Failed', y: donutChartCypressData?.failed },
      { name: 'Pending', y: donutChartCypressData?.pending },
      { name: 'Skipped', y: donutChartCypressData?.skipped },
    ];
  } else if (JSON.stringify(dashbaordsuitesdata) !== '{}') {
    data = [
      {
        name: 'Passed', y: dashbaordsuitesdata?.passed || 0, sliced: false, selected: false,
      },
      {
        name: 'Failed', y: dashbaordsuitesdata?.failed || 0, sliced: false, selected: false,
      },
      {
        name: 'Skipped', y: dashbaordsuitesdata?.skipped || 0, sliced: false, selected: false,
      },
      {
        name: 'Ignored', y: dashbaordsuitesdata?.ignored || 0, sliced: false, selected: false,
      },
    ];
  } else {
    data = [
      {
        name: 'Passed', y: donutChartData?.passed, sliced: true, selected: true,
      },
      { name: 'Failed', y: donutChartData?.failed },
      { name: 'Skip', y: 0 },
      { name: 'Ignore', y: 0 },
    ];
  }

  useEffect(() => {
    const options = (JSON.stringify(donutChartSuitesData) !== '{}' || JSON.stringify(donutChartPlayWrightData) !== '{}' || JSON.stringify(dashbaordsuitesdata) !== '{}') ? suitesOption : stockOptions;
    setChartData({
      ...options,
      series: [{
        name: 'Ratio',
        colorByPoint: true,
        data,
      }],
    });
  }, [donutChartData, donutChartPlayWrightData,
    donutChartSuitesData, donutChartCypressData, dashbaordsuitesdata]);

  return (
    <div>
      {loading ? <CircularProgress />
        : <PieChart
            highcharts={Highcharts}
            options={chartData}
        />}
      {snackbar?.state && (
        <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
      )}
    </div>
  );
}

DonutChart.propTypes = {
  donutChartData: PropTypes.shape({
    passed: PropTypes.number,
    failed: PropTypes.number,
  }),
  donutChartPlayWrightData: PropTypes.shape({
    passed: PropTypes.number,
    failed: PropTypes.number,
    skipped: PropTypes.number,
    flaky: PropTypes.number,
  }),
  donutChartCypressData: PropTypes.shape({
    passed: PropTypes.number,
    failed: PropTypes.number,
    skipped: PropTypes.number,
    pending: PropTypes.number,
  }),
  donutChartSuitesData: PropTypes.shape({
    passed: PropTypes.number,
    failed: PropTypes.number,
    skipped: PropTypes.number,
    ignored: PropTypes.number,
  }),
  dashbaordsuitesdata: PropTypes.shape({
    passed: PropTypes.number,
    failed: PropTypes.number,
    skipped: PropTypes.number,
    ignored: PropTypes.number,
  }),
  loading: PropTypes.bool,
};

DonutChart.defaultProps = {
  donutChartData: {},
  donutChartPlayWrightData: {},
  donutChartCypressData: {},
  donutChartSuitesData: {},
  dashbaordsuitesdata: {},
  loading: false,
};

export default DonutChart;
