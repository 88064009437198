import { styled } from '@mui/styles';
import { Box } from '@mui/material';

const Boxstyle = styled(Box)(() => ({
  background: 'rgb(0 0 0 / 62%)',
  position: 'absolute',
  right: '0',
  left: '0',
  top: '0',
  bottom: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '9999',
}));

export default Boxstyle;
