import React from 'react';
import {
  Breadcrumbs,
  Typography,
  Box,
  Icon,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import useStyles from './style';
import route from '../../utils/route';

function Breadcrumb(props) {
  const { data } = props;
  const styles = useStyles();
  const navigate = useNavigate();
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      className={styles.routeBreadcrumb}
    >
      <Box
        className={styles.routeBreadcrumbBox}
      >
        <Icon className={styles.routeBreadcrumbHomeICon}>home</Icon>
        <Typography
          className={styles.dashboardTypography}
          onClick={() => { navigate(`/${route.ROUTE_DASHBOARD}`); }}
        >
          Dashboard
        </Typography>
      </Box>
      {
        data.map((val) => (
          <Box
            className={styles.routeBreadcrumbBox}
            key={val}
          >
            {
            val === 'Dashboard' && <Icon className={styles.routeBreadcrumbHomeICon}>home</Icon>
            }
            <Typography
              className={val === 'Dashboard' ? styles.dashboardTypography : styles.reportsTypography}
            >
              {val}
            </Typography>
          </Box>
        ))
    }
    </Breadcrumbs>
  );
}

Breadcrumb.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.string,
  ),
};
Breadcrumb.defaultProps = {
  data: ['Dashboard'],
};

export default Breadcrumb;
