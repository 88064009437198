import { styled } from '@mui/material/styles';
import {
  Button,
} from '@mui/material';

const BelowForgotText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  textAlign: 'center',
  fontSize: theme.fontSize.subHeaderFontSize,
  marginTop: theme.spacing(2),
  color: theme.palette.text.ternary,
}));
const LogoBox = styled('div')(({ theme }) => ({
  height: '100vh',
  background: theme.palette.primary.main,
}));
const ButtonForgotText = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(0.6),
  textTransform: 'none',
}));
const LoginFormBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0.6),
    display: 'grid',
    width: 'auto',
    justifyContent: 'flex-start',
    alignItems: 'start',
  },
  display: 'block',
  textAlign: '-webkit-center',
  justifyContent: 'center',
}));
const WelcomeTextStyling = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.primary.main,
  textAlign: 'center',
  display: 'block',
  fontSize: theme.fontSize.headerFontSize,
}));
const TextFieldBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
  margin: theme.spacing(5, 5, 0, 5),
}));
const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  justifyContent: 'center',
  width: '100%',
  margin: theme.spacing(3, 5, 0, 5),
  background: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  '&:hover': {
    background: theme.palette.primary.main,
  },
}));
const BelowForgotBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    justifyContent: 'center',
  },
  display: 'flex',
  margin: theme.spacing(0, 5),
  justifyContent: 'center',
}));
export {
  BelowForgotText,
  BelowForgotBox,
  LogoBox,
  ButtonForgotText,
  LoginFormBox,
  WelcomeTextStyling,
  TextFieldBox,
  CustomButton,
};
