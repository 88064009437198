import React, { useState, useEffect } from 'react';
import {
  TextField,
  Box,
  Grid,
  CircularProgress,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  WelcomeTextStyling,
  ButtonStyle,
} from './style';
import companyService from '../../services/company.service';
import route from '../../utils/route';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';

function CompanyEdit() {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [companydetail, setCompanyDetail] = useState({
    companyName: '',
    companyId: id,
  });
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  useEffect(() => {
    async function getCompanyDetail() {
      try {
        setLoading(true);
        const res = await companyService.getCompany(id);
        setCompanyDetail(res);
      } catch (err) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${err.message}`,
        });
      } finally {
        setLoading(false);
      }
    }
    getCompanyDetail();
  }, [id]);
  async function upadteCompany(values) {
    try {
      setLoading(true);
      await companyService.updateCompany(values);
    } catch (err) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err.message}`,
      });
    } finally {
      setLoading(false);
    }
  }
  const handleClick = () => {
    navigate(`/${route.ROUTE_COMPANIES}`);
  };
  const companyName = RegExp(/^[A-Za-z0-9 ]*$/);
  const signUpSchema = Yup.object({
    companyName: Yup.string().matches(companyName, 'Please enter valid Company name!').max(30, 'Should be 15 or less than 15 characters')
      .required('Company name is required!'),
  });
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: companydetail,
    validationSchema: signUpSchema,
    onSubmit: () => {
      upadteCompany(values);
    },
    enableReinitialize: true,
  });
  return (
    <>
      {
        location.pathname === `/companyEdit/${id}` && (
          <Box
            sx={{
              marginTop: '100px',
            }}
          >
            <Grid item xs={7}>
              <WelcomeTextStyling> Company Create </WelcomeTextStyling>
            </Grid>
            <Grid
              container
              rowSpacing={3}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: '9px' }}
            >
              <Grid item xs={7}>
                <TextField
                  label="Company name *"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.companyName}
                  helperText={errors.companyName}
                  placeholder="New Company"
                />
              </Grid>
              <Grid item xs={7}>
                {loading && <CircularProgress style={{ marginLeft: '600px' }} />}
                {!loading && <ButtonStyle variant="contained" onClick={handleSubmit}> Update </ButtonStyle>}
                <ButtonStyle variant="contained" onClick={() => handleClick()}> Back </ButtonStyle>
              </Grid>
            </Grid>
            {
              snackbar.state
              && (
                <NavigateSnackbar
                  snackbarObj={snackbar}
                  setSnackbar={setSnackbar}
                />
              )
            }
          </Box>

        )
      }
    </>
  );
}

export default CompanyEdit;
