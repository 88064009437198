import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import WelcomeTextStyling from './style';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import Loader from '../../utils/loader/Loader';
import projectService from '../../services/project.service';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '1rem',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function UserInviteProject() {
  const [loading, setLoading] = useState(false);
  const [userProjects, setUserProjects] = useState([]);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });

  const getUserProjects = async () => {
    try {
      setLoading(true);
      const response = await projectService.getProjectListByUser();
      setUserProjects(response);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getUserProjects();
  }, []);

  const viewProject = (id) => {
    if (id) {
      navigate(`/viewProject/${id}`);
    }
  };

  return (
    <>
      <Box
        sx={{
          marginTop: '100px',
          p: 4,
          background: '#f5f5f5',
        }}
      >
        <Grid item xs={7}>
          <WelcomeTextStyling style={{ textAlign: 'left' }}> User Invite Project</WelcomeTextStyling>
        </Grid>

        {userProjects && <TableContainer component={Paper}>
          <Table aria-label="customized table">
            {loading ? <Loader /> : <TableHead>
              <TableRow>
                <StyledTableCell>Project Name</StyledTableCell>
                <StyledTableCell>Projects</StyledTableCell>
              </TableRow>
            </TableHead>}
            <TableBody>
              <>
                {
                  userProjects && userProjects?.map((item) => (
                    <StyledTableRow key={item.inviteUserHistoryId}>
                      <StyledTableCell style={{ width: '36%' }}>{item?.projectName}</StyledTableCell>
                      <StyledTableCell style={{ width: '28%' }}>
                        <Button
                          style={{
                            cursor: 'pointer',
                            marginRight: '30px',
                          }}
                          startIcon={<CheckIcon />}
                          color="info"
                          variant="outlined"
                          onClick={() => viewProject(item?.projectId)}
                        >
                          Show Project
                        </Button>
                      </StyledTableCell>

                    </StyledTableRow>
                  ))
                }
                {
                  snackbar.state
                  && (
                    <NavigateSnackbar
                      snackbarObj={snackbar}
                      setSnackbar={setSnackbar}
                    />
                  )
                }
              </>
            </TableBody>
          </Table>
        </TableContainer>}
      </Box>

      {
        snackbar.state
        && (
          <NavigateSnackbar
            snackbarObj={snackbar}
            setSnackbar={setSnackbar}
          />
        )
      }
    </>
  );
}

export default UserInviteProject;
