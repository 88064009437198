/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import dayjs from 'dayjs';
import { CircularProgress, Typography } from '@mui/material';

const defaultOptions = {
  chart: {
    type: 'line',
    marginTop: 70,
  },
  title: {
    text: '',
  },
  responsive: true,
  maintainAspectRatio: false,
  credits: false,
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      format: '{value}',
    },
    gridLineWidth: 1,
  },
  legend: {
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'top',
    floating: true,
    x: 0,
    y: -10,
    borderWidth: 0,
    itemStyle: {
      fontWeight: 'normal',
      fontSize: 15,
    },
  },
  tooltip: {
    shared: true,
  },
  plotOptions: {
    line: {
      dataLabels: {
        enabled: false,
      },
      enableMouseTracking: true,
    },
  },
};

const LineChart = ({ chartType, chartData, loading }) => {
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (chartData) {
      let series = [];
      let xAxis = {};

      switch (chartType) {
        case 'buildStability':
          xAxis = {
            categories: chartData?.map((item) => dayjs(item.date).format('D MMM')),
            gridLineWidth: 1,
          };
          series = [
            {
              name: 'Overall',
              data: chartData?.map((item) => item.overall),
              color: '#1D6AE5', // blue
              marker: {
                symbol: 'circle',
                radius: 5,
              },
              connectNulls: false,
            },
            {
              name: 'Passed',
              data: chartData?.map((item) => item.passed),
              color: '#5FD726', // green
              marker: {
                symbol: 'circle',
                radius: 5,
              },
              connectNulls: false,
            },
            {
              name: 'Failed',
              data: chartData?.map((item) => item.failed),
              color: '#FF0000', // red
              marker: {
                symbol: 'circle',
                radius: 5,
              },
              connectNulls: false,
            },
          ];
          break;

        case 'flakiness':
          xAxis = {
            categories: chartData?.graphData?.map((item) => dayjs(item.date).format('D MMM')),
            gridLineWidth: 1,
          };
          series = [
            {
              name: 'Flakiness',
              data: chartData?.graphData?.map((item) => item.flakiness),
              color: '#5FD726', // green
              marker: {
                symbol: 'circle',
                radius: 5,
              },
              connectNulls: false,
            },
          ];
          break;

        default:
          break;
      }

      setChartOptions({
        ...defaultOptions,
        chart: {
          type: 'line',
          marginTop: chartType === 'flakiness' ? 40 : defaultOptions.chart.marginTop,
          height: chartType === 'flakiness' ? 340 : undefined,
        },
        series: [...series],
        xAxis: { ...xAxis },
      });
    }
  }, [chartData, chartType]);

  return <div style={{ width: '100%', textAlign: 'center' }}>
    {loading ? (
      <CircularProgress />
    ) : (
      <>
        {chartType === 'flakiness' && chartData && (
          <div style={{ textAlign: 'left', marginBottom: '5px' }}>
            <div style={{ fontSize: '15px', fontWeight: '400', marginBottom: '2px' }}>
              Average Flakiness
            </div>
            <Typography fontWeight={600} fontSize={25} variant="body2">
              {`${chartData?.average}%`}
              <span style={{ fontSize: '15px', fontWeight: '400', marginLeft: '8px' }}>
                {`${chartData?.totalFlakiness}/${chartData?.totalTest} test executions are flaky.`}
              </span>
            </Typography>
          </div>
        )}
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </>
    )}
  </div>;
};

export default LineChart;
