/* eslint-disable react/forbid-prop-types */
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  Paper,
  Box,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import CircleIcon from '@mui/icons-material/Circle';
import { renderAccordinForAttachment, renderAccordionForTest, renderScreenShots } from './ReportListAttachments';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
const useStyles = makeStyles((theme) => ({
  testSummmaryBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    border: '1px solid #E1E1E1',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
  },
  title: {
    fontSize: '17px',
    fontWeight: '600',
  },
  stepTitle: {
    display: 'flex',
    alignItems: 'baseline',
  },
  testWrapper: {
    width: '100%',
    padding: theme.spacing(1, 2),
    border: '1px solid #E1E1E1',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconError: {
    fontSize: '12px',
    color: '#FF0000',
    marginRight: theme.spacing(1),
  },
  iconSuccess: {
    fontSize: '12px',
    color: '#5FD726',
    marginRight: theme.spacing(1),
  },
}));
function RenderReportList(props) {
  const {
    isFailed,
    title,
    description,
    resultStep,
  } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const textWithInlineStyle = (text) => {
    const textStyle = 'font-size: 12px; color: #333; font-family: graphik_regularregular; word-break: break-word';
    const modifiedComment = `<div style="${textStyle}">${text}</div>`;

    return modifiedComment;
  };

  return (
    <>
      <Paper className={classes.testSummmaryBox}>
        <Box>
          <Box>
            <Typography className={classes.title} variant="h6">{title}</Typography>
          </Box>
          <Box>
            <Card className={classes.testWrapper}>
              <CardContent
                sx={{
                  maxHeight: !expanded ? '120px' : '380px',
                  transition: 'max-height 1s ease',
                  overflow: !expanded ? 'hidden' : 'auto',
                }}
              >
                {(title === 'Result Output' || title === 'Description')
                  ? <>
                    {!!resultStep?.length
                      && <>
                        <Box className={classes.stepTitle}>
                          <CircleIcon
                            className={isFailed ? classes.iconError : classes.iconSuccess}
                          />
                          <div
                            dangerouslySetInnerHTML={{ __html: (textWithInlineStyle(resultStep)) }}
                          />
                        </Box>
                      </>}
                    {!!description?.length && <Box className={classes.stepTitle}>
                      <CircleIcon className={isFailed ? classes.iconError : classes.iconSuccess} />
                      <Typography
                        variant="body2"
                        sx={{ color: '#333' }}
                        fontFamily="Space Mono"
                      >
                        {description}
                      </Typography>
                    </Box>}
                  </>
                  : (
                    <>
                      {['Test Steps', 'Attachment', 'ScreenShots'].includes(title) && !!resultStep ? (
                        <>
                          <Box className={classes.stepTitle}>
                            {title === 'Test Steps' && renderAccordionForTest(resultStep)}
                            {title === 'Attachment' && <>
                              <CircleIcon
                                className={isFailed ? classes.iconError : classes.iconSuccess}
                              />
                              {renderAccordinForAttachment(resultStep)}
                            </>}
                            {title === 'ScreenShots' && <>
                              <CircleIcon
                                className={isFailed ? classes.iconError : classes.iconSuccess}
                              />
                              {renderScreenShots(resultStep)}
                            </>}
                          </Box>
                        </>
                      ) : (
                        <>
                          {!!description.length && <Box className={classes.stepTitle}>
                            <CircleIcon
                              className={isFailed ? classes.iconError : classes.iconSuccess}
                            />
                            <Typography
                              variant="body2"
                              sx={{ color: '#333' }}
                              fontFamily="Space Mono"
                            >
                              {description}
                            </Typography>
                          </Box>}
                        </>
                      )}
                    </>
                  )}
              </CardContent>
              <CardActions disableSpacing sx={{ p: 0 }}>
                <div
                  style={{
                    background: '#ffffffc7',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  <ExpandMore
                    expand={expanded}
                    onClick={() => setExpanded(!expanded)}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </div>
              </CardActions>
            </Card>
          </Box>
        </Box>
      </Paper>
    </>
  );
}

RenderReportList.propTypes = {
  isFailed: PropTypes.bool,
  title: PropTypes.string,
  resultStep: PropTypes.any,
  description: PropTypes.string,
};

RenderReportList.defaultProps = {
  isFailed: false,
  title: '',
  resultStep: null,
  description: '',
};

export default RenderReportList;
