/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckIcon from '@mui/icons-material/Check';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TopicIcon from '@mui/icons-material/Topic';
import Typography from '@mui/material/Typography';
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Loader from '../../utils/loader/Loader';
// import { getListByUserId } from '../../services/uploadFile.service';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import { TestStatus } from '../../utils/constants';

import Breadcrumb from '../breadcrumbs/Breadcrumb';
import {
  InputParentGrid,
  ParentBox,
  Item,
  reportStyles,
} from './style';
import FullScreenModal from '../reportList/FullScreenModal';

// eslint-disable-next-line prefer-const
let counter = {
  mount: 0,
  handleChange: 0,
  onTestSuitClick: 0,
};

function ReportId() {
  const [data] = useState([]);
  const [loading] = useState(false);
  const style = reportStyles();
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [active, setActive] = useState(-1);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTests, setSelectedTests] = useState([]);
  const navigate = useNavigate();
  const handleClick = (index) => {
    setActive(index === active ? -1 : index);
  };
  const onTestSuitClick = (tests) => {
    counter.onTestSuitClick += 1;
    setSelectedTests(tests);
    setOpenDrawer(true);
  };

  useEffect(() => {
    navigate('/dashboard');
    // setLoading(true);
    // getListByUserId().then((res) => {
    //   if (res) {
    //     setData(res);
    //     setLoading(false);
    //   }
    // }).catch((err) => {
    //   setSnackbar({
    //     navigateText: '',
    //     severity: 'error',
    //     state: true,
    //     message: `${err.message}`,
    //   });
    // });
  }, []);

  // useEffect(() => {
  //   counter.mount += 1;
  //   setLoading(true);
  //   resultList()
  //     .then((res) => {
  //       if (res) {
  //         setData(res);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setSnackbar({
  //         navigateText: '',
  //         severity: 'error',
  //         state: true,
  //         message: `${err.message}`,
  //       });
  //     });
  // }, []);
  // console.log('counter', counter);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  return (
    <>
      <ParentBox>
        {loading && <Loader />}
        <Grid container>
          <InputParentGrid item xs={12}>
            <Breadcrumb data={['ReportById']} />
          </InputParentGrid>
          {data.length === 0 ? (
            ''
          ) : (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                <Grid>
                  <Item>
                    <Grid container spacing={1}>
                      <Box component="span" sx={{ p: 2 }}>
                        <DescriptionIcon />
                        {`Total - ${data[0]?.testingResults?.total}`}
                      </Box>
                      <Box component="span" sx={{ p: 2 }}>
                        <CheckIcon />
                        {`Passed -${data[0]?.testingResults?.passed}`}
                      </Box>
                      <Box component="span" sx={{ p: 2 }}>
                        <ReportProblemIcon />
                        {`Failed -${data[0]?.testingResults?.failed}`}
                      </Box>
                      <Box component="span" sx={{ p: 2 }}>
                        <TopicIcon />
                        Other
                      </Box>
                    </Grid>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid item sm={12} xs={12}>
          {data.length === 0 ? '' : <h2>Report of the day </h2>}
          {data && data.length !== 0 && (
            <List
              sx={{ width: '100%', bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {data.map((val, index) => {
                if (
                  val.testingResults?.suite?.test && !Array.isArray(val.testingResults?.suite?.test)
                ) {
                  // eslint-disable-next-line no-param-reassign
                  val.testingResults.suite.test = [val.testingResults?.suite?.test];
                }
                return (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton
                        key={val.id}
                        onClick={() => handleClick(index)}
                      >
                        {active !== index ? (
                          <ChevronRightIcon />
                        ) : (
                          <ExpandMore />
                        )}
                        <ListItemIcon>
                          {val?.testingResults?.failed === 0 && (
                            <CheckIcon sx={{ color: 'green', mr: 2 }} />
                          )}
                          {val?.testingResults?.failed !== 0 && (
                            <ReportProblemIcon sx={{ color: 'red', mr: 2 }} />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={val?.testingResults?.suite?.name}
                        />
                        <Typography
                          component="div"
                          className={style.reportHeader}
                        >
                          <Typography
                            component="h1"
                            className={style.reportTime}
                          >
                            Suite
                          </Typography>
                          <BootstrapTooltip title="Passed">
                            <Button
                              variant="contained"
                              className={style.testButton}
                            >
                              {val?.testingResults?.passed}
                            </Button>
                          </BootstrapTooltip>
                          <BootstrapTooltip title="Failed">
                            <Button
                              variant="contained"
                              sx={{ backgroundColor: '#f04438' }}
                              className={reportStyles.failedButton}
                            >
                              {val?.testingResults?.failed}
                            </Button>
                          </BootstrapTooltip>
                          <Button>
                            <BootstrapTooltip title="Show all details">
                              <FormatAlignLeftIcon />
                            </BootstrapTooltip>
                          </Button>
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                    <Collapse
                      in={active === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {val.testingResults?.suite?.test?.map((item, i) => {
                          const methods = item?.class?.['test-method'];
                          const passTest = methods.length ? methods.filter(
                            (a) => a.status === TestStatus.PASS,
                          ) : [];
                          const failedTest = methods.length ? methods.filter(
                            (a) => a.status === TestStatus.FAIL,
                          ) : [];
                          return (
                            <>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => onTestSuitClick(item?.class['test-method'])}
                              >
                                <ListItemIcon>
                                  <SearchIcon className={style.searchicon} />
                                  {failedTest.length ? (
                                    <ReportProblemIcon
                                      className={style.failIcon}
                                    />
                                  ) : (
                                    <CheckIcon sx={{ color: 'green', mr: 2 }} />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  primary={item.name}
                                  secondary={item.class.name}
                                />

                                <Typography
                                  component="div"
                                  className={style.accordianTime}
                                >
                                  <Typography
                                    component="h1"
                                    className={style.accordianHeader}
                                  >
                                    Test
                                  </Typography>
                                  <BootstrapTooltip title="Passed">
                                    <Button
                                      variant="contained"
                                      className={style.testlistButton}
                                    >
                                      {passTest.length}
                                    </Button>
                                  </BootstrapTooltip>
                                  <BootstrapTooltip title="Failed">
                                    <Button
                                      variant="contained"
                                      className={style.failedlistButton}
                                    >
                                      {failedTest.length}
                                    </Button>
                                  </BootstrapTooltip>
                                  <Button>
                                    <BootstrapTooltip title="Show all details">
                                      <FormatAlignLeftIcon />
                                    </BootstrapTooltip>
                                  </Button>
                                </Typography>
                              </ListItemButton>
                              {i !== val.testingResults?.suite?.test?.length - 1 && (
                                <Divider />
                              )}
                            </>
                          );
                        })}
                      </List>
                    </Collapse>
                    {index !== data.length - 1 && <Divider />}
                  </>
                );
              })}
            </List>
          )}
        </Grid>
        {openDrawer && <FullScreenModal
          tests={selectedTests}
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
        />}
        {snackbar.state && (
          <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
        )}
      </ParentBox>
    </>
  );
}

export default ReportId;
