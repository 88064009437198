import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import Tooltip from '@mui/material/Tooltip';
import { HtmlStatus, ReportSchema } from '../../utils/constants';

import {
  ParentBox,
  reportStyles,
} from './style';
import HtmlDescription from '../HtmlDescription';
import { useCommonContext } from '../header/context';
import { parseDate } from '../dashboard/utils';
import Breadcrumb from '../breadcrumbs/Breadcrumb';
import { readableTime } from '../../utils';

const HtmlNotification = ({ data }) => {
  const style = reportStyles();
  const [active, setActive] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTests, setSelectedTests] = useState([]);
  const {
    selectedfilter,
    setSelectedFilter,
    reportType,
  } = useCommonContext();

  const onTestSuitClick = (tests) => {
    setSelectedTests(tests);
    setOpenDrawer(true);
  };
  const renderData = (testData) => {
    let listData = testData;
    if (testData?.test) {
      if (!Array.isArray(testData?.test)) {
        listData = [testData?.test];
      } else {
        listData = testData?.test;
      }
    }
    return listData?.map((details, i) => {
      const originalItem = testData?.test ? details : details?.test;

      const itemArray = Array.isArray(originalItem) ? originalItem : [originalItem];

      return itemArray?.map((item) => {
        const methods = item?.testResultClass?.testMethod;
        const failedTest = methods?.length ? methods.filter(
          (a) => a.status === HtmlStatus.FAILED,
        ) : [];
        if (selectedfilter === HtmlStatus.PASSED && failedTest.length) {
          return null;
        }
        if (selectedfilter === HtmlStatus.FAILED && failedTest.length === 0) {
          return null;
        }

        return (
          <>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => onTestSuitClick(item?.testResultClass?.testMethod)}
              key={item?.name}
            >
              <ListItemIcon>
                <SearchIcon className={style.searchicon} />
                {failedTest.length ? (
                  <ReportProblemIcon
                    className={style.failIcon}
                  />
                ) : (
                  <CheckIcon sx={{ color: 'green', mr: 2 }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={item?.name}
                secondary={item?.class?.name}
              />

              <Typography
                component="div"
                className={style.accordianTime}
              >
                <Typography
                  component="h1"
                  className={style.accordianHeader}
                >
                  Test
                </Typography>
                {
                  failedTest.length ? <Tooltip title="Failed">
                    <Button
                      variant="contained"
                      className={style.failedlistButton}
                    >
                      {failedTest.length}
                    </Button>
                  </Tooltip> : <Tooltip title="Passed">
                    <Button
                      variant="contained"
                      className={style.testlistButton}
                    >
                      1
                    </Button>
                  </Tooltip>
                }
                <Button>
                  <Tooltip title="Show all details">
                    <FormatAlignLeftIcon />
                  </Tooltip>
                </Button>
              </Typography>
            </ListItemButton>
            {i !== listData?.length - 1 && (
              <Divider />
            )}
          </>
        );
      });
    });
  };

  return (
    <>
      <ParentBox>
        <Grid item sm={12} xs={12}>
          <Grid>
            <Breadcrumb data={[ReportSchema[reportType].name]} />
          </Grid>
          {data && (
            <List
              sx={{ width: '100%', bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <>
                <ListItem>

                  <IconButton onClick={() => setActive((pre) => !pre)}>
                    {active ? (
                      <ExpandMore />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                  <ListItemIcon>
                    <SummarizeIcon sx={{ color: '#3f6398', mr: 2 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={data?.testingResults?.suite?.name || 'Smoke test'}
                  />
                  <ListItemText
                    primary={parseDate(data?.createAt)}
                  />
                  <ListItemText
                    primary={
                      readableTime(Math.round((data?.testingResults?.totalTime || 0) / 1000))
                    }
                  />
                  <Typography
                    component="div"
                    className={style.reportHeader}
                  >
                    <Tooltip title="Company">
                      <Typography
                        component="h1"
                        className={style.reportTime}
                      >
                        {data?.companyName}
                      </Typography>
                    </Tooltip>
                    <Tooltip
                      title="Project"
                    >
                      <Typography
                        component="h1"
                        className={style.reportTime}
                      >
                        {data?.projectName}
                      </Typography>
                    </Tooltip>
                    <Tooltip title="Passed">
                      <Grid
                        sx={{
                          backgroundColor: '#fff',
                          border: '11px solid #10b981',
                          borderRadius: '100px',
                          height: '38px',
                          width: '38px',
                          position: 'relative',
                          cursor: 'pointer',
                          ml: 2,
                        }}
                        onClick={() => {
                          setSelectedFilter(HtmlStatus.PASSED);
                          setActive(true);
                        }}
                      >
                        <Grid
                          sx={{
                            position: 'absolute',
                            top: '-21px',
                            right: '-11px',
                            background: '#fff',
                            minWidth: '25px',
                            borderRadius: '9px',
                            border: '1px solid #10b981',
                            fontWeight: 600,
                            textAlign: 'center',
                            padding: '1px',
                          }}
                        >
                          {data?.testingResults?.passed}
                        </Grid>
                      </Grid>
                    </Tooltip>
                    <Tooltip title="Failed">
                      <Grid
                        sx={{
                          backgroundColor: '#fff',
                          border: '11px solid #f04438',
                          borderRadius: '100px',
                          height: '38px',
                          width: '38px',
                          position: 'relative',
                          cursor: 'pointer',
                          ml: 2,
                        }}
                        onClick={() => {
                          setSelectedFilter(HtmlStatus.FAILED);
                          setActive(true);
                        }}
                      >
                        <Grid
                          sx={{
                            position: 'absolute',
                            top: '-21px',
                            right: '-11px',
                            background: '#fff',
                            minWidth: '25px',
                            borderRadius: '9px',
                            border: '1px solid #e93e3d',
                            fontWeight: 600,
                            textAlign: 'center',
                          }}
                        >
                          {data?.testingResults?.failed}
                        </Grid>
                      </Grid>
                    </Tooltip>
                    <Button>
                      <Tooltip title="Show all details">
                        <FormatAlignLeftIcon />
                      </Tooltip>
                    </Button>
                  </Typography>

                </ListItem>
                <Collapse
                  in={active}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {renderData(data?.testingResults?.suite)}
                  </List>
                </Collapse>
              </>
            </List>
          )}
        </Grid>
        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <Box sx={{ width: 600 }} role="presentation">
            <HtmlDescription tests={selectedTests} />
          </Box>
        </Drawer>
      </ParentBox>
    </>
  );
};

HtmlNotification.propTypes = {
  data: PropTypes.func.isRequired,
};

export default HtmlNotification;
