import axios from 'axios';

const fetchGoogleData = async (code) => {
  try {
    const rootURl = 'https://oauth2.googleapis.com/token';
    const options = {
      redirect_uri: process.env.REACT_APP_GREDIRECT_URL,
      client_id: process.env.REACT_APP_GCLIENT_ID,
      grant_type: 'authorization_code',
      code,
    };
    const qs = new URLSearchParams(options);
    const response = await axios.post(rootURl, qs, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${btoa(
          `${process.env.REACT_APP_GCLIENT_ID}:${
            process.env.REACT_APP_GSECRET_KEY
          }`,
        )}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const getGoogleUrl = () => {
  const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
  const options = {
    redirect_uri: process.env.REACT_APP_GREDIRECT_URL,
    client_id: process.env.REACT_APP_GCLIENT_ID,
    access_type: 'offline',
    response_type: 'code',
    prompt: 'consent',
    state: 'GOOGLE',
    scope: [
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/userinfo.email',
    ].join(' '),
  };

  const qs = new URLSearchParams(options);
  return `${rootUrl}?${qs.toString()}`;
};

export { fetchGoogleData, getGoogleUrl };
