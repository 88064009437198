import React from 'react';
import { Box } from '@mui/material';
import PropTypes, { element } from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import Header from '../header/Header';
import ErrorBoundary from '../ErrorBoundary';
import './index.css';
import NewSidePanel from '../sidePanel/NewSidePanel';
import { useStyles } from '../header/style';

function LayoutOutlet({ isAuth, children }) {
  const token = localStorage.getItem('token');
  const location = useLocation();
  const classes = useStyles();
  if (isAuth) {
    return token ? (
      <>
        <div className={classes.root}>
          <NewSidePanel />
          <Header />
          <ErrorBoundary key={location.pathname}>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              <Box sx={{ p: 3, background: '#f5f5f5' }}>
                {children}
              </Box>
            </main>
          </ErrorBoundary>
        </div>
      </>
    ) : <Navigate to="/login" state={window.location.pathname.replace(process.env.REACT_APP_BASE_PATH || '', '')} />;
  }

  return !token ? children : <Navigate to="/home" />;
}

LayoutOutlet.propTypes = {
  children: PropTypes.element,
  isAuth: PropTypes.bool,
};
LayoutOutlet.defaultProps = {
  children: element,
  isAuth: false,
};
export default LayoutOutlet;
