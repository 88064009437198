import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Alert from '@mui/material/Alert';
import { HtmlStatus } from '../../utils/constants';
import { TestPropType } from '../../Types/test';

const TextContainers = ({ icon, title, text }) => (
  <>
    <div style={{ display: 'flex' }}>
      {icon}
      <Typography style={{ color: 'red' }}>{title}</Typography>
    </div>
    <Alert severity="error">{text}</Alert>
  </>
);
const TextContainer = ({ icon, title }) => (
  <>
    <div style={{ display: 'flex' }}>
      {icon}
      <Typography>{title}</Typography>
    </div>
  </>
);
TextContainers.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
TextContainer.propTypes = {
  icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
const AccordionContainer = ({ test }) => {
  const resultStep = test?.reporterOutput?.line;
  if (test?.status === HtmlStatus.PASSED) {
    return (
      <>
        <TextContainer
          title="Description"
          icon={<FormatQuoteIcon />}
        />
        <div dangerouslySetInnerHTML={{ __html: (resultStep) }} />
      </>
    );
  }
  if (test.status === HtmlStatus.PASSED || HtmlStatus.SKIPPED) {
    return (
      <>
        <TextContainers
          title="Exception"
          icon={<FormatQuoteIcon />}
          text={test?.exception}
        />
        <div dangerouslySetInnerHTML={{ __html: (resultStep) }} />

      </>
    );
  }

  return <>Unable to extract data</>;
};

AccordionContainer.propTypes = TestPropType.isRequired;

export default AccordionContainer;
