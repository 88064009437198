import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Chip, CircularProgress, Grid, Link, MenuItem, TextField, TextareaAutosize, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { createJiraTicket, fetchJiraTickets, getAllProjectIssueType } from '../../services/integrations';
import JiraIcon from '../../assets/jira-svgrepo-com.svg';
import { DashboardEnum } from '../../utils/constants';
import CommonUtils from '../../utils/common/common';

const useStyles = makeStyles((theme) => ({
  buttonJira: {
    backgroundColor: '#1d6ae5',
    boxShadow: 'none',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#165bb5',
    },
  },
  inputFeild: {
    border: '1px solid #f0f0f0',
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
    '&:focus-visible': {
      outlineColor: '#f1f1f1',
    },
  },
  titleWrapper: {
    fontWeight: 600,
    fontSize: '17px',
  },
}));
const JiraWarpper = ({
  project,
  test,
  id,
  selectedTestName,
  setSnackbarData,
  jiraTickets,
  setJiraTickets,
  reportType,
}) => {
  const classes = useStyles();
  const [issueTypes, setIssueTypes] = useState([]);
  const [issue, setIssue] = useState('');
  const [createJira, setCreateJira] = useState(false);
  const [ticket, setTicket] = useState(false);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const getIssueTypes = async (projectName) => {
    try {
      const response = await getAllProjectIssueType(projectName);
      setIssueTypes(response);
    } catch (error) {
      console.log(error.data.message);
    }
  };

  const getJiraTickets = async () => {
    setLoading(true);
    const testName = CommonUtils.replaceAmpersandWithAnd(selectedTestName);
    const filterAnd = `testReportId|eq|${id}&projectName|eq|${project}&testReportTestName|eq|${testName}`;
    const encodedFilterAnd = encodeURIComponent(filterAnd);
    const query = `filterAnd=${encodedFilterAnd}`;
    try {
      const response = await fetchJiraTickets(query);
      setJiraTickets(response);
    } catch (error) {
      console.log(error.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleJiraTicket = async () => {
    try {
      setCreateJira(true);
      let payload = '';
      if (reportType === DashboardEnum.SELENIUM) {
        payload = {
          ResultSteps: test?.['reporter-output']?.line,
          Description: test?.signature,
          ExceptionName: test?.exception?.class,
          ExceptionDescription: test?.exception?.message,
          ExceptionBackTrace: test?.exception?.['full-stacktrace'],
        };
      } else if (reportType === DashboardEnum.PLAYWRIGHT) {
        payload = {
          errors: test.errors,
          steps: { ...test.steps },
          attachments: { ...test.attachments },
        };
      }
      const res = await createJiraTicket({
        description: JSON.stringify({
          ...payload,
        }),
        projectName: project,
        summary: description,
        testReportId: id,
        testReportTestName: CommonUtils.replaceAmpersandWithAnd(selectedTestName),
        issueType: issue,
      });
      setTicket(res);
      setSnackbarData({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Raised Issue Successfully ..!',
      });
    } catch (err) {
      setSnackbarData({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err.data.message}`,
      });
    } finally {
      setCreateJira(false);
    }
  };

  useEffect(() => {
    if (project) {
      getIssueTypes(project);
    }
  }, []);

  useEffect(() => {
    if (project) getJiraTickets();
  }, [ticket]);

  if (loading) {
    return <>
      <Box textAlign="center">
        <CircularProgress textAlign="center" />
      </Box>
    </>;
  }
  return (
    <>
      {!jiraTickets?.content?.length && <>
        <Typography
          variant="subtitle1"
          className={classes.titleWrapper}
        >
          Create Jira Ticket
        </Typography>
        <Grid container display="flex" alignItems="center" spacing={2} paddingBlock={2}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              select
              label="Issue Type"
              value={issue}
              InputProps={{
                classes: {
                  root: classes.inputFeild,
                },
              }}
              onChange={(e) => setIssue(e.target.value)}
            >
              {issueTypes.length > 0 ? issueTypes.map((menuitem) => (
                <MenuItem
                  value={menuitem?.id}
                  key={menuitem?.id}
                >
                  {menuitem?.name}
                </MenuItem>
              ))
                : <MenuItem>No Record Found</MenuItem>}
            </TextField>
          </Grid>
          <Grid item xs={8}>
            <TextField
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="outlined"
              fullWidth
              multiline
              label="Description"
              InputProps={{
                inputComponent: TextareaAutosize,
                classes: {
                  root: classes.inputFeild,
                },
                inputProps: {
                  minRows: 1,
                  style: { width: '100%' },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} textAlign="end">
            <Button className={classes.buttonJira} disabled={createJira || !issue || !description} onClick={() => handleJiraTicket()} variant="contained">Create Jira</Button>
          </Grid>
        </Grid>
      </>}
      {jiraTickets?.content?.length > 0
        && <>
          <Typography
            variant="subtitle1"
            fontSize="15px"
            fontWeight="bold"
            fontFamily="Open Sans"
            textAlign="left"
            color="#323232"
            p={1.3}
          >
            Raised Jira Ticket
          </Typography>
          <Box>
            {jiraTickets?.content?.map((item) => (
              <Chip
                label={item.jiraTicketId}
                component={Link}
                href={item?.ticketReturnUrl}
                target="_blank"
                icon={<img width="20px" height="20px" src={JiraIcon} alt="jira" />}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  margin: '5px',
                  width: '100px',
                  height: '40px',
                  fontFamily: 'Open Sans',
                  textTransform: 'capitalize',
                  borderRadius: '9px',
                  fontSize: '10px',
                  fontWeight: 600,
                  background: '#f5eced',
                  color: '#000',
                }}
              />
            ))}
          </Box>
        </>}
    </>
  );
};

JiraWarpper.propTypes = {
  project: PropTypes.string,
  test: {},
  id: PropTypes.string,
  selectedTestName: PropTypes.string,
  setSnackbarData: PropTypes.func,
  jiraTickets: [],
  setJiraTickets: PropTypes.func,
  reportType: PropTypes.string,
};

JiraWarpper.defaultProps = {
  project: '',
  test: {},
  id: '-1',
  selectedTestName: '',
  setSnackbarData: PropTypes.func,
  jiraTickets: [],
  setJiraTickets: PropTypes.func,
  reportType: PropTypes.string,
};

export default JiraWarpper;
