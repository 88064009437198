import React, { useEffect, useState } from 'react';
import {
  Box,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { reportStyles } from './style';
import { FileType } from '../../utils/constants';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import invitereportService from '../../services/invitereport.service';
import Loader from '../../utils/loader/Loader';
import XmlNotification from './XmlNotfication';
import HtmlNotification from './HtmlNofication';

const ViewNotification = () => {
  const { id, type } = useParams();
  const styles = reportStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });

  const viewReport = async () => {
    try {
      setLoading(true);
      if (id && type) {
        const res = await invitereportService.viewNotification(id, type);
        if (res) {
          setData(res);
        }
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      viewReport();
    }
  }, [id]);

  return (
    <>
      {loading && <Loader />}
      <Box
        className={styles.parentBox}
        data-testid="report-box"
      >
        {
          data?.fileType === FileType.XML && <XmlNotification data={data} />
        }
        {
          data?.fileType === FileType.HTML && <HtmlNotification data={data} />
        }
      </Box>
      {snackbar.state && (
        <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
      )}
    </>
  );
};

export default ViewNotification;
