import PropTypes from 'prop-types';

const ExceptionPropType = PropTypes.shape({
  fullStacktrace: PropTypes.string,
  _class: PropTypes.string,
  message: PropTypes.string,
});

const TestPropType = PropTypes.shape({
  name: PropTypes.string,
  status: PropTypes.string,
  signature: PropTypes.string,
  exception: ExceptionPropType,
});

const TestsPropType = PropTypes.arrayOf(TestPropType);

export { TestPropType, ExceptionPropType, TestsPropType };
