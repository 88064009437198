import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import verification from '../../assets/images/verification.png';

const EmailVerificationPage = ({ email }) => <>
  <div className="comman-pagewrap">
    <div className="left-block-details">
      <div className="logo-block-wrap">
        <Link to="/">
          <img src={logo} alt="logo" width="206" height="38" />
        </Link>
      </div>
      <div className="login-detail-block">
        <div className="login-detail-inner">
          <div className="login-detail-block verification-wrap">
            <h2 className="h2">Check your email to finish signing in</h2>
            <p className="sub-title">
              {'We just sent a link to '}
              <a href="/" onClick={(e) => e.preventDefault()}>
                {email}
              </a>
            </p>
            <div className="max-verification">
              <p>Follow the link in your email to finish signing in.</p>
              <p>
                If you havent received it within a few minutes,
                double check your spam/junk folder.
              </p>
            </div>

          </div>
        </div>
      </div>

    </div>
    <div className="right-block-details verification-right-block">
      <div className="login-img-right">
        <h1 className="h1">The simplest way to manage your workforce</h1>
        <p>Enter your credentials to access your account</p>
        <div className="right-block-detailsimg">
          <img className="sd-img" src={verification} alt="verification" width="540" height="420" />
        </div>
      </div>
    </div>
  </div>
</>;

export default EmailVerificationPage;

EmailVerificationPage.propTypes = {
  email: PropTypes.string,
};
EmailVerificationPage.defaultProps = {
  email: '',
};
