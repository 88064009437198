import React, { useState, useEffect } from 'react';
import {
  TextField,
  Box,
  Grid,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Dialog,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  TextFieldBox,
} from './style';
import { useCommonContext } from '../header/context';
import inviteUserService from '../../services/inviteUser.service';
import commonUtils from '../../utils/common/common';
import { UserPermission, UserPermissionsObject } from '../../utils/constants';
import companyService from '../../services/company.service';
import reportService from '../../services/report.service';

const InviteDialog = ({
  isOpen,
  onClose,
  setSnackbar,
  onSuccess,
  roleList,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentRole, setCurrentRole] = useState({});
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [project, setProject] = useState(null);

  const {
    user,
  } = useCommonContext();

  const inviteUser = async (data, action) => {
    try {
      setLoading(true);
      await inviteUserService.inviteUserByEmail(data);
      action.resetForm();
      onSuccess();
      setSnackbar({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Please Check Your Email...',
      });
    } catch (err) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err?.data?.message || 'Something went wrong.'}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    projectId: [],
    companyId: '',
    roleId: '',
  };

  const inviteUserSchema = Yup.object({
    firstName: Yup.string().trim().matches(commonUtils.validName, 'Please enter valid Firstname').min(2, 'Must include more than one character')
      .required('Firstname is required'),
    lastName: Yup.string().trim().matches(commonUtils.validName, 'Please enter valid Lastname').min(2, 'Must include more than one character')
      .required('Lastname is required'),
    email: Yup.string().trim().matches(commonUtils.validateEmail, 'Please enter valid email').required('Email is required'),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: inviteUserSchema,
    onSubmit: (val, action) => inviteUser(val, action),
  });

  const getProjectName = async () => {
    if (values?.companyId) {
      try {
        const res = await reportService.getProjectById(values?.companyId);
        setProject(res);
      } catch (error) {
        setProject([]);
      }
    }
  };

  const companyList = async () => {
    try {
      const res = await companyService.getAll();
      setCompanyDropdown(res);
    } catch (error) {
      setCompanyDropdown([]);
    }
  };

  useEffect(() => {
    if ((user?.role?.userPermissionsList.includes(UserPermission.PROJECT))) {
      getProjectName();
    }
  }, [user?.role?.roleId, values?.companyId]);

  useEffect(() => {
    companyList();
  }, [user?.role?.roleId]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box>
        <DialogTitle>Add User</DialogTitle>
        <Divider />
        <form
          onSubmit={handleSubmit}
        >
          <DialogContent>
            <Grid item>
              <TextFieldBox>
                <TextField
                  fullWidth
                  error={touched.firstName && errors.firstName}
                  helperText={touched.firstName && errors.firstName}
                  size="small"
                  value={values?.firstName || ''}
                  label="First Name"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </TextFieldBox>
              <TextFieldBox>
                <TextField
                  fullWidth
                  error={touched.lastName && errors.lastName}
                  helperText={touched.lastName && errors.lastName}
                  size="small"
                  value={values?.lastName || ''}
                  label="Last Name"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </TextFieldBox>
              <TextFieldBox>
                <TextField
                  fullWidth
                  error={touched.email && errors.email}
                  helperText={touched.email && errors.email}
                  size="small"
                  value={values.email}
                  label="Email Address"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </TextFieldBox>
              <TextFieldBox>
                <FormControl fullWidth size="small">
                  <InputLabel size="small">Role</InputLabel>
                  <Select
                    name="roleId"
                    value={values?.roleId || ''}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue('projectId', []);
                      setCurrentRole(roleList.find((i) => i?.roleId === e?.target?.value) || {});
                      handleChange(e);
                    }}
                    label="Role"
                    size="small"
                  >
                    {roleList?.map((role) => (
                      <MenuItem value={role?.roleId} key={role?.roleId}>
                        {role?.roleName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TextFieldBox>
              <Grid container spacing={1}>
                <Grid item lg={6} xs={12}>
                  <TextFieldBox>
                    <FormControl fullWidth size="small">
                      <InputLabel size="small">Company</InputLabel>
                      <Select
                        label="Company"
                        size="small"
                        name="companyId"
                        value={values?.companyId || ''}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('projectId', []);
                          handleChange(e);
                        }}
                      >
                        {
                         companyDropdown?.length && companyDropdown?.map((menuitem) => (
                           <MenuItem value={menuitem?.companyId} key={menuitem?.companyId}>
                             {menuitem?.companyName}
                           </MenuItem>))
                          }
                      </Select>
                    </FormControl>
                  </TextFieldBox>
                </Grid>
                {(user?.role?.userPermissionsList.includes(UserPermission.INVITE_USER)
              && (Object.keys(currentRole).length > 0 && !currentRole?.userPermissionsList?.includes('INVITE_USER')))
                  ? <Grid item lg={6} xs={12}>
                    <TextFieldBox>
                      <FormControl fullWidth size="small">
                        <InputLabel size="small">Project</InputLabel>
                        {project && project.length > 0
                          ? <Select
                              label="Project"
                              size="small"
                              name="projectId"
                              value={values?.projectId || []}
                              multiple
                              onBlur={handleBlur}
                              onChange={handleChange}
                          >
                            {project?.map((menuitem) => (
                              <MenuItem value={menuitem?.projectId} key={menuitem?.projectId}>
                                {menuitem.projectName}
                              </MenuItem>
                            ))}
                          </Select> : <Select value={[]} size="small" label="Project" style={{ cursor: 'none' }}>
                            <MenuItem style={{ color: '#999' }}>
                              No Project Found
                            </MenuItem>
                          </Select> }
                      </FormControl>
                    </TextFieldBox>
                  </Grid> : <Grid sx={{ width: 255 }} /> }
              </Grid>
              <Grid item lg={12} xs={12} sx={{ margin: 'auto' }}>
                <Grid
                  lg={12}
                  xs={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    border: '1px solid #e0e0e0',
                    p: 1,
                    borderRadius: '4px',
                  }}
                >
                  <Grid item lg={4} xs={12}>
                    <FormControlLabel control={<Checkbox name="VIEW_REPORT" checked={Boolean(currentRole?.userPermissionsList?.includes('VIEW_REPORT'))} />} label={UserPermissionsObject.VIEW_REPORT} />
                    <FormControlLabel control={<Checkbox name="UPLOAD_REPORT" checked={Boolean(currentRole?.userPermissionsList?.includes('UPLOAD_REPORT'))} />} label={UserPermissionsObject.UPLOAD_REPORT} />
                    <FormControlLabel control={<Checkbox name="INVITE_USER" checked={Boolean(currentRole?.userPermissionsList?.includes('INVITE_USER'))} />} label={UserPermissionsObject.INVITE_USER} />
                    <FormControlLabel control={<Checkbox name="TEAM" checked={Boolean(currentRole?.userPermissionsList?.includes('TEAM'))} />} label={UserPermissionsObject.TEAM} />
                    <FormControlLabel control={<Checkbox name="COMPANY" checked={Boolean(currentRole?.userPermissionsList?.includes('COMPANY'))} />} label={UserPermissionsObject.COMPANY} />
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <FormControlLabel control={<Checkbox name="PROJECT" checked={Boolean(currentRole?.userPermissionsList?.includes('PROJECT'))} />} label={UserPermissionsObject.PROJECT} />
                    <FormControlLabel control={<Checkbox name="INVITE_PROJECT" checked={Boolean(currentRole?.userPermissionsList?.includes('INVITE_PROJECT'))} />} label={UserPermissionsObject.INVITE_PROJECT} />
                    <FormControlLabel control={<Checkbox name="INVITE_REPORT" checked={Boolean(currentRole?.userPermissionsList?.includes('INVITE_REPORT'))} />} label={UserPermissionsObject.INVITE_REPORT} />
                    <FormControlLabel control={<Checkbox name="ROLE" checked={Boolean(currentRole?.userPermissionsList?.includes('ROLE'))} />} label={UserPermissionsObject.ROLE} />
                    <FormControlLabel control={<Checkbox name="MAVEN_PLUGIN" checked={Boolean(currentRole?.userPermissionsList?.includes('MAVEN_PLUGIN'))} />} label={UserPermissionsObject.MAVEN_PLUGIN} />
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <FormControlLabel control={<Checkbox name="GUEST_USER" checked={Boolean(currentRole?.userPermissionsList?.includes('GUEST_USER'))} />} label={UserPermissionsObject.GUEST_USER} />
                    <FormControlLabel control={<Checkbox name="SLACK_INTEGRATION" checked={Boolean(currentRole?.userPermissionsList?.includes('SLACK_INTEGRATION'))} />} label={UserPermissionsObject.SLACK_INTEGRATION} />
                    <FormControlLabel control={<Checkbox name="JIRA_INTEGRATION" checked={Boolean(currentRole?.userPermissionsList?.includes('JIRA_INTEGRATION'))} />} label={UserPermissionsObject.JIRA_INTEGRATION} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button type="submit" disabled={loading} variant="contained">
              Add User
            </Button>
            <Button onClick={onClose} disabled={loading}>Cancel</Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default InviteDialog;

InviteDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  roleList: PropTypes.arrayOf(),
};

InviteDialog.defaultProps = {
  isOpen: false,
  roleList: [],
};
