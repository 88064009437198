import React from 'react';
import NewLogin from './components/newLogin/NewLogin';
import EmailVerification from './components/emailVerification/EmailVerification';
import Register from './components/Register/Register';
import Home from './components/Home/Home';
import NewDashboard from './components/NewDashboard/NewDashboard';
import NewSideNav from './components/NewSideNav/NewSideNav';
import BuildRun from './components/BuildRun/BuildRun';
import route from './utils/route';
import PersonalInfo from './components/PersonalInfo/PersonalInfo';
import Companies from './components/companies/Companies';
import ReportId from './components/Report/ReportId';
import Forgot from './components/Forgot/Forgot';
import CompanyCreate from './components/companycreate/CompanyCreate';
import CompanyDetail from './components/CompanyDetail/CompanyDetail';
import CompanyEdit from './components/CompanyEdit/CompanyEdit';
import CreateGroup from './components/group/CreateGroup';
import GroupList from './components/grouplist/GroupList';
import GroupDetail from './components/groupdetail/groupDetail';
import GroupPage from './components/grouppage/GroupPage';
import Profile from './components/Profile/Profile';
import ProfileCreate from './components/profileCreate/ProfileCreate';
import ProfileDetail from './components/profileDetail/ProfileDetail';
import ReportList from './components/reportList/ReportList';
import PlayWrightList from './components/reportList/Playwright/PlayWrightList';
import XmlNotification from './components/ViewPost/XmlNotfication';
import ProjectList from './components/projectlist/ProjectList';
import InviteUser from './components/Inviteuser/InviteUser';
import HtmlReportList from './components/HtmlreportList/HtmlReportList';
import HtmlReportId from './components/Report/HtmlReportId';
import InviteExpired from './components/InviteExpired/InviteExpired';
import UserInviteProject from './components/UserInviteProject/UserInviteProject';
import AdminInviteProject from './components/AdminInvitedProjects/AdminInvitedProjects';
import Projects from './components/viewproject/Project';
import AcceptProjectInvite from './components/AcceptProjectInvite/AcceptProjectInvite';
import AccessToken from './components/accessTokens/AccessToken';
import ViewReport from './components/ViewUserReport/ViewReport';
import ViewNotification from './components/ViewPost/ViewNotification';
import Team from './components/Team/Team';
import CypressList from './components/reportList/Cypress/CypressList';
// import DashboardSwitch from './components/dashboard/DashboardSwitch';
// import OnBoardingPage from './components/onBoarding/OnBoarding';
import Redirect from './components/signup/Redirect';
import SignupPage from './components/signup/signup';
import Integrations from './components/Integrations/Integrations';

const routes = [
  {
    path: '/',
    component: <Home />,
    exact: true,
    isAuth: true,
  },
  // {
  //   path: `/${route.ROUTE_DASHBOARD}`,
  //   component: <DashboardSwitch />,
  //   exact: true,
  //   isAuth: true,
  // },
  {
    path: `/${route.ROUTE_LOGIN}`,
    component: <NewLogin />,
    exact: true,
    isAuth: false,
  },
  {
    path: `/${route.ROUTE_NEW_LOGIN}`,
    component: <NewLogin />,
    exact: true,
    isAuth: false,
  },
  {
    path: `/${route.ROUTE_SIGNUP}`,
    component: <SignupPage />,
    exact: true,
    isAuth: false,
  },
  {
    path: `/${route.ROUTE_EMAIL_VERIFICATION}`,
    component: <EmailVerification />,
    exact: true,
    isAuth: false,
  },
  // {
  //   path: `/${route.ROUTE_ON_BOARDING_PAGE}`,
  //   component: <OnBoardingPage />,
  //   exact: true,
  //   isAuth: false,
  // },
  {
    path: `/${route.ROUTE_NEW_LOGIN}`,
    component: <NewLogin />,
    exact: true,
    isAuth: false,
  },
  {
    path: `/${route.ROUTE_EMAIL_VERIFICATION}`,
    component: <EmailVerification />,
    exact: true,
    isAuth: false,
  },
  // {
  //   path: `/${route.ROUTE_ON_BOARDING_PAGE}`,
  //   component: <OnBoardingPage />,
  //   exact: true,
  //   isAuth: false,
  // },
  {
    path: `/${route.ROUTE_HOME_PAGE}`,
    component: <Home />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.ROUTE_DASHBOARD}`,
    component: <NewDashboard />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.ROUTE_NEW_SIDENAV_PAGE}`,
    component: <NewSideNav />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.ROUTE_BUILD_RUN_PAGE}`,
    component: <BuildRun />,
    exact: true,
    isAuth: false,
  },
  {
    path: `/${route.ROUTE_FORGOT}`,
    component: <Forgot />,
    exact: true,
    isAuth: false,
  },
  {
    path: `/${route.ROUTE_REGISTER}`,
    component: <Register />,
    exact: true,
    isAuth: false,
  },
  {
    path: `/${route.ROUTE_PERSONALINFO}`,
    component: <PersonalInfo />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.ROUTE_COMPANIES}`,
    component: <Companies />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.ROUTE_REPORTID}`,
    component: <ReportId />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.COMPANY_CREATE}`,
    component: <CompanyCreate />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.COMPANY_DETAIL}`,
    component: <CompanyDetail />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.COMPANY_EDIT}`,
    component: <CompanyEdit />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.CREATE_GROUP}`,
    component: <CreateGroup />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.GROUP_LIST}`,
    component: <GroupList />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.GROUP_ID}`,
    component: <GroupDetail />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.GROUP_PAGE}`,
    component: <GroupPage />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.PROFILE_PAGE}`,
    component: <Profile />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.CREATE_PROFILE}`,
    component: <ProfileCreate />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.PROFILE_DETAIL}`,
    component: <ProfileDetail />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.REPORT_LIST}`,
    component: <ReportList />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.PLAYWRIGHT_LIST}`,
    component: <PlayWrightList />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.CYPRESS_LIST}`,
    component: <CypressList />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.REPORT_LIST_BY_ID}`,
    component: <XmlNotification />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.ROUTE_BUILD}`,
    component: <BuildRun />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.PROJECT_LIST}`,
    component: <ProjectList />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.INVITE_USER}`,
    component: <InviteUser />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.HTML_REPORT}`,
    component: <HtmlReportList />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.ROUTE_HTMLREPORTID}`,
    component: <HtmlReportId />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.INVITE_EXPIRED}`,
    component: <InviteExpired />,
    exact: true,
    isAuth: false,
  },
  {
    path: `/${route.USER_INVITE_PROJECT}`,
    component: <UserInviteProject />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.INVITED_PROJECT}`,
    component: <AdminInviteProject />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.VIEW_PROJECT}`,
    component: <Projects />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.INTEGRATIONS}`,
    component: <Integrations />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.ACCEPT_PROJECT_INVITE}`,
    component: <AcceptProjectInvite />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.ACCESS_TOKEN}`,
    component: <AccessToken isSidePanel />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.VIEW_REPORT}`,
    component: <ViewReport />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.VIEW_REPORT_GUEST}`,
    component: <XmlNotification />,
    exact: true,
    isAuth: false,
  },
  {
    path: `/${route.VIEW_NOTIFICATION}`,
    component: <ViewNotification />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.TEAM}`,
    component: <Team />,
    exact: true,
    isAuth: true,
  },
  {
    path: `/${route.REDIRECT}`,
    component: <Redirect />,
    exact: true,
    isAuth: false,
  },
];
export default routes;
