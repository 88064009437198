import { SortingType } from './constants';

const sorting = (data, sortingField, order) => {
  let sorted;
  if (order === 'ASC') {
    sorted = data?.sort((a, b) => {
      let sortedData;
      switch (sortingField) {
        case SortingType.REPORTNAME:
          sortedData = a?.testingResults?.suite?.name?.toLowerCase()
                        > b.testingResults?.suite?.name?.toLowerCase() ? 1 : -1;
          break;
        case SortingType.DATE:
          sortedData = a?.createAt > b.createAt ? 1 : -1;
          break;
        case SortingType.PASSED:
          sortedData = a?.testingResults?.passed > b?.testingResults?.passed ? 1 : -1;
          break;
        case SortingType.FAILED:
          sortedData = a?.testingResults?.failed > b?.testingResults?.failed ? 1 : -1;
          break;
        default:
      }
      return sortedData;
    });
  }
  if (order === 'DSC') {
    sorted = data?.sort((a, b) => {
      let sortedData;
      switch (sortingField) {
        case SortingType.REPORTNAME:
          sortedData = a?.testingResults?.suite?.name?.toLowerCase()
                        > b.testingResults?.suite?.name?.toLowerCase() ? -1 : 1;
          break;
        case SortingType.DATE:
          sortedData = a?.createAt > b.createAt ? -1 : 1;
          break;
        case SortingType.PASSED:
          sortedData = a?.testingResults?.passed > b?.testingResults?.passed ? -1 : 1;
          break;
        case SortingType.FAILED:
          sortedData = a?.testingResults?.failed > b?.testingResults?.failed ? -1 : 1;
          break;
        default:
      }
      return sortedData;
    });
  }
  return sorted;
};
export default sorting;
