/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import dayjs from 'dayjs';
import { CircularProgress } from '@mui/material';

const defaultOptions = {
  chart: {
    type: 'areaspline',
    marginTop: 70,
  },
  title: {
    text: null,
  },
  xAxis: {
    tickmarkPlacement: 'on',
    title: {
      enabled: false,
    },
  },
  yAxis: {
    title: {
      text: '',
    },
    min: 0,
    labels: {
      format: '{value}%',
    },
    max: 100,
  },
  tooltip: {
    split: true,
    valueSuffix: ' %',
  },
  plotOptions: {
    areaspline: {
      stacking: 'percent',
      lineColor: '#666666',
      lineWidth: 1,
      marker: {
        enabled: true,
        radius: 4,
        lineWidth: 1,
        lineColor: '#666666',
      },
    },
  },
  legend: {
    align: 'left',
    verticalAlign: 'top',
    layout: 'horizontal',
    symbolRadius: 8,
    itemStyle: {
      fontWeight: 'normal',
      fontSize: 15,
    },
    symbolPadding: 10,
    symbolHeight: 10,
  },
  responsive: true,
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
};

const StackedAreaChart = ({ chartData, chartType, loading }) => {
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (chartData) {
      let series = [];
      let categories = [];
      let colors = [];
      switch (chartType) {
        case 'TestsStatus':
          categories = chartData?.map((item) => dayjs(item.date).format('D MMM'));
          series = [
            {
              name: 'Passed',
              data: chartData?.map((item) => item.pass),
              marker: {
                symbol: 'circle',
              },
            },
            {
              name: 'Failed',
              data: chartData?.map((item) => item.fail),
              marker: {
                symbol: 'circle',
              },
            },
            {
              name: 'Skipped',
              data: chartData?.map((item) => item.skip),
              marker: {
                symbol: 'circle',
              },
            },
          ];
          colors = ['#5FD726', '#FF0000', '#FFC72B', '#000000'];
          break;

        case 'FailureCategories':
          categories = chartData?.map((item) => dayjs(item.date).format('D MMM'));
          series = [
            {
              name: 'To be Investigated',
              data: chartData?.map((item) => item.toInvestigate), // Adjust according to your data
              marker: {
                symbol: 'circle',
              },
            },
            {
              name: 'Automation Bug',
              data: chartData?.map((item) => item.automationBug), // Adjust according to your data
              marker: {
                symbol: 'circle',
              },
            },
            {
              name: 'Environment Issue',
              data: chartData?.map((item) => item.environmentIssue),
              marker: {
                symbol: 'circle',
              },
            },
            {
              name: 'Product Bug',
              data: chartData?.map((item) => item.productBug), // Adjust according to your data
              marker: {
                symbol: 'circle',
              },
            },
            {
              name: 'No Defect',
              data: chartData?.map((item) => item.noDefect), // Adjust according to your data
              marker: {
                symbol: 'circle',
              },
            },
          ];
          colors = ['#D9D9D9', '#FFC72B', '#5FD726', '#FF0000', '#1D6AE5'];
          break;

        default:
          break;
      }

      setChartOptions({
        ...defaultOptions,
        xAxis: {
          ...defaultOptions.xAxis,
          categories,
        },
        colors: [...colors],
        series: [...series],
      });
    }
  }, [chartData, chartType]);

  return <div className="custom-legend" style={{ width: '100%', textAlign: 'center' }}>
    {loading ? (
      <CircularProgress />
    ) : (
      <>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </>
    )}
  </div>;
};

export default StackedAreaChart;
