import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1d6ae5',
      CheckboxColor: '#1d6ae5',
    },
    secondary: {
      main: '#fff',
    },
    error: {
      main: '#f04438',
    },
    success: {
      main: '#10b981',
    },
    warning: {
      main: '#ffc72b',
    },
    text: {
      createAccount: '#fff',
      // createAccount: '#10B981',
      ternary: 'rgba(11, 35, 54, 0.7)',
    },
  },
  typography: {
    fontSize: 12,
    fontWeightBold: 600,
    fontFamily: [
      'graphik_regularregular',
    ].join(','),
  },
  fontSize: {
    headerFontSize: 24,
    subHeaderFontSize: 12,
  },
  width: {
    logo: '40%',
    form: '60%',
  },
});

export default theme;
