import React, { useState } from 'react';
import {
  TextField,
  Box,
  Grid,
  CircularProgress,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Item,
  WelcomeTextStyling,
  ButtonStyle,
} from './style';
import route from '../../utils/route';
import companyService from '../../services/company.service';
import { UserStatus } from '../../utils/constants';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';

function CompanyCreate() {
  const initialValues = {
    companyName: '',
    companyStatus: UserStatus.STATUS,
  };
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const location = useLocation();
  const navigate = useNavigate();
  const companyName = RegExp(/^[A-Za-z0-9 ]*$/);
  const signUpSchema = Yup.object({
    companyName: Yup.string().matches(companyName, 'Please enter valid Company name!').max(30, 'Should be 15 or less than 15 characters')
      .required('Company name is required'),
  });
  const handleClick = async (values) => {
    try {
      setLoading(true);
      await companyService.addCompany(values);
      setSnackbar({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Company Created ..!',
      });
      setTimeout(() => {
        navigate(`/${route.ROUTE_COMPANIES}`);
      }, 2000);
    } catch (err) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err.data.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    onSubmit: () => {
      handleClick(values);
    },
  });
  return (
    <>
      {
        location.pathname === `/${route.COMPANY_CREATE}` && (
          <Box
            sx={{
              marginTop: '100px',
            }}
          >
            <Grid item xs={7}>
              <WelcomeTextStyling> Company Create </WelcomeTextStyling>
            </Grid>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                rowSpacing={3}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: '9px' }}
              >
                <Grid item xs={7}>
                  <TextField
                    label="Company name *"
                    name="companyName"
                    value={values.companyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.companyName}
                    helperText={errors.companyName}
                    placeholder="New Company"
                  />
                </Grid>
                <Grid item xs={7} style={{ cursor: 'pointer' }}>
                  {loading && <CircularProgress style={{ marginLeft: '35px' }} />}
                  {
                    !loading && <Item><ButtonStyle type="submit" variant="contained" style={{ width: '155px', cursor: 'pointer' }}>SUBMIT</ButtonStyle></Item>
                  }
                </Grid>
              </Grid>
            </form>
            {
              snackbar.state
              && (
                <NavigateSnackbar
                  snackbarObj={snackbar}
                  setSnackbar={setSnackbar}
                />
              )
            }
          </Box>
        )
      }
    </>
  );
}

export default CompanyCreate;
