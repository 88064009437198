import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCommonContext } from '../header/context';
import { UserPermission } from '../../utils/constants';
import companyService from '../../services/company.service';
import reportService from '../../services/report.service';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import { createJiraProject, deleteJiraAccount, jiraAccountPageByCurrentUser } from '../../services/integrations';

const JiraIntegration = () => {
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [project, setProject] = useState([]);
  const [jiraProjects, setJiraProjects] = useState([]);
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [getJira, setGetJira] = useState(false);
  const { user } = useCommonContext();

  const companyList = async () => {
    try {
      const res = await companyService.getAll();
      setCompanyId(res[0]?.companyId);
      setCompanyDropdown(res);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.data.message}`,
      });
    }
  };

  const getProjectName = async () => {
    if (companyId) {
      try {
        const res = await reportService.getProjectById(companyId);
        setProject(res);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error.data.message}`,
        });
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteJiraAccount(id);
      if (res) {
        setGetJira(!getJira);
        setSnackbar({
          navigateText: '',
          severity: 'success',
          state: true,
          message: 'Jira Account Deleted Successfully',
        });
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    }
  };

  useEffect(() => {
    companyList();
  }, [user?.role?.roleId]);

  useEffect(() => {
    if (user?.role?.userPermissionsList.includes(UserPermission.JIRA_INTEGRATION)) getProjectName();
  }, [user?.role?.roleId, companyId]);

  useEffect(async () => {
    try {
      const data = await jiraAccountPageByCurrentUser(page, pageSize);
      setJiraProjects(data);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.data.message}`,
      });
    }
  }, [page, pageSize, getJira]);

  const initialValues = {
    accountApiKey: '',
    jiraAccountDomainName: '',
    jiraAccountEmail: '',
    key: '',
    leadAccountId: '',
    projectName: '',
  };

  const validationSchema = Yup.object({
    accountApiKey: Yup.string().required('Account API Key is required'),
    jiraAccountDomainName: Yup.string().required('Jira Account Domain Name is required'),
    jiraAccountEmail: Yup.string().email('Invalid email format').required('Jira Account Email is required'),
    key: Yup.string().max(10, 'Key cannot be longer than 10 characters').required('Key is required'),
    leadAccountId: Yup.string().required('Lead Account ID is required'),
    projectName: Yup.string().required('Project Name is required'),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await createJiraProject(values);
      setGetJira(!getJira);
      setSnackbar({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Jira Integration Successful',
      });
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.data.message}`,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const JiraProjectInfoTable = (projects) => <>
    <TableContainer sx={{ mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: '#F6F7F8' }}>
            <TableCell>Project Name</TableCell>
            <TableCell>Domain Name</TableCell>
            <TableCell>Lead Id</TableCell>
            <TableCell>Key</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects?.content?.map((item) => (
            <TableRow key={item.jiraProjectId}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.jiraAccountDomainName}</TableCell>
              <TableCell>{item.leadAccountId}</TableCell>
              <TableCell>{item.key}</TableCell>
              <TableCell align="left">
                <IconButton color="error" size="small" onClick={() => handleDelete(item.id)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={jiraProjects?.totalPages}
      rowsPerPage={pageSize}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </>;

  return (
    <>
      <Box
        sx={{
          borderRadius: '4px',
          background: '#fff',
        }}
      >
        {user?.role?.userPermissionsList.includes(UserPermission.JIRA_INTEGRATION) && (
          <div>
            <Box sx={{ flexGrow: 1 }}>
              <Formik
                gutterBottom
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  isSubmitting, handleChange, handleBlur, values, errors, touched,
                }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3}>
                        <FormControl sx={{ width: '100%' }}>
                          <TextField
                            fullWidth
                            select
                            label="Company"
                            name="companyId"
                            variant="outlined"
                            value={companyId}
                            onChange={(e) => {
                              setCompanyId(e.target.value);
                              handleChange(e);
                            }}
                            InputLabelProps={{
                              shrink: companyId !== null,
                            }}
                            sx={{
                              my: 1,
                              '& .MuiInputBase-input': {
                                padding: '8.5px 14px',
                              },
                            }}
                          >
                            {companyDropdown.length > 0 ? companyDropdown.map((menuitem) => (
                              <MenuItem
                                value={menuitem?.companyId}
                                key={menuitem?.companyId}
                              >
                                {menuitem.companyName}
                              </MenuItem>
                            ))
                              : <MenuItem>No Record Found</MenuItem>}
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl sx={{ width: '100%' }}>
                          <TextField
                            fullWidth
                            select
                            label="Projects"
                            name="projectName"
                            variant="outlined"
                            value={values.projectName}
                            onChange={handleChange}
                            sx={{
                              my: 1,
                              '& .MuiInputBase-input': {
                                padding: '8.5px 14px',
                              },
                            }}
                            error={!!errors.projectName && touched.projectName}
                            helperText={touched.projectName && errors.projectName}
                          >
                            {project.length > 0 ? project.map((menuitem) => (
                              <MenuItem
                                value={menuitem?.projectName}
                                key={menuitem?.projectId}
                              >
                                {menuitem?.projectName}
                              </MenuItem>
                            ))
                              : <MenuItem>No Record Found</MenuItem>}
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl sx={{ width: '100%' }}>
                          <TextField
                            sx={{ my: 1 }}
                            size="small"
                            fullWidth
                            value={values.accountApiKey}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="Account API Key"
                            name="accountApiKey"
                            id="accountApiKey"
                            error={touched.accountApiKey && Boolean(errors.accountApiKey)}
                            helperText={touched.accountApiKey && errors.accountApiKey}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl sx={{ width: '100%' }}>
                          <TextField
                            sx={{ my: 1 }}
                            size="small"
                            fullWidth
                            value={values.jiraAccountDomainName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="Jira Domain Name"
                            name="jiraAccountDomainName"
                            id="jiraAccountDomainName"
                            error={touched.jiraAccountDomainName
                                && Boolean(errors.jiraAccountDomainName)}
                            helperText={touched.jiraAccountDomainName
                                && errors.jiraAccountDomainName}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl sx={{ width: '100%' }}>
                          <Field
                            as={TextField}
                            sx={{ my: 1 }}
                            size="small"
                            fullWidth
                            value={values.jiraAccountEmail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="jiraAccountEmail"
                            id="jiraAccountEmail"
                            label="Jira Email"
                            error={touched.jiraAccountEmail
                                && Boolean(errors.jiraAccountEmail)}
                            helperText={touched.jiraAccountEmail
                                && errors.jiraAccountEmail}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl sx={{ width: '100%' }}>
                          <TextField
                            sx={{ my: 1 }}
                            size="small"
                            fullWidth
                            value={values.key}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="Key"
                            name="key"
                            id="key"
                            inputProps={{
                              style: { textTransform: 'uppercase' },
                            }}
                            error={touched.key
                                && Boolean(errors.key)}
                            helperText={touched.key
                                && errors.key}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl sx={{ width: '100%' }}>
                          <TextField
                            sx={{ my: 1 }}
                            size="small"
                            fullWidth
                            value={values.leadAccountId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="Lead Account Id"
                            name="leadAccountId"
                            id="leadAccountId"
                            error={touched.leadAccountId
                                && Boolean(errors.leadAccountId)}
                            helperText={touched.leadAccountId
                                && errors.leadAccountId}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Button
                        type="submit"
                        sx={{ borderRadius: '4px' }}
                        size="large"
                        variant="contained"
                        disabled={isSubmitting}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>
              <Box component={Paper}>
                {JiraProjectInfoTable(jiraProjects)}
              </Box>
            </Box>
          </div>
        )}
      </Box>
      {snackbar.state && (
        <NavigateSnackbar
          snackbarObj={snackbar}
          setSnackbar={setSnackbar}
        />
      )}
    </>
  );
};

export default JiraIntegration;
