import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import {
  Button,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  AppBar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Dailog from './Dialog';
import Loader from '../../utils/loader/Loader';
import {
  exportToExcel, downloadPdf, htmlReportInvite, htmlReports,
} from '../../services/uploadFile.service';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import {
  FileType,
  HtmlStatus,
  ReportSchema,
  SortingType,
  UserPermission,
} from '../../utils/constants';
import commonUtil from '../../utils/common/common';

import {
  ParentBox,
  reportStyles,
} from './style';
import HtmlDescription from '../HtmlDescription';
import Breadcrumb from '../breadcrumbs/Breadcrumb';
import sorting from '../../utils/html';
import { useCommonContext } from '../header/context';
import { readableTime } from '../../utils';
import projectService from '../../services/project.service';

function HtmlReportList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const style = reportStyles();
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [active, setActive] = useState(-1);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTests, setSelectedTests] = useState({ id: null, tests: [], name: null });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('ASC');
  const [userList, setUserList] = React.useState([]);
  const [tId, setTId] = React.useState('');
  const [openInviteProjectModal, setOpenInviteProjectModal] = React.useState(false);
  const {
    user,
    selectedfilter,
    setSelectedFilter,
    notification,
    reportType,
    setReportType,
  } = useCommonContext();
  const handleClick = (index) => {
    setActive(index === active ? -1 : index);
  };

  const onTestSuitClick = (tests, id, name) => {
    setSelectedTests({ tests, id, name });
    setOpenDrawer(true);
  };
  const myStyle = {
    boxShadow: 'none',
    position: 'relative',
    background: '#fff',
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    zIndex: 1,
    padding: '5px',
    marginBottom: '12px',
    border: '1px solid #3f639859',
    borderRadius: '4px',
  };
  const htmlReportPageByCurrentInvitedUser = async () => {
    try {
      const res = await htmlReportInvite(page, rowsPerPage);
      if (res) {
        setTotalElements(res.totalElements || 0);
        setData(res.content);
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    }
  };
  const htmlReportList = async () => {
    try {
      setLoading(true);
      const res = await htmlReports(page, rowsPerPage);
      if (res) {
        setTotalElements(res.totalElements || 0);
        setLoading(false);
        setData(res.content);
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setReportType(FileType.XML);
    if (user?.role?.userPermissionsList.includes(UserPermission.GUEST_USER)) {
      htmlReportPageByCurrentInvitedUser(page, rowsPerPage);
    } else if (user?.role?.userPermissionsList) {
      htmlReportList(page, rowsPerPage);
    }
  }, [user?.role?.roleId, page, rowsPerPage, JSON.stringify(notification)]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newValue = event.target.value;
    if (newValue === totalElements) {
      setRowsPerPage(totalElements);
      setPage(0);
    } else {
      setRowsPerPage(parseInt(newValue, 10));
      setPage(0);
    }
  };
  function calculateTimestamp(val) {
    let month = null;
    let year = null;
    let dayDate = null;
    const repDate = val?.createAt;
    const reportDate = repDate?.indexOf('IST') >= 0
      ? repDate.split(' IST')[0] : repDate;
    if (reportDate) {
      const newReportDate = new Date(reportDate);
      month = commonUtil.months[newReportDate?.getMonth()];
      year = newReportDate?.getFullYear();
      dayDate = `${newReportDate?.getDate() > 9 ? newReportDate?.getDate()
        : 0 + (newReportDate?.getDate())}`;
    }
    return { month, year, dayDate };
  }
  const handleSort = (type) => {
    let sData;
    let sOrder;
    if (order === 'ASC') {
      sData = sorting(data, type, order);
      sOrder = 'DSC';
    }
    if (order === 'DSC') {
      sData = sorting(data, type, order);
      sOrder = 'ASC';
    }
    setData(sData);
    setOrder(sOrder);
  };
  const reportDownload = async (id, filename) => {
    try {
      setLoading(true);
      const res = await downloadPdf({
        testReportId: id,
        fileType: FileType.HTML,
      });
      if (res) {
        const url = new Blob([res], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(url);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  const reportExportToExecl = async (id, filename) => {
    try {
      setLoading(true);
      const res = await exportToExcel({
        testReportId: id,
        fileType: FileType.HTML,
      });
      if (res) {
        const url = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(url);
        link.setAttribute('exportToExcel', filename);
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const onInvite = async (val) => {
    setTId(val?.id);
    try {
      const users = await projectService.getMemberList(val?.companyId, val?.projectId);
      setUserList(users);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    }
    setOpenInviteProjectModal(true);
  };

  return (
    <>
      <ParentBox>
        <Breadcrumb data={[ReportSchema[reportType].name]} />
        <AppBar
          style={myStyle}
        >
          <Paper
            component="form"
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: 265,
              boxShadow: 'none',
              border: '1px solid #3f639859',
            }}
            elevation="0"
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Html Report Search"
              inputProps={{ 'aria-label': 'Html Report Search' }}
              onChange={(e) => setSearch(e.target.value)}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>

          </Paper>
          <Box>
            <Button sx={{ mr: 2 }} size="large" variant="text" onClick={() => setSelectedFilter(null)}>
              <RefreshIcon />
              Reset
            </Button>
          </Box>
        </AppBar>
        {loading && <Loader />}
        {data && data.length !== 0 && (
          <Grid item sm={12} xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow className={style.tableHeader}>
                    <TableCell />
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleSort(SortingType.REPORTNAME)}
                    >
                      Report Name
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleSort(SortingType.DATE)}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                    >
                      Execution Time
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                      }}
                    >
                      Company
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                      }}
                    >
                      Project
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        textAlign: 'center',
                      }}
                      onClick={() => handleSort(SortingType.PASSED)}
                    >
                      Result
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        textAlign: 'center',
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.filter(
                    (fname) => (
                      search.toLowerCase() === ''
                        ? fname
                        : fname.testingResults?.suite?.name.toLowerCase().trim()
                          .includes(search.toLowerCase().trim())
                    ),
                  )
                    ?.map((val, index) => {
                      const { month, year, dayDate } = calculateTimestamp(val);
                      let test = val.testingResults?.suite?.test;
                      if (
                        test && !Array.isArray(test)
                      ) {
                        test = [test];
                      }
                      return (
                        <>
                          <TableRow key={data.id} sx={{ border: '1px solid #e0e0e0', 'td, th': { border: 0 } }}>
                            <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                              <IconButton onClick={() => handleClick(index)}>
                                {active !== index ? (
                                  <ChevronRightIcon />
                                ) : (
                                  <ExpandMore />
                                )}
                              </IconButton>

                              <SummarizeIcon sx={{ color: '#3f6398', mr: 2 }} />
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: '14px',
                              }}
                            >
                              {val?.testingResults?.suite?.name || 'Smoke test'}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: '14px',
                              }}
                            >
                              {`${dayDate} ${month} ${year}`}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: '14px',
                              }}
                            >
                              {
                                readableTime(Math.round((val?.testingResults?.totalTime || 0)
                                 / 1000))
                              }
                            </TableCell>

                            <Tooltip title="Company">
                              <TableCell
                                sx={{
                                  fontSize: '14px',
                                  cursor: 'pointer',
                                }}
                              >
                                {val?.companyName}
                              </TableCell>
                            </Tooltip>
                            <Tooltip title="Project">
                              <TableCell
                                sx={{
                                  fontSize: '14px',
                                  cursor: 'pointer',
                                }}
                              >
                                {val?.projectName}
                              </TableCell>
                            </Tooltip>
                            <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center' }}>
                              <Tooltip title="Passed">
                                <Grid
                                  sx={{
                                    backgroundColor: '#fff',
                                    border: '11px solid #10b981',
                                    borderRadius: '100px',
                                    height: '38px',
                                    width: '38px',
                                    position: 'relative',
                                    cursor: 'pointer',
                                    ml: 2,
                                  }}
                                  onClick={() => {
                                    setSelectedFilter(HtmlStatus.PASSED);
                                    setActive(index === active && selectedfilter
                                      === HtmlStatus.PASSED ? -1 : index);
                                  }}
                                >
                                  <Grid
                                    sx={{
                                      position: 'absolute',
                                      top: '-21px',
                                      right: '-11px',
                                      background: '#fff',
                                      minWidth: '25px',
                                      borderRadius: '9px',
                                      border: '1px solid #10b981',
                                      fontWeight: 600,
                                      padding: '1px',
                                    }}
                                  >
                                    {val?.testingResults?.passed}
                                  </Grid>
                                </Grid>
                              </Tooltip>
                              <Tooltip title="Failed">
                                <Grid
                                  sx={{
                                    backgroundColor: '#fff',
                                    border: '11px solid #f04438',
                                    borderRadius: '100px',
                                    height: '38px',
                                    width: '38px',
                                    position: 'relative',
                                    cursor: 'pointer',
                                    ml: 2,
                                  }}
                                  onClick={() => {
                                    setSelectedFilter(HtmlStatus.FAILED);
                                    setActive(index === active && selectedfilter
                                      === HtmlStatus.FAILED ? -1 : index);
                                  }}
                                >
                                  <Grid
                                    sx={{
                                      position: 'absolute',
                                      top: '-21px',
                                      right: '-11px',
                                      background: '#fff',
                                      minWidth: '25px',
                                      borderRadius: '9px',
                                      border: '1px solid #e93e3d',
                                      fontWeight: 600,
                                    }}
                                  >
                                    {val?.testingResults?.failed}
                                  </Grid>
                                </Grid>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title="Download Pdf">
                                <Button
                                  onClick={(e) => {
                                    reportDownload(val?.id, val?.testingResults?.suite?.name);
                                    e.stopPropagation();
                                  }}
                                >
                                  <FileDownloadIcon />
                                </Button>
                              </Tooltip>
                              <Button
                                onClick={(e) => {
                                  reportExportToExecl(val?.id, val?.testingResults?.suite?.name);
                                  e.stopPropagation();
                                }}
                                style={{
                                  cursor: 'pointer',
                                }}
                                color="info"
                                variant="outlined"
                              >
                                Export To Excel
                              </Button>
                              {
                                user?.role?.userPermissionsList
                                  .includes(UserPermission.INVITE_REPORT)
                                && <Button
                                  onClick={(e) => {
                                    onInvite(val);
                                    e.stopPropagation();
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: '4px',
                                  }}
                                  color="info"
                                  variant="outlined"
                                >
                                  Invite Report
                                </Button>
                              }
                            </TableCell>
                          </TableRow>
                          {active === index ? (
                            <TableRow>
                              <TableCell colSpan="10">
                                <Collapse
                                  in={active === index}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List component="div" disablePadding>
                                    {val?.testingResults?.suite?.test.map((item, i) => {
                                      const methods = item?.testResultClass?.testMethod;
                                      const passTest = methods?.length ? methods.filter(
                                        (a) => a.status === HtmlStatus.PASSED,
                                      ) : [];
                                      const failedTest = methods?.length ? methods.filter(
                                        (a) => a.status === HtmlStatus.FAILED,
                                      ) : [];
                                      if (
                                        selectedfilter === HtmlStatus.PASSED && failedTest.length) {
                                        return null;
                                      }
                                      if (selectedfilter === HtmlStatus.FAILED
                                        && failedTest.length === 0) {
                                        return null;
                                      }
                                      return (
                                        <>
                                          <ListItemButton
                                            sx={{ pl: 4 }}
                                            onClick={() => onTestSuitClick(
                                              item?.testResultClass?.testMethod,
                                              val?.id,
                                              item?.name,
                                            )}
                                          >
                                            <ListItemIcon>
                                              <SearchIcon className={style.searchicon} />
                                              {failedTest.length ? (
                                                <ReportProblemIcon
                                                  className={style.failIcon}
                                                />
                                              ) : (
                                                <CheckIcon sx={{ color: 'green', mr: 2 }} />
                                              )}
                                            </ListItemIcon>
                                            <ListItemText
                                              primary={item?.name}
                                            //   secondary={item.class.name}
                                            />

                                            <Typography
                                              component="div"
                                              className={style.accordianTime}
                                            >
                                              <Typography
                                                component="h1"
                                                className={style.accordianHeader}
                                              >
                                                Test
                                              </Typography>
                                              {
                                                passTest.length === 0 ? '' : <Tooltip title="Passed">
                                                  <Button
                                                    variant="contained"
                                                    className={style.testlistButton}
                                                  >
                                                    {passTest.length}
                                                  </Button>
                                                </Tooltip>
                                              }
                                              {
                                                failedTest.length === 0 ? '' : <Tooltip title="Failed">
                                                  <Button
                                                    variant="contained"
                                                    className={style.failedlistButton}
                                                  >
                                                    {failedTest.length}
                                                  </Button>
                                                </Tooltip>
                                              }
                                            </Typography>
                                          </ListItemButton>
                                          {i !== test?.length - 1 && (
                                            <Divider />
                                          )}
                                        </>
                                      );
                                    })}
                                  </List>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          ) : null}
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <Box sx={{ width: 600 }} role="presentation">
            <HtmlDescription
              tests={selectedTests.tests}
              id={selectedTests.id}
              name={selectedTests.name}
            />
          </Box>
        </Drawer>
        {snackbar.state && (
          <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
        )}
        {data.length ? <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, totalElements]}
          count={totalElements}
          component="div"
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> : null}
        {
          data?.map((a) => <Dailog
            openDailog={openInviteProjectModal}
            tId={tId}
            cid={a.companyId}
            pid={a.projectId}
            userList={userList}
            onOpen={(value) => setOpenInviteProjectModal(value)}
            setSnackbarData={(datas) => setSnackbar(datas)}
          />)
        }
      </ParentBox>
    </>
  );
}

export default HtmlReportList;
