import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import {
  AppBar, Badge, Button, Grid, IconButton,
  InputBase, Paper,
  Table, TableBody, TableCell, TableContainer,
  TableHead, TablePagination, TableRow, Tooltip, Typography,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { cypressResultList } from '../../../services/uploadFile.service';
import { ParentBox } from '../style';
import Breadcrumb from '../../breadcrumbs/Breadcrumb';
import { useCommonContext } from '../../header/context';
import {
  CypressTestStatus, FileType, InviteStatus, ReportSchema, UserPermission,
} from '../../../utils/constants';
import Loader from '../../../utils/loader/Loader';
import { parseDate } from '../../dashboard/utils';
import { readableTime } from '../../../utils';
import jenkinsImg from '../../../assets/jenkins.png';
import projectService from '../../../services/project.service';
import NavigateSnackbar from '../../navigateSnackbar/NavigateSnackbar';
import Dailog from '../Dialog';
import CypressDrawer from './CypressDrawer';

const CypressList = () => {
  const {
    reportType, setSelectedFilter, user, setReportType,
  } = useCommonContext();
  const [dataList, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [totalElements, setTotalElements] = useState(0);
  const [tId, setTId] = useState('');
  const [userList, setUserList] = useState([]);
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [openInviteProjectModal, setOpenInviteProjectModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTests, setSelectedTests] = useState({
    id: null, tests: [], project: '',
  });
  const myStyle = {
    boxShadow: 'none',
    position: 'relative',
    background: '#fff',
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    zIndex: 1,
    padding: '5px',
    marginBottom: '12px',
    border: '1px solid #3f639859',
    borderRadius: '4px',
  };

  const cypressResults = async () => {
    try {
      setLoading(true);
      const res = await cypressResultList(page, rowsPerPage, search);
      if (res) {
        setTotalElements(res.totalElements || 0);
        setData(res.content);
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const onInvite = async (val) => {
    setTId(val?.id);
    try {
      const users = await projectService.getMemberList(val?.companyId, val?.projectId);
      setUserList(users);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    }
    setOpenInviteProjectModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newValue = event.target.value;
    if (newValue === totalElements) {
      setRowsPerPage(totalElements);
      setPage(0);
    } else {
      setRowsPerPage(parseInt(newValue, 10));
      setPage(0);
    }
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    cypressResults();
    setReportType(FileType.CYPRESS);
  }, []);

  const hasBuildVersion = dataList?.some((data) => data.jenkinsBuildVersion !== null);

  return (
    <>
      <ParentBox>
        <Breadcrumb data={[ReportSchema[reportType]?.name]} />
        <AppBar
          style={myStyle}
        >
          <Box
            component="form"
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'end',
              width: 265,
              boxShadow: 'none',
              border: '1px solid #3f639859',
            }}
            elevation="0"
          >
            <Typography>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="CYPRESS Report Search"
                inputProps={{ 'aria-label': 'CYPRESS Report Search' }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <IconButton
                type="button"
                sx={{
                  p: '10px',
                  fontSize: '13px',
                  color: 'white',
                  border: '1px solid white',
                  borderRadius: '5px',
                  background: '#466197',
                  width: '80px',
                  '&:hover': {
                    background: '#2e4166',
                  },
                }}
                onClick={() => cypressResults()}
                aria-label="search"
              >
                Search
              </IconButton>
            </Typography>
          </Box>
          <Box>
            <Button sx={{ mr: 2 }} size="large" variant="text" onClick={() => setSelectedFilter(null)}>
              <RefreshIcon />
              Reset
            </Button>
          </Box>
        </AppBar>
        {loading && <Loader />}
        {
          dataList?.length === 0 ? '' : <Grid item sm={12} xs={12}>
            {loading ? <Loader /> : <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{ width: '100%', bgcolor: '#3f6398' }}>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                    >
                      Report Name
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                    >
                      Execution Time
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                      }}
                    >
                      Company
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                      }}
                    >
                      Project
                    </TableCell>
                    {hasBuildVersion && <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        textAlign: 'center',
                      }}
                    >
                      Build Version
                    </TableCell>}
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        textAlign: 'center',
                      }}
                    >
                      Result
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        textAlign: 'center',
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataList?.length && dataList?.map((data) => (
                    <>
                      <TableRow key={data.id} sx={{ border: '1px solid #e0e0e0', 'td, th': { border: 0 } }}>
                        <TableCell
                          sx={{
                            fontSize: '14px',
                          }}
                        >
                          {data?.testReportName || 'Smoke test'}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '14px',
                          }}
                        >
                          {parseDate((data?.reportResult?.dataRow?.stats?.start))}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '14px',
                          }}
                        >
                          {readableTime(
                            Math.round((data?.reportResult?.dataRow?.stats?.duration || 0) / 1000),
                          )}
                        </TableCell>
                        <Tooltip title="Company">
                          <TableCell
                            sx={{
                              fontSize: '14px',
                              cursor: 'pointer',
                            }}
                          >
                            {data?.companyName}
                          </TableCell>
                        </Tooltip>
                        <Tooltip title="Project">
                          <TableCell
                            sx={{
                              fontSize: '14px',
                              cursor: 'pointer',
                            }}
                          >
                            {data?.projectName}
                          </TableCell>
                        </Tooltip>
                        {hasBuildVersion && <TableCell
                          align="center"
                          sx={{
                            justifyContent: 'center',
                            fontSize: '14px',
                            cursor: 'pointer',
                          }}
                        >
                          <Badge max={Number.MAX_VALUE} badgeContent={data?.jenkinsBuildVersion === 'null' ? null : data?.jenkinsBuildVersion} color="primary" sx={{ marginRight: '0px' }}>
                            {data?.jenkinsBuildVersion !== 'null' && <img src={jenkinsImg} alt="build-version" height={35} />}
                          </Badge>
                        </TableCell>}
                        <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center', padding: '32px' }}>
                          <Tooltip title="Passed">
                            <Grid
                              sx={{
                                backgroundColor: '#fff',
                                border: '11px solid #10b981',
                                borderRadius: '100px',
                                height: '38px',
                                width: '38px',
                                position: 'relative',
                                cursor: 'pointer',
                                ml: 2,
                              }}
                              onClick={() => {
                                setSelectedFilter(CypressTestStatus.PASSES);
                                setSelectedTests({
                                  id: data.id,
                                  tests: data?.reportResult?.dataRow,
                                  project: data.projectName,
                                });
                                setOpenDrawer(true);
                              }}
                            >
                              <Grid
                                sx={{
                                  position: 'absolute',
                                  top: '-21px',
                                  right: '-11px',
                                  background: '#fff',
                                  minWidth: '25px',
                                  borderRadius: '9px',
                                  border: '1px solid #10b981',
                                  fontWeight: 600,
                                  padding: '1px',
                                }}
                              >
                                {data?.reportResult?.dataRow?.stats?.passes}
                              </Grid>
                            </Grid>
                          </Tooltip>
                          <Tooltip title="Failed">
                            <Grid
                              sx={{
                                backgroundColor: '#fff',
                                border: '11px solid #f04438',
                                borderRadius: '100px',
                                height: '38px',
                                width: '38px',
                                position: 'relative',
                                cursor: 'pointer',
                                ml: 2,
                              }}
                              onClick={() => {
                                setSelectedFilter(CypressTestStatus.FAILURES);
                                setSelectedTests({
                                  id: data.id,
                                  tests: data?.reportResult?.dataRow,
                                  project: data.projectName,
                                });
                                setOpenDrawer(true);
                              }}
                            >
                              <Grid
                                sx={{
                                  position: 'absolute',
                                  top: '-21px',
                                  right: '-11px',
                                  background: '#fff',
                                  minWidth: '25px',
                                  borderRadius: '9px',
                                  border: '1px solid #e93e3d',
                                  fontWeight: 600,
                                }}
                              >
                                {data?.reportResult?.dataRow?.stats?.failures}
                              </Grid>
                            </Grid>
                          </Tooltip>
                          <Tooltip title="Skipped">
                            <Grid
                              sx={{
                                backgroundColor: '#fff',
                                border: '11px solid #174274',
                                borderRadius: '100px',
                                height: '38px',
                                width: '38px',
                                position: 'relative',
                                cursor: 'pointer',
                                ml: 2,
                              }}
                              onClick={() => {
                                setSelectedFilter(CypressTestStatus.SKIPPED);
                                setSelectedTests({
                                  id: data.id,
                                  tests: data?.reportResult?.dataRow,
                                  project: data.projectName,
                                });
                                setOpenDrawer(true);
                              }}
                            >
                              <Grid
                                sx={{
                                  position: 'absolute',
                                  top: '-21px',
                                  right: '-11px',
                                  background: '#fff',
                                  minWidth: '25px',
                                  borderRadius: '9px',
                                  border: '1px solid #174274',
                                  fontWeight: 600,
                                  padding: '1px',
                                }}
                              >
                                {data?.reportResult?.dataRow?.stats?.skipped}
                              </Grid>
                            </Grid>
                          </Tooltip>
                          <Tooltip title="Pending">
                            <Grid
                              sx={{
                                backgroundColor: '#fff',
                                border: '11px solid #acad32',
                                borderRadius: '100px',
                                height: '38px',
                                width: '38px',
                                position: 'relative',
                                cursor: 'pointer',
                                ml: 2,
                              }}
                              onClick={() => {
                                setSelectedFilter(CypressTestStatus.PENDING);
                                setSelectedTests({
                                  id: data.id,
                                  tests: data?.reportResult?.dataRow,
                                  project: data.projectName,
                                });
                                setOpenDrawer(true);
                              }}
                            >
                              <Grid
                                sx={{
                                  position: 'absolute',
                                  top: '-21px',
                                  right: '-11px',
                                  background: '#fff',
                                  minWidth: '25px',
                                  borderRadius: '9px',
                                  border: '1px solid #acad32',
                                  fontWeight: 600,
                                  padding: '1px',
                                }}
                              >
                                {data?.reportResult?.dataRow?.stats?.pending}
                              </Grid>
                            </Grid>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {
                            user?.role?.userPermissionsList.includes(UserPermission.INVITE_REPORT)
                            && <Button
                              onClick={(e) => {
                                onInvite(data);
                                e.stopPropagation();
                              }}
                              style={{
                                cursor: 'pointer',
                                marginLeft: '4px',
                              }}
                              color="info"
                              variant="outlined"
                              disabled={data?.companyStatus === InviteStatus.BLOCKED
                                || data?.projectStatus === InviteStatus.BLOCKED}
                            >
                              Invite Report
                            </Button>
                          }
                          <Button
                            onClick={() => {
                              setSelectedFilter(CypressTestStatus.ALL);
                              setSelectedTests({
                                id: data.id,
                                tests: data?.reportResult?.dataRow,
                                project: data.projectName,
                              });
                              setOpenDrawer(true);
                            }}
                            style={{
                              cursor: 'pointer',
                              marginLeft: '4px',
                            }}
                            color="info"
                            variant="outlined"
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>}
          </Grid>
        }
        {openDrawer && <CypressDrawer
          report={selectedTests?.tests}
          reportId={selectedTests?.id}
          project={selectedTests?.project}
          open={openDrawer}
          handleClose={handleClose}
          setSnackbarData={(data) => setSnackbar(data)}
        />}
        {snackbar.state && (
          <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
        )}
        {dataList?.length ? <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, totalElements]}
          count={totalElements}
          component="div"
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> : null}
        {
          dataList?.length === 0 ? <Typography variant="h5" align="center"> Data Not Found </Typography> : ''
        }
        {
          dataList?.length ? dataList?.map((a) => <Dailog
            openDailog={dataList && openInviteProjectModal}
            tId={tId}
            userList={userList}
            cid={a.companyId}
            pid={a.projectId}
            onOpen={(value) => setOpenInviteProjectModal(value)}
            setSnackbarData={(data) => setSnackbar(data)}
          />)
            : ''
        }
      </ParentBox>
    </>
  );
};

export default CypressList;
