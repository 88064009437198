import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(err, info) {
    console.log('error found:', err, info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div style={{ paddingTop: '100px', marginRight: '100px' }}>Something is wrong.</div>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.objectOf.isRequired,
};

export default ErrorBoundary;
