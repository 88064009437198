import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  CircularProgress,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Breadcrumb from '../breadcrumbs/Breadcrumb';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import profileService from '../../services/profile.service';
import {
  ParentBox,
  ButtonStyle,
} from './style';
import route from '../../utils/route';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function ProfileDetail() {
  const { id } = useParams();
  const location = useLocation();
  const [profileDetail, setProfileDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const navigate = useNavigate();

  useEffect(() => {
    async function getProfileDetail() {
      try {
        setLoading(true);
        const res = await profileService.getProfile(id);
        setProfileDetail(res);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error.message}`,
        });
      } finally {
        setLoading(false);
      }
    }
    getProfileDetail();
  }, [id]);
  const handleClick = () => {
    if (profileDetail?.companyId && profileDetail?.groupId) {
      navigate(`/createProflie/${profileDetail?.companyId}/${profileDetail?.groupId}`);
    }
  };
  return (
    <>
      {
        location.pathname === `/profileDetail/${id}` && (
          <Box
            sx={{
              marginTop: '100px',
              marginLeft: 4,
              marginRight: 4,
            }}
          >
            <ParentBox>
              <Grid container rowSpacing={3}>
                <Grid
                  item
                  xs={12}
                >
                  <Breadcrumb data={['Profile Detail']} />
                  <item>
                    <Button variant="contained" style={{ margin: '10px', marginLeft: '755px' }} startIcon={<AddIcon />} onClick={handleClick}>
                      Create Profile
                    </Button>
                  </item>

                </Grid>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Profile Name</StyledTableCell>
                        <StyledTableCell>Profile Status</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left">{profileDetail?.profileName}</StyledTableCell>
                        <StyledTableCell align="left">{profileDetail?.profileStatus}</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <ButtonStyle variant="contained" onClick={() => { navigate(`/${route.PROFILE_PAGE}`); }}> BACK </ButtonStyle>
              </Grid>
            </ParentBox>
            {loading && <CircularProgress style={{ marginLeft: '75px' }} />}
            {
              snackbar.state
              && (
                <NavigateSnackbar
                  snackbarObj={snackbar}
                  setSnackbar={setSnackbar}
                />
              )
            }
          </Box>
        )
      }
    </>
  );
}

export default ProfileDetail;
