import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Button = styled('Button')(({ theme }) => ({
  margin: theme.spacing(0.6),
  ...theme,
  background: '#3f6398',
  borderRadius: '40px',
  color: 'white',
  width: '100%',
  padding: '15px',
  fontSize: '15px',
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
}));
const WelcomeTextStyling = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.primary.main,
  display: 'block',
  textAlign: 'center',
  borderBottom: '2px solid',
  borderColor: '#e2e5e8',
  paddingBottom: '10px',
  fontSize: theme.fontSize.headerFontSize,
}));
const ButtonStyle = styled('Button')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
  background: '#3f6398',
  borderRadius: '4px',
  color: 'white',
  padding: '15px',
  fontSize: '15px',
}));

export {
  Button,
  Item,
  WelcomeTextStyling,
  ButtonStyle,
};
