/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CircleIcon from '@mui/icons-material/Circle';
import {
  Box, Grid, Paper, Tab, Tabs, Typography,
} from '@mui/material';
import { getDuration } from '../../Home/TestData';
import RenderReportList from '../../reportList/RenderReportList';
import {
  FileType, PlatwrightTestStatus, TagEnum, TestStatus,
} from '../../../utils/constants';
import JiraWarpper from '../../jiraWrapper';
import CommentWrapper from '../../commentWrapper';
import stopwatchIcon from '../../../assets/stopwatch.svg';
import commentService from '../../../services/comment.service';
import NavigateSnackbar from '../../navigateSnackbar/NavigateSnackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F5F5F5',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  summaryHeaderBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: theme.spacing(2),
    border: '1px solid #000000',
    margin: theme.spacing(2, 0),
    boxShadow: '-5px 5px 0px 0px #FFC72B',
    borderRadius: theme.spacing(1),
  },
  tabsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #E1E1E1',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    fontSize: '12px',
    margin: theme.spacing(2, 0),
  },
  summaryTitleWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    wordBreak: 'break-word',
  },
  summaryTitle: {
    fontWeight: 600,
    fontSize: '17px',
  },
  stepSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  testSummmaryBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    border: '1px solid #E1E1E1',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
  },
  testHeaderMain: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  testWrapper: {
    width: '100%',
    padding: theme.spacing(1, 2),
    border: '1px solid #E1E1E1',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
  },
  stepHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0, 2),
  },
  stepDetails: {
    padding: theme.spacing(2),
  },
  stepTitle: {
    display: 'flex',
    alignItems: 'baseline',
  },
  stepIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  investigateButton: {
    backgroundColor: '#5FD726',
    borderRadius: '24px',
    color: '#fff',
    fontWeight: '500',
    padding: theme.spacing(0.5, 2),
    whiteSpace: 'nowrap',
    '&:hover': {
      background: '#4ebf18',
    },
  },
  iconError: {
    fontSize: '12px',
    color: '#FF0000',
    marginRight: theme.spacing(1),
  },
  iconSuccess: {
    fontSize: '12px',
    color: '#5FD726',
    marginRight: theme.spacing(1),
  },
  browserInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(1, 0),
    background: '#fff',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #E7E7E7',
    '&:hover': {
      background: '#EFEFEF',
    },
  },
  chipSuccess: {
    color: '#fff',
    backgroundColor: '#5FD726',
    borderColor: '#5FD726',
    height: '25px',
  },
  chipFailure: {
    color: '#fff',
    backgroundColor: '#FF0000',
    borderColor: '#FF0000',
    height: '25px',
  },
  chipSkipped: {
    color: '#fff',
    backgroundColor: '#FFC72B',
    borderColor: '#FFC72B',
    height: '25px',
  },
  chipIgnored: {
    color: '#fff',
    backgroundColor: '#000000',
    borderColor: '#000000',
    height: '25px',
  },
  envIssue: {
    backgroundColor: '#5FD726',
    fontWeight: 600,
    '&.Mui-selected': {
      color: '#fff',
    },
  },
  productBug: {
    backgroundColor: '#FF0000',
    fontWeight: 600,
    '&.Mui-selected': {
      color: '#fff',
    },
  },
  automationBug: {
    backgroundColor: '#FFC72B',
    fontWeight: 600,
    '&.Mui-selected': {
      color: '#fff',
    },
  },
  noDefect: {
    backgroundColor: '#1D6AE5',
    fontWeight: 600,
    '&.Mui-selected': {
      color: '#fff',
    },
  },
  toInvestigate: {
    backgroundColor: '#000000',
    fontWeight: 600,
    '&.Mui-selected': {
      color: '#fff',
    },
  },
  TO_INVESTIGATE: {
    fontSize: '12px',
    color: '#D9D9D9',
    marginRight: theme.spacing(1),
  },
  PRODUCT_BUG: {
    fontSize: '12px',
    color: '#FF0000',
    marginRight: theme.spacing(1),
  },
  AUTOMATION_BUG: {
    fontSize: '12px',
    color: '#FFC72B',
    marginRight: theme.spacing(1),
  },
  NO_DEFECT: {
    fontSize: '12px',
    color: '#1D6AE5',
    marginRight: theme.spacing(1),
  },
  ENVIRONMENT_ISSUE: {
    fontSize: '12px',
    color: '#5FD726',
    marginRight: theme.spacing(1),
  },
}));

const TestReviewPage = ({
  bugTab,
  setBugTab,
  tags,
  id,
  project,
  selectedTestName,
  test,
  reportType,
}) => {
  const classes = useStyles();
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [jiraTickets, setJiraTickets] = useState([]);
  const [selectedTab, setSelectedTab] = useState();
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const tagClasses = [
    classes.productBug,
    classes.automationBug,
    classes.noDefect,
    classes.toInvestigate,
    classes.envIssue,
  ];

  // Helper function to set variables based on reportType
  const getTestDetails = () => {
    let resultStep; let hasFailed; let duration; let screenshots; let attachment; let testName;
    const tagClass = tags[selectedTestName]?.tagName;

    if (reportType === FileType.XML) {
      testName = test?.name;
      resultStep = test?.['reporter-output']?.line;
      hasFailed = test?.status === TestStatus.FAIL;
      duration = test['duration-ms'];
    } else if (reportType === FileType.PLAYWRIGHT) {
      resultStep = test?.steps;
      hasFailed = test?.status === PlatwrightTestStatus.FAILED;
      duration = test?.duration;
      screenshots = test.attachments.filter((attach) => attach.contentType && attach.contentType.startsWith('image/'));
      attachment = test.attachments.filter((attach) => attach.contentType && attach.contentType.startsWith('text/'));
    }

    return {
      testName, resultStep, hasFailed, tagClass, duration, screenshots, attachment,
    };
  };

  const {
    testName, resultStep, hasFailed, tagClass, duration, screenshots, attachment,
  } = getTestDetails();

  const tagClassName = Object.keys(TagEnum)[selectedTab] || tagClass;

  const createComment = async (e, val) => {
    try {
      setLoading(true);
      setSelectedTab(val);
      setBugTab(val);
      const commentData = {
        testName: selectedTestName,
        testReportId: id,
        comment: commentText || '',
        tagName: Object.keys(TagEnum)[val],
      };
      await commentService.create(commentData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const tabKey = Object.keys(TagEnum).indexOf(tags[selectedTestName]?.tagName) !== -1
      ? Object.keys(TagEnum).indexOf(tags[selectedTestName]?.tagName) : bugTab;
    setSelectedTab(tabKey);
  }, [tags, bugTab]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        {!test ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <Typography variant="body1" color="textSecondary">No Data Found</Typography>
          </Box>
        ) : (
          <>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="stretch"
                >
                  <Paper className={classes.summaryHeaderBox}>
                    <Box className={classes.summaryTitleWrapper}>
                      <Box flex={1}>
                        <Typography className={classes.summaryTitle} variant="h6">{testName || selectedTestName}</Typography>
                      </Box>
                      <Box className={classes.stepSummary}>
                        <Box className={classes.stepIcons}>
                          <Box display="flex" alignItems="center">
                            <img src={stopwatchIcon} width={20} alt="Stopwatch" />
                            <Typography variant="body2" color="textSecondary" ml={0.5}>{getDuration(duration || 0)}</Typography>
                          </Box>
                          {hasFailed && <Box display="flex" alignItems="center" ml={0.5}>
                            <CircleIcon
                              className={classes[tagClassName]}
                            />
                            <Typography sx={{ textTransform: 'capitalize' }} variant="body2" className={classes[tagClassName]}>{tagClassName?.split('_').join(' ') || 'To Investigate'}</Typography>
                          </Box>}
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                  {
                    reportType === FileType.PLAYWRIGHT
                    && <>
                      {test.status !== PlatwrightTestStatus.SKIPPED
                        && <>
                          {!!test.errors?.length && <Grid sx={{ width: '100%' }}>
                            <RenderReportList
                              isFailed={hasFailed}
                              title="Errors"
                              description={test?.errors}
                            />
                          </Grid>}
                          {!!resultStep && <Grid sx={{ width: '100%' }}>
                            <RenderReportList
                              isFailed={hasFailed}
                              title="Test Steps"
                              resultStep={resultStep}
                            />
                          </Grid>}
                          {!!screenshots && <Grid sx={{ width: '100%' }}>
                            <RenderReportList
                              isFailed={hasFailed}
                              title="ScreenShots"
                              resultStep={screenshots}
                            />
                          </Grid>}
                          {!!attachment && <Grid sx={{ width: '100%' }}>
                            <RenderReportList
                              isFailed={hasFailed}
                              title="Attachment"
                              resultStep={attachment}
                            />
                          </Grid>}
                        </>}
                      {test.status === PlatwrightTestStatus.SKIPPED && <Grid sx={{ width: '100%' }}>
                        <RenderReportList
                          isFailed={hasFailed}
                          title="Annotations"
                          description="Skip"
                        />
                      </Grid>}
                      {(test.status !== PlatwrightTestStatus.FAILED
                        && test.status !== PlatwrightTestStatus.PASSED
                        && test.status !== PlatwrightTestStatus.SKIPPED)
                        && <Typography
                          variant="body2"
                          color="text.secondary"
                        >
                          Unable to extract data
                        </Typography>}
                    </>
                  }
                  {
                    reportType === FileType.XML
                    && <>
                      {test.status === TestStatus.PASS
                        && <Grid sx={{ width: '100%' }}>
                          <RenderReportList
                            isFailed={false}
                            title="Description"
                            description={test?.signature}
                            resultStep={resultStep}
                          />
                        </Grid>}
                      {(test.status === TestStatus.FAIL || test.status === TestStatus.SKIP)
                        && <Grid sx={{ width: '100%' }}>
                          <RenderReportList
                            isFailed
                            title="Result Output"
                            description=""
                            resultStep={resultStep}
                          />
                          <RenderReportList
                            isFailed
                            title="Description"
                            description={test?.signature}
                          />
                          <RenderReportList
                            isFailed
                            title="Exception name"
                            description={test?.exception?.class}
                          />
                          <RenderReportList
                            isFailed
                            title="Exception description"
                            description={test?.exception?.message}
                          />
                          <RenderReportList
                            isFailed
                            title="Exception backtrace"
                            description={test?.exception?.['full-stacktrace']}
                          />
                        </Grid>}
                      {(test.status !== TestStatus.FAIL
                        && test.status !== TestStatus.SKIP
                        && test.status !== TestStatus.PASS)
                        && <Typography
                          variant="body2"
                          color="text.secondary"
                          textAlign="center"
                        >
                          Unable to extract data
                        </Typography>}
                    </>
                  }
                </Grid>
              </Grid>
              <Grid item xs={6} style={hasFailed ? { paddingTop: '8px' } : {}}>
                {hasFailed && (
                  <>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        className={classes.summaryTitle}
                      >
                        Select Defect Manually
                      </Typography>
                      <Tabs
                        indicatorColor="none"
                        textColor="primary"
                        className={classes.tabsWrapper}
                        value={selectedTab}
                        onChange={createComment}
                        sx={{
                          '& .MuiTabs-flexContainer': {
                            justifyContent: 'space-between',
                          },
                        }}
                      >
                        {Object.values(TagEnum).map((label, index) => (
                          <Tab
                            sx={{ padding: '12px' }}
                            key={index}
                            className={selectedTab === index ? tagClasses[index] : ''}
                            label={label}
                          />
                        ))}
                      </Tabs>
                    </Box>
                    <Paper className={classes.testSummmaryBox}>
                      <JiraWarpper
                        project={project}
                        test={test}
                        id={id}
                        selectedTestName={selectedTestName}
                        jiraTickets={jiraTickets}
                        setJiraTickets={setJiraTickets}
                        reportType={FileType.SELENIUM}
                        setSnackbarData={setSnackbar}
                      />
                    </Paper>
                  </>
                )}
                <Paper className={classes.testSummmaryBox}>
                  <Typography
                    variant="subtitle1"
                    className={classes.summaryTitle}
                  >
                    Post Comment
                  </Typography>
                  <CommentWrapper
                    selectedTestName={selectedTestName}
                    testReportId={id}
                    setComments={setComments}
                    comments={comments}
                    loading={loading}
                    setLoading={setLoading}
                    setCommentText={setCommentText}
                    commentText={commentText}
                    selectedChip={Object.keys(TagEnum)[selectedTab]}
                    // guestUser={guestUser}
                    jiraTickets={jiraTickets?.content}
                  // setSnackbarData={setSnackbarData}
                  />
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      {snackbar.state && (
        <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
      )}
    </>);
};
export default TestReviewPage;

TestReviewPage.propTypes = {
  tags: {},
  id: PropTypes.string,
  project: PropTypes.string,
  selectedTestName: PropTypes.string,
  bugTab: PropTypes.number,
  setBugTab: PropTypes.func,
  reportType: PropTypes.string,
  test: {},
};

TestReviewPage.defaultProps = {
  tags: {},
  id: '',
  project: '',
  selectedTestName: '',
  bugTab: 3, // TagEnum TO investigate at 3
  setBugTab: () => { },
  reportType: '',
  test: {},
};
