import {
  styled,
} from '@mui/material/styles';
import {
  TableCell,
  tableCellClasses,
  TableRow,
  Box,
} from '@mui/material';

const WelcomeTextStyling = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.primary.main,
  display: 'block',
  borderBottom: '1px solid',
  borderColor: '#e2e5e8',
  paddingBottom: '6px',
  marginBottom: '10px',
  fontSize: '16px',
  fontWeight: 'bold',
  padding: '16px 12px',
}));
const StyledBox = styled(Box)(() => ({
  background: '#fff',
  paddingBottom: '16px',
}));
const ParentBox = styled(Box)(() => ({
  marginTop: '65px',
  borderRadius: '4px',
}));
const StyledDailogAction = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: '20px',
}));
const StatusTextStyle = styled('span')(() => ({
  padding: '5px',
  borderRadius: '5px',
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
    fontSize: 16,
    textAlign: 'left',
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: '50%',
    textAlign: 'left',
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: theme.spacing(0),
  },
}));

export {
  WelcomeTextStyling, StyledTableCell, StyledTableRow, StyledBox, StyledDailogAction,
  StatusTextStyle, ParentBox,
};
