import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  Chip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import Loader from '../../utils/loader/Loader';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import { ReportSchema, TestStatus } from '../../utils/constants';

import {
  ParentBox,
  reportStyles,
} from './style';
import { useCommonContext } from '../header/context';
import Breadcrumb from '../breadcrumbs/Breadcrumb';
import { parseDate } from '../dashboard/utils';
import { readableTime } from '../../utils';
import { resultListById } from '../../services/uploadFile.service';
import commentService from '../../services/comment.service';
import reportService from '../../services/report.service';
import FullScreenModal from '../reportList/FullScreenModal';

const counter = {
  mount: 0,
  handleChange: 0,
  onTestSuitClick: 0,
};

const tagEnum = {
  PRODUCT_BUG: 'Product Bug',
  AUTOMATION_BUG: 'Automation Bug',
  SYSTEM_ISSUE: 'System Issue',
  TO_INVESTIGATE: 'To Investigate',
};

const backgroundEnum = {
  PRODUCT_BUG: '#ec3900',
  AUTOMATION_BUG: '#ffb743',
  SYSTEM_ISSUE: '#0274d1',
  TO_INVESTIGATE: 'green',
};

const XmlNotification = ({ data }) => {
  const style = reportStyles();
  const params = useParams();
  const { state: report } = useLocation();
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [reportData, setReportData] = useState(data);
  const [active, setActive] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [tags, setTags] = useState({});
  const [isGuest, setIsGuest] = useState(false);
  const [selectedTests, setSelectedTests] = useState({ id: null, tests: [], testName: '' });
  const {
    selectedfilter,
    setSelectedFilter,
    reportType,
    user,
  } = useCommonContext();

  const onTestSuitClick = (tests, id, testName) => {
    counter.onTestSuitClick += 1;
    setSelectedTests({ tests, id, testName });
    setOpenDrawer(true);
  };

  useEffect(() => {
    counter.mount += 1;
  }, []);

  const xmlResultListById = async (reportId) => {
    try {
      const res = await resultListById(reportId);
      if (res?.status === 200) {
        setReportData(res?.data);
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    }
  };

  useEffect(() => {
    if (report?.reportId) {
      xmlResultListById(report?.reportId);
    }
  }, []);

  const getSharedReport = async (id) => {
    setLoading(true);
    try {
      const res = await reportService.getSharedReport(id);
      if (Object.keys(res).length) {
        setReportData(res);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    }
  };

  useEffect(() => {
    if (!user && params?.id) {
      setIsGuest(true);
      getSharedReport(params?.id);
    }
  }, []);

  const getAllTags = (id) => {
    // setLoading(true);
    commentService.getTagNamebyReportId(id).then((res) => {
      if (Object.keys(res).length) {
        setTags(res);
      } else {
        console.error('Invalid response format:', res);
      }
      setLoading(false);
    }).catch((error) => {
      console.error('Error loading comments:', error);
      setLoading(false);
    });
  };

  const renderData = ({ testData, id }) => {
    let listData = testData;
    if (testData?.test) {
      if (!Array.isArray(testData?.test)) {
        listData = [testData?.test];
      } else {
        listData = testData?.test;
      }
    }
    return listData?.map((details, i) => {
      const originalItem = testData?.test ? details : details?.test;

      const itemArray = Array.isArray(originalItem) ? originalItem : [originalItem];

      return itemArray?.map((item) => {
        const tagVal = selectedfilter === TestStatus.FAIL ? (tags[item?.name] || { tagName: 'TO_INVESTIGATE' }) : '';
        const methods = item?.class?.['test-method'];
        const failedTest = methods?.length ? methods.filter(
          (a) => a.status === TestStatus.FAIL,
        ) : [];
        if (selectedfilter === TestStatus.PASS && failedTest.length) {
          return null;
        }
        if (selectedfilter === TestStatus.FAIL && failedTest.length === 0) {
          return null;
        }

        return (
          <>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => onTestSuitClick(item?.class?.['test-method'], id, item?.name)}
              key={item?.name}
            >
              <ListItemIcon>
                <SearchIcon className={style.searchicon} />
                {failedTest.length ? (
                  <ReportProblemIcon
                    className={style.failIcon}
                  />
                ) : (
                  <CheckIcon sx={{ color: 'green', mr: 2 }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={item?.name}
                secondary={item?.class?.name}
              />

              <Typography
                component="div"
                className={style.accordianTime}
              >
                <Typography
                  component="h1"
                  className={style.accordianHeader}
                >
                  <div>
                    {TestStatus.FAIL && !!tagVal && (
                      <Chip
                        label={tagEnum[tagVal?.tagName]}
                        style={{
                          margin: '5px',
                          height: '38px',
                          textTransform: 'capitalize',
                          borderRadius: '9px',
                          fontSize: '10px',
                          fontWeight: 600,
                          background: backgroundEnum[tagVal?.tagName],
                          color: '#fff',
                        }}
                      />
                    )}
                  </div>
                </Typography>
                {
                  failedTest.length ? <Tooltip title="Failed">
                    <Button
                      variant="contained"
                      className={style.failedlistButton}
                    >
                      {failedTest.length}
                    </Button>
                  </Tooltip> : <Tooltip title="Passed">
                    <Button
                      variant="contained"
                      className={style.testlistButton}
                    >
                      1
                    </Button>
                  </Tooltip>
                }
                <Button>
                  <Tooltip title="Show all details">
                    <FormatAlignLeftIcon />
                  </Tooltip>
                </Button>
              </Typography>
            </ListItemButton>
            {i !== listData?.length - 1 && (
              <Divider />
            )}
          </>
        );
      });
    });
  };

  const handleClose = () => {
    getAllTags(selectedTests?.id, 'close');
    setOpenDrawer(false);
  };

  return (
    <>
      <ParentBox>
        {loading && <Loader />}
        <Grid>
          <Breadcrumb data={[ReportSchema[reportType].name]} />
        </Grid>
        <Grid item sm={12} xs={12}>
          {reportData?.id && (
            <List
              sx={{ width: '100%', bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <>
                <ListItem>

                  <IconButton onClick={() => {
                    getAllTags(reportData?.id);
                    setActive((pre) => !pre);
                  }}
                  >
                    {active ? (
                      <ExpandMore />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                  <ListItemIcon>
                    <SummarizeIcon sx={{ color: '#3f6398', mr: 2 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={reportData?.testingResults['testng-results']?.suite?.name || 'Smoke test'}
                  />
                  <ListItemText
                    primary={parseDate(reportData?.testingResults['testng-results']?.suite['started-at'] || reportData?.testingResults['testng-results']?.suite[0]['started-at'])}
                  />
                  <ListItemText
                    primary={readableTime(Math.round((reportData?.testingResults['testng-results']?.suite['duration-ms'] || 0) / 1000))}
                  />
                  <Typography
                    component="div"
                    className={style.reportHeader}
                  >
                    <Tooltip title="Company">
                      <Typography
                        component="h1"
                        className={style.reportTime}
                      >
                        {reportData?.companyName}
                      </Typography>
                    </Tooltip>
                    <Tooltip
                      title="Project"
                    >
                      <Typography
                        component="h1"
                        className={style.reportTime}
                      >
                        {reportData?.projectName}
                      </Typography>
                    </Tooltip>
                    <Tooltip title="Passed">
                      <Grid
                        sx={{
                          backgroundColor: '#fff',
                          border: '11px solid #10b981',
                          borderRadius: '100px',
                          height: '38px',
                          width: '38px',
                          position: 'relative',
                          cursor: 'pointer',
                          ml: 2,
                        }}
                        onClick={() => {
                          setSelectedFilter(TestStatus.PASS);
                          getAllTags(reportData?.id);
                          setActive(true);
                        }}
                      >
                        <Grid
                          sx={{
                            position: 'absolute',
                            top: '-21px',
                            right: '-11px',
                            background: '#fff',
                            minWidth: '25px',
                            borderRadius: '9px',
                            border: '1px solid #10b981',
                            fontWeight: 600,
                            textAlign: 'center',
                            padding: '1px',
                          }}
                        >
                          {reportData?.testingResults['testng-results']?.passed}
                        </Grid>
                      </Grid>
                    </Tooltip>
                    <Tooltip title="Failed">
                      <Grid
                        sx={{
                          backgroundColor: '#fff',
                          border: '11px solid #f04438',
                          borderRadius: '100px',
                          height: '38px',
                          width: '38px',
                          position: 'relative',
                          cursor: 'pointer',
                          ml: 2,
                        }}
                        onClick={() => {
                          setSelectedFilter(TestStatus.FAIL);
                          getAllTags(reportData?.id);
                          setActive(true);
                        }}
                      >
                        <Grid
                          sx={{
                            position: 'absolute',
                            top: '-21px',
                            right: '-11px',
                            background: '#fff',
                            minWidth: '25px',
                            borderRadius: '9px',
                            border: '1px solid #e93e3d',
                            fontWeight: 600,
                            textAlign: 'center',
                          }}
                        >
                          {reportData?.testingResults['testng-results']?.failed}
                        </Grid>
                      </Grid>
                    </Tooltip>
                    <Button>
                      <Tooltip title="Show all details">
                        <FormatAlignLeftIcon />
                      </Tooltip>
                    </Button>
                  </Typography>

                </ListItem>
                <Collapse
                  in={active}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {renderData({
                      testData: reportData?.testingResults['testng-results']?.suite,
                      id: reportData?.id,
                    })}
                  </List>
                </Collapse>
              </>
            </List>
          )}
        </Grid>
        {openDrawer && <FullScreenModal
          tests={selectedTests.tests}
          guestUser={isGuest}
          id={reportData?.id || ''}
          selectedTestName={selectedTests?.testName}
          tagVal={tags[selectedTests?.testName]}
          open={openDrawer}
          handleClose={handleClose}
        />}
        {snackbar.state && (
          <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
        )}
      </ParentBox>
    </>
  );
};

XmlNotification.propTypes = {
  data: PropTypes.func.isRequired,
};

export default XmlNotification;
