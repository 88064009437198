import * as React from 'react';
import {
  Button,
  DialogTitle,
  Autocomplete,
  Checkbox,
  DialogActions,
  Divider,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { IconButtonStyle } from '../Modal/style';
import {
  Form, icon,
  checkedIcon,
} from '../projectlist/style';
import invitereportService from '../../services/invitereport.service';
import { FileType } from '../../utils/constants';

export default function ScrollDialog(
  {
    openDailog,
    tId,
    userList,
    onOpen,
    setSnackbarData,
    cid,
    pid,
  },
) {
  const [selectedhtmlUsers, setSelectedHtmlUsers] = React.useState([]);
  const onSubmit = async (e) => {
    e.preventDefault();
    const dateTime = new Date().toISOString();
    onOpen(false);
    try {
      await invitereportService.inviteForReport({
        companyId: cid,
        createAt: dateTime,
        listInvitedTo: selectedhtmlUsers,
        testReportFileFormat: FileType.HTML,
        testReportId: tId,
        updateAt: dateTime,
        projectId: pid,
      });
      setSnackbarData({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Report Invited Successfully ..!',
      });
    } catch (error) {
      setSnackbarData({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.data.message}`,
      });
    } finally {
      setSelectedHtmlUsers([]);
    }
  };
  const onClose = () => {
    setSelectedHtmlUsers([]);
    onOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openDailog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDailog]);
  return (
    <div>
      <Dialog
        open={openDailog}
        fullWidth
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <DialogTitle>
          Invite Report
        </DialogTitle>
        <Divider />
        <DialogContent />
        <IconButtonStyle
          onClick={() => onOpen(false)}
        >
          <CloseIcon />
        </IconButtonStyle>
        <Form onSubmit={(e) => onSubmit(e)}>
          <Autocomplete
            multiple
            value={selectedhtmlUsers}
            options={userList?.map((user) => user?.invitedTo?.email)}
            disableCloseOnSelect
            onChange={(event, newValue) => {
              setSelectedHtmlUsers(newValue);
            }}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
                {option}
              </li>
            )}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Checkboxes" />
            )}
          />
          <DialogActions>
            <Button autoFocus color="primary" variant="contained" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" autoFocus disabled={selectedhtmlUsers.length === 0} color="primary" variant="contained">
              Send
            </Button>
          </DialogActions>
        </Form>
        <DialogContent />
      </Dialog>
    </div>
  );
}

ScrollDialog.propTypes = {
  openDailog: PropTypes.bool,
  tId: PropTypes.string,
  cid: PropTypes.string,
  pid: PropTypes.string,
  userList: PropTypes.instanceOf(Array),
  onOpen: PropTypes.func,
  setSnackbarData: PropTypes.func,
};

ScrollDialog.defaultProps = {
  openDailog: false,
  tId: '',
  cid: '',
  pid: '',
  userList: [],
  onOpen: PropTypes.func,
  setSnackbarData: PropTypes.func,
};
