import moment from 'moment';
import { parseDate } from '../dashboard/utils';

const commonData = (test) => ({
  id: test.id,
  testReportName: test.testReportName,
  companyName: test.companyName,
  projectName: test.projectName,
  fileType: test.fileType,
  customUserResponse: {
    username: test.customUserResponse?.username || 'Unknown',
  },
});

export const getDuration = (data) => {
  let result = `${data} ms`;
  if (data >= 1000 && data < 60000) {
    // Less than a minute, show seconds
    result = `${(data / 1000).toFixed(1)} s`;
  } else if (data >= 60000 && data < 3600000) {
    // Less than an hour, show minutes and seconds
    const minutes = Math.floor(data / 60000);
    const seconds = ((data % 60000) / 1000).toFixed(0);
    result = `${minutes} min ${seconds} sec`;
  } else if (data >= 3600000) {
    // An hour or more, show hours, minutes, and seconds
    const hours = Math.floor(data / 3600000);
    const minutes = Math.floor((data % 3600000) / 60000);
    const seconds = ((data % 60000) / 1000).toFixed(0);
    result = `${hours} hr ${minutes} min ${seconds} sec`;
  }
  return result;
};

const segregateSeleniumData = (tests) => tests.map((test) => ({
  ...commonData(test),
  startedAt: parseDate(test.startedAt),
  passed: test.passed,
  failed: test.failed,
  skipped: test.skipped,
  ignored: test.ignored,
  duration: test?.testingResults ? getDuration(test?.testingResults['testng-results']?.suite['duration-ms'] || 0) : 0,
}));

const segregateSeleniumHTMLData = (tests) => tests.map((test) => {
  const {
    failed, passed, retried, skipped, totalTime,
  } = test.testingResults;
  return {
    ...commonData(test),
    startedAt: parseDate(moment(Number(test.startedAt)).format('YYYY-MM-DDTHH:mm:ss')),
    passed,
    failed,
    skipped,
    ignored: retried,
    duration: getDuration(totalTime || 0),
  };
});

const segregatePlaywrightData = (tests) => tests.map((test) => {
  const { stats, startTime } = test.reportResult;
  const date = parseDate(moment(startTime).format('YYYY-MM-DDTHH:mm:ss'));
  return {
    ...commonData(test),
    startedAt: date,
    passed: stats.expected,
    failed: stats.unexpected,
    skipped: stats.skipped,
    ignored: stats.flaky,
    duration: getDuration(test?.reportResult?.duration || 0),
  };
});

const segregateCypressData = (tests) => tests.map((test) => {
  const { stats } = test?.reportResult?.dataRow;
  const date = parseDate(stats.start);
  return {
    ...commonData(test),
    startedAt: date,
    passed: stats.passes,
    failed: stats.failures,
    skipped: stats.skipped,
    ignored: stats.pending,
    duration: getDuration(stats?.duration || 0),
  };
});

export {
  segregateSeleniumHTMLData, segregateSeleniumData, segregatePlaywrightData, segregateCypressData,
};
