import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  TableRow,
  TableHead,
  TablePagination,
  TableContainer,
  TableBody,
  Table,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import Loader from '../../utils/loader/Loader';
import groupService from '../../services/inviteUser.service';
import {
  WelcomeTextStyling,
  StyledTableCell,
  StyledTableRow,
  StyledBox,
  ParentBox,
} from './style';
import Dailog from './Dailog';
import Breadcrumb from '../breadcrumbs/Breadcrumb';

function AdminInviteProject() {
  const [loading, setLoading] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [open, onOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCounts] = useState(0);
  const [projectList, setProjectList] = useState([]);
  const [isUserStatusChanged, setISUserStatusChanged] = useState(false);
  const [activeListItem, setActiveListItem] = useState({});
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });

  const getUserProjects = async () => {
    try {
      setLoading(true);
      const response = await groupService.getInvitedUsersDistinct(page, rowsPerPage);
      setUserList(response.content);
      setTotalCounts(response.totalElements);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  const getProjects = async (item) => {
    setActiveListItem(item);
    try {
      setProjectsLoading(true);
      setProjectList(item.projectInviteResponseList);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    } finally {
      setProjectsLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    getUserProjects();
  }, [rowsPerPage, page, isUserStatusChanged]);

  useEffect(() => {
    if (activeListItem.length) {
      getProjects(activeListItem);
      onOpen(false);
    }
  }, [isUserStatusChanged, activeListItem]);

  return (
    <>
      {
        userList.length && <ParentBox>
          <Breadcrumb data={['Invited Projects']} />
          <StyledBox>
            <Grid item xs={7}>
              <WelcomeTextStyling> Invited Projects</WelcomeTextStyling>
            </Grid>
            <Grid sx={{ px: 1.5 }}>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  {loading ? <Loader /> : <TableHead>
                    <TableRow sx={{ background: 'linear-gradient(146deg,#3f6398 8%,#3f6398 93%)' }}>
                      <StyledTableCell>User Email</StyledTableCell>
                      <StyledTableCell>Projects</StyledTableCell>
                    </TableRow>
                  </TableHead>}
                  <TableBody>
                    <>
                      {
                        userList && userList?.map((item) => (
                          <StyledTableRow key={item.projectInviteId}>
                            <StyledTableCell>{item?.invitedTo}</StyledTableCell>
                            <StyledTableCell>
                              <Button
                                startIcon={<CheckIcon />}
                                color="info"
                                variant="outlined"
                                onClick={() => {
                                  onOpen(true);
                                  getProjects(item);
                                }}
                              >
                                View Projects
                              </Button>
                            </StyledTableCell>

                          </StyledTableRow>
                        ))
                      }
                      {
                        snackbar.state
                        && (
                          <NavigateSnackbar
                            snackbarObj={snackbar}
                            setSnackbar={setSnackbar}
                          />
                        )
                      }
                    </>
                  </TableBody>
                </Table>

                {userList?.length && <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  count={totalCount}
                  component="div"
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />}
              </TableContainer>
            </Grid>
          </StyledBox>
        </ParentBox>
      }
      <Dailog
        projectList={projectList}
        openDailog={open}
        projectsLoading={projectsLoading}
        onOpen={(value) => onOpen(value)}
        setSnackbarData={(data) => setSnackbar(data)}
        setISUserStatusChanged={setISUserStatusChanged}
        isUserStatusChanged={isUserStatusChanged}
      />
      {
        snackbar.state
        && (
          <NavigateSnackbar
            snackbarObj={snackbar}
            setSnackbar={setSnackbar}
          />
        )
      }
    </>
  );
}

export default AdminInviteProject;
