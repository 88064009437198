import * as React from 'react';
// import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Snackbar } from '@mui/material';

export default function NavigateSnackbar({
  snackbarObj,
  setSnackbar,
}) {
  let message = snackbarObj.message.split(':');
  message = message.slice(-1);
  // const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbarObj, state: false });
    // navigate(snackbarObj.navigateText);
  };

  return (
    <Snackbar
      open={snackbarObj.state}
      autoHideDuration={2500}
      onClose={handleClose}
      data-testid="snackbarTestid"
      title="snackbarTitle"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Alert onClose={handleClose} severity={snackbarObj.severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
NavigateSnackbar.propTypes = {
  snackbarObj: PropTypes.shape({
    navigateText: PropTypes.string,
    message: PropTypes.string.isRequired,
    state: PropTypes.bool.isRequired,
    severity: PropTypes.string.isRequired,
  }).isRequired,
  setSnackbar: PropTypes.func.isRequired,
};
