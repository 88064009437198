import { styled } from '@mui/styles';
import {
  Box,
  Grid,
  DialogContent,
  Dialog,
  Button,
  MenuItem,
  IconButton,
} from '@mui/material';

const DialogStyle = styled(Dialog)(({ theme }) => ({
  width: theme.spacing('auto'),
  height: theme.spacing('100%'),
}));

const ParentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(0),
    overflow: 'hidden',
  },
  overflow: 'hidden',
}));

const DialogContentStyle = styled(DialogContent)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    // height: theme.spacing(30),
    overflowY: 'scroll',
    display: 'grid',
    alignItems: 'center',
  },
  width: theme.spacing('auto'),
  // height: theme.spacing(42),
}));

const ButtonStyle = styled(Button)(({ theme }) => ({
  background: '#1d6ae5',
  color: 'white',
  display: 'flex',
  width: theme.spacing(20),
  // height: theme.spacing(5),
  borderRadius: theme.spacing(0.5),
  padding: '8px',
  textAlign: 'center',
  alignItems: 'center',
  textTransform: 'none',
  '&:hover': {
    background: '#165bb5',
  },
  cursor: 'pointer',
  margin: '15px',
}));

const ParentGrid = styled(Grid)(({ theme }) => ({
  display: 'grid',
  borderRadius: 50,
  width: theme.spacing('100%'),
}));
const InputParentGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(0),
    width: theme.spacing('100%'),
    alignItems: 'center',
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: theme.spacing('100%'),
  borderRadius: 50,
}));

const SelectStyle = styled(MenuItem)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 0,
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    marginTop: 0,
  },
  display: 'flex',
}));
const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(1.5),
  color: theme.palette.grey[500],
}));

const inputStyleObj = {
  position: 'absolute',
  display: 'inline-block',
  maxWidth: 'auto',
  height: '56px',
  zIndex: 10000,
  lineHeight: '48px',
  opacity: 0,
};
export {
  DialogStyle,
  ButtonStyle,
  inputStyleObj,
  SelectStyle,
  InputParentGrid,
  IconButtonStyle,
  ParentBox,
  DialogContentStyle,
  ParentGrid,
};
