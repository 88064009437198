import { makeStyles, createStyles } from '@mui/styles';

const BreadcrumbsStyle = makeStyles((theme) => createStyles({
  routeBreadcrumb: {
    background: 'linear-gradient(146deg,#3f6398 8%,#3f6398 93%)',
    height: 40,
    alignItems: 'center',
    display: 'flex',
    borderRadius: '4px',
    marginBottom: 12,
    color: theme.palette.secondary.main,
  },
  routeBreadcrumbBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  routeBreadcrumbHomeICon: {
    color: '#FFf',
    marginLeft: 12,
    paddingRight: 6,
    paddingBottom: 4,
  },
  reportsTypography: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#FFF',
    paddingBottom: 3,
  },
  dashboardTypography: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#FFF',
    paddingBottom: 3,
    cursor: 'pointer',
    marginLeft: 1,
    '&:empty': {
      display: 'none',
    },
  },

}));

export default BreadcrumbsStyle;
