import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import {
  Grid,
  Chip,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CheckIcon from '@mui/icons-material/Check';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CommentWrapper from '../../commentWrapper';
import commentService from '../../../services/comment.service';
import { DashboardEnum, PlatwrightTestStatus } from '../../../utils/constants';
import { TestsPropType } from '../../../Types/test';
import RenderReportList from '../RenderReportList';
import JiraWarpper from '../../jiraWrapper';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const tagEnum = {
  PRODUCT_BUG: 'Product Bug',
  AUTOMATION_BUG: 'Automation Bug',
  SYSTEM_ISSUE: 'System Issue',
  TO_INVESTIGATE: 'To Investigate',
};

const backgroundEnum = {
  PRODUCT_BUG: '#ec3900',
  AUTOMATION_BUG: '#ffb743',
  SYSTEM_ISSUE: '#0274d1',
  TO_INVESTIGATE: 'green',
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
}));

const FullScreenDrawer = ({
  tests,
  id,
  selectedTestName,
  tagVal,
  guestUser,
  handleClose,
  open,
  project,
  setSnackbarData,
}) => {
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedChip, setSelectedChip] = useState(tagVal?.tagName || 'TO_INVESTIGATE');
  const [jiraTickets, setJiraTickets] = useState([]);

  const createComment = async (chip) => {
    try {
      setLoading(true);
      const commentData = {
        testName: selectedTestName,
        testReportId: id,
        comment: commentText,
        tagName: chip,
      };
      await commentService.create(commentData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChipClick = (chipName) => {
    let chip = null;
    if (selectedChip !== chipName) {
      setSelectedChip(chipName);
      chip = chipName;
    } else {
      setSelectedChip(null);
      chip = null;
    }
    createComment(chip);
  };

  const RenderTime = ({ data }) => {
    let result = `${data} ms`;

    if (data >= 1000 && data < 60000) {
      result = `${(data / 1000).toFixed(1)} s`;
    }
    if (data >= 60000) {
      result = `${(data / 60000).toFixed(1)} mins`;
    }
    return result;
  };

  const renderSteps = (steps) => steps.map((step) => (
    <Accordion sx={{ '&:before': { backgroundColor: 'transparent' } }} key={step.title} elevation={0}>
      <AccordionSummary
        disabled={!step.steps.length}
        sx={{
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg) !important',
          },
          flexDirection: 'row-reverse',
          alignItems: 'center',
          opacity: '1 !important',
        }}
        expandIcon={step.steps.length ? <ChevronRightIcon /> : null}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!step.error ? <CheckIcon sx={{ color: 'success.main' }} /> : <CloseIcon sx={{ color: 'warning.main' }} />}
            <Typography ml={0.5}>{step?.title}</Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {step.steps && step.steps.length > 0 && renderSteps(step.steps)}
      </AccordionDetails>
    </Accordion>
  ));

  const renderAccordionForTest = (steps) => (
    <div>
      {steps.map((item) => (
        <Accordion sx={{ '&:before': { backgroundColor: 'transparent' } }} key={item.title} elevation={0}>
          <AccordionSummary
            disabled={!item.steps.length}
            sx={{
              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(90deg) !important',
              },
              flexDirection: 'row-reverse',
              alignItems: 'center',
              opacity: '1 !important',
            }}
            expandIcon={item.steps.length ? <ChevronRightIcon /> : null}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {!item.error ? <CheckIcon sx={{ color: 'success.main' }} /> : <CloseIcon sx={{ color: 'warning.main' }} />}
                <Typography ml={0.5}>
                  {item?.title}
                </Typography>
              </Box>
              <Typography>
                {(item?.duration / 1000).toFixed(1)}
                s
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {item.steps && item.steps.length > 0 && renderSteps(item.steps)}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
  const renderScreenShots = (screenshot) => (
    <Box>
      <a href={`${process.env.REACT_APP_BACKEND_BASE_URL}/user/getImage/SCREENSHOT/${screenshot.path.split('data/')[1]}`} style={{ textDecoration: 'none', color: '#333' }}>
        <Box sx={{ maxWidth: 480, marginTop: 1 }}>
          <img width="100%" src={`${process.env.REACT_APP_BACKEND_BASE_URL}/user/getImage/SCREENSHOT/${screenshot.path.split('data/')[1]}`} alt={screenshot.name} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', m: 2 }}>
          <SummarizeIcon sx={{ color: 'gray', mr: 2 }} />
          <Typography variant="body1" sx={{ textDecoration: 'underline', color: 'blue', ml: 0.5 }}>
            {screenshot?.name}
          </Typography>
        </Box>
      </a>
    </Box>
  );

  const renderAccordinForAttachment = (attachment) => (<Accordion sx={{ '&:before': { backgroundColor: 'transparent' } }} elevation={0}>
    <AccordionSummary
      sx={{
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg) !important',
        },
        flexDirection: 'row-reverse',
        alignItems: 'center',
        opacity: '1 !important',
      }}
      expandIcon={<ChevronRightIcon />}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <SummarizeIcon sx={{ color: 'gray' }} />
          <Typography ml={0.5}>
            {attachment?.name}
          </Typography>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Box sx={{ ml: 2, padding: 2, backgroundColor: '#f6f8fa' }}>
        {attachment.body.split('\n')?.map((line) => (
          <Typography
            variant="body2"
            sx={{
              color: '#333',
            }}
            fontFamily="Space Mono"
          >
            {line}
          </Typography>
        ))}
      </Box>
    </AccordionDetails>
  </Accordion>
  );

  const hasFailed = tests?.some((test) => test.status === PlatwrightTestStatus.FAILED);

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" fontWeight={600} component="div">
              Execution Summary
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ flexGrow: 1, m: 4 }}>
          {tests.length === 0 ? (
            <p style={{ marginLeft: '6%' }}>No data found</p>
          ) : (
            tests?.map((test) => {
              const resultStep = test?.steps;
              const screenshots = test?.attachments[0];
              const attachment = test?.attachments[1];
              return (
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-evenly"
                      alignItems="stretch"
                    >
                      <Item sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} direction="column">
                        <Grid display="flex" alignItems="center">
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            fontFamily="Open Sans"
                            textAlign="left"
                            color="#323232"
                            padding={2}
                            width={hasFailed ? '400px' : '550px'}
                            sx={{ wordBreak: 'break-word' }}
                          >
                            {selectedTestName}
                          </Typography>
                          <Typography
                            sx={{
                              color: 'text.secondary',
                              fontSize: '12px',
                            }}
                          >
                            <RenderTime data={test.duration} />
                          </Typography>
                        </Grid>
                        {hasFailed && <Chip
                          label={tagEnum[selectedChip]}
                          style={{
                            margin: '5px',
                            width: '140px',
                            height: '40px',
                            fontFamily: 'Open Sans',
                            textTransform: 'capitalize',
                            borderRadius: '9px',
                            fontSize: '10px',
                            fontWeight: 600,
                            background: backgroundEnum[selectedChip],
                            color: '#fff',
                          }}
                        />}
                      </Item>
                      {test.status !== PlatwrightTestStatus.SKIPPED
                      && <>
                        {!!test.errors.length && <Grid sx={{ width: '100%' }}>
                          <RenderReportList
                            titleIcon={<FormatQuoteIcon color="primary" fontSize="large" />}
                            title="Errors"
                            description={test?.errors}
                          />
                        </Grid>}
                        {!!resultStep && <Grid sx={{ width: '100%' }}>
                          <RenderReportList
                            titleIcon={<FormatQuoteIcon color="primary" fontSize="large" />}
                            title="Test Steps"
                            resultStep={renderAccordionForTest(resultStep)}
                          />
                        </Grid>}
                        {!!screenshots && <Grid sx={{ width: '100%' }}>
                          <RenderReportList
                            titleIcon={<FormatQuoteIcon color="primary" fontSize="large" />}
                            title="ScreenShots"
                            resultStep={renderScreenShots(screenshots)}
                          />
                        </Grid>}
                        {!!attachment && <Grid sx={{ width: '100%' }}>
                          <RenderReportList
                            titleIcon={<FormatQuoteIcon color="primary" fontSize="large" />}
                            title="Attachment"
                            resultStep={renderAccordinForAttachment(attachment)}
                          />
                        </Grid>}
                      </>}
                      {test.status === PlatwrightTestStatus.SKIPPED && <Grid sx={{ width: '100%' }}>
                        <RenderReportList
                          titleIcon={<FormatQuoteIcon color="primary" fontSize="large" />}
                          title="Annotations"
                          description="Skip"
                        />
                        </Grid>}
                      {(test.status !== PlatwrightTestStatus.FAILED
                        && test.status !== PlatwrightTestStatus.PASSED
                        && test.status !== PlatwrightTestStatus.SKIPPED)
                        && <Typography
                          variant="body2"
                          color="text.secondary"
                          fontFamily="Space Mono"
                        >
                          Unable to extract data
                        </Typography>}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    {hasFailed && (
                      <>
                        <Item>
                          <Typography
                            variant="subtitle1"
                            fontSize="15px"
                            fontWeight="bold"
                            fontFamily="Open Sans"
                            textAlign="left"
                            color="#323232"
                            p={1.3}
                          >
                            Select Defect Manually
                          </Typography>
                          <Grid display="flex" flexDirection="row" p={1}>
                            <Chip
                              label="Product Bug"
                              disabled={guestUser}
                              onClick={() => handleChipClick('PRODUCT_BUG')}
                              style={{
                                margin: '5px',
                                width: '140px',
                                height: '40px',
                                fontFamily: 'Open Sans',
                                textTransform: 'capitalize',
                                borderRadius: '9px',
                                fontSize: '10px',
                                fontWeight: 600,
                                // background: '#ec3900',
                                // color: '#fffff',
                                background: `${selectedChip === 'PRODUCT_BUG' ? '#ec3900' : '#f5eced'}`,
                                color: `${selectedChip === 'PRODUCT_BUG' ? '#fff' : '#000'}`,
                              }}
                            />
                            <Chip
                              label="Automation Bug"
                              disabled={guestUser}
                              onClick={() => handleChipClick('AUTOMATION_BUG')}
                              style={{
                                margin: '5px',
                                width: '140px',
                                height: '40px',
                                fontFamily: 'Open Sans',
                                textTransform: 'capitalize',
                                borderRadius: '9px',
                                fontSize: '10px',
                                fontWeight: 600,
                                // background: '#ffb743',
                                // color: '#fffff',
                                background: `${selectedChip === 'AUTOMATION_BUG' ? '#ffb743' : '#f5eced'}`,
                                color: `${selectedChip === 'AUTOMATION_BUG' ? '#fff' : '#000'}`,
                              }}
                            />
                            <Chip
                              label="System Issue"
                              disabled={guestUser}
                              onClick={() => handleChipClick('SYSTEM_ISSUE')}
                              style={{
                                margin: '5px',
                                width: '140px',
                                height: '40px',
                                fontFamily: 'Open Sans',
                                textTransform: 'capitalize',
                                borderRadius: '9px',
                                fontSize: '10px',
                                fontWeight: 600,
                                // background: '#0274d1',
                                // color: '#fffff',
                                background: `${selectedChip === 'SYSTEM_ISSUE' ? '#0274d1' : '#f5eced'}`,
                                color: `${selectedChip === 'SYSTEM_ISSUE' ? '#fff' : '#000'}`,
                              }}
                            />
                            <Chip
                              label="To Investigate"
                              disabled={guestUser}
                              onClick={() => handleChipClick('TO_INVESTIGATE')}
                              style={{
                                margin: '5px',
                                width: '140px',
                                height: '40px',
                                fontFamily: 'Open Sans',
                                textTransform: 'capitalize',
                                borderRadius: '9px',
                                fontSize: '10px',
                                fontWeight: 600,
                                background: `${selectedChip === 'TO_INVESTIGATE' ? 'green' : '#f5eced'}`,
                                color: `${selectedChip === 'TO_INVESTIGATE' ? '#fff' : '#000'}`,
                              }}
                            />
                          </Grid>
                        </Item>
                        <Item>
                          <JiraWarpper
                            project={project}
                            test={test}
                            id={id}
                            selectedTestName={selectedTestName}
                            setSnackbarData={setSnackbarData}
                            jiraTickets={jiraTickets}
                            setJiraTickets={setJiraTickets}
                            reportType={DashboardEnum.PLAYWRIGHT}
                          />
                        </Item>
                      </>
                    )}
                    <Item>
                      <Typography
                        variant="subtitle1"
                        fontSize="15px"
                        fontWeight="bold"
                        fontFamily="Open Sans"
                        textAlign="left"
                        color="#323232"
                        px={1.3}
                        pt={1.3}
                      >
                        Post Comment
                      </Typography>
                      <CommentWrapper
                        selectedTestName={selectedTestName}
                        testReportId={id}
                        setComments={setComments}
                        comments={comments}
                        loading={loading}
                        setLoading={setLoading}
                        setCommentText={setCommentText}
                        commentText={commentText}
                        selectedChip={selectedChip}
                        guestUser={guestUser}
                        jiraTickets={jiraTickets?.content}
                        setSnackbarData={setSnackbarData}
                      />
                    </Item>
                  </Grid>
                </Grid>
              );
            })
          )}
        </Box>
      </Dialog>
    </>
  );
};

FullScreenDrawer.propTypes = {
  tests: TestsPropType,
  id: PropTypes.string,
  selectedTestName: PropTypes.string,
  tagVal: PropTypes.string,
  guestUser: PropTypes.bool,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  project: PropTypes.string,
  setSnackbarData: PropTypes.func,
};

FullScreenDrawer.defaultProps = {
  tests: [],
  id: '-1',
  selectedTestName: '',
  tagVal: '', // Changed to string, assuming it's always passed as string
  guestUser: false,
  handleClose: () => {},
  open: false,
  project: '',
  setSnackbarData: PropTypes.func,
};

export default FullScreenDrawer;
