import { useEffect, useState } from 'react';
import commonUtils from '../../utils/common/common';

export default function Validation(data) {
  const [error, setError] = useState({});
  const {
    email,
    password,
    firstName,
    lastName,
    mobile,
    username,
  } = data.userDetail;
  const handleErrors = () => {
    const validEmail = commonUtils.validateEmail.test(email);
    const validPassword = commonUtils.validatePassword.test(password);
    const validName = commonUtils.validName.test(firstName);
    const validlastName = commonUtils.validName.test(lastName);
    const validatePhone = commonUtils.validatePhone.test(mobile);
    const validateUserName = commonUtils.validName.test(username);

    const newErrors = { ...error };
    if (email && !validEmail) {
      newErrors.email = true;
    } else {
      newErrors.email = false;
    }
    if (password && !validPassword) {
      newErrors.password = true;
    } else {
      newErrors.password = false;
    }
    if (firstName && !validName) {
      newErrors.firstName = true;
    } else {
      newErrors.firstName = false;
    }
    if (lastName && !validlastName) {
      newErrors.lastName = true;
    } else {
      newErrors.lastName = false;
    }
    if (mobile && !validatePhone) {
      newErrors.mobile = true;
    } else {
      newErrors.mobile = false;
    }
    if (username && !validateUserName) {
      newErrors.username = true;
    } else {
      newErrors.username = false;
    }
    setError(newErrors);
  };

  useEffect(() => {
    handleErrors();
  }, [email, password, firstName, lastName, mobile, username]);

  return { error };
}
