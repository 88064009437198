/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Collapse, Box, Typography, Button, IconButton, Grid, Tabs, Tab, TextField, MenuItem, Paper, Chip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WindowIcon from '@mui/icons-material/Window';
import GoogleLogo from '../../assets/images/google-logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F5F5F5',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  summaryBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    border: '1px solid #f0f0f0',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
  },
  stepHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 0),
    margin: theme.spacing(0, 2),
    borderBottom: '1px solid #e0e0e0',
  },
  stepHeaderMain: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 0),
  },
  stepDetails: {
    padding: theme.spacing(2),
    backgroundColor: '#F5F5F5',
  },
  stepSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stepTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  stepIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  investigateButton: {
    backgroundColor: '#5FD726',
    borderRadius: '24px',
    color: '#fff',
    fontWeight: '500',
    padding: theme.spacing(0.5, 2),
    whiteSpace: 'nowrap',
    '&:hover': {
      background: '#4ebf18',
    },
  },
  iconError: {
    fontSize: '12px',
    color: '#FF0000',
    marginRight: theme.spacing(1),
  },
  iconSuccess: {
    fontSize: '12px',
    color: '#5FD726',
    marginRight: theme.spacing(1),
  },
  browserInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderBottom: '1px solid #e0e0e0',
  },
  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(1, 0),
    background: '#fff',
    borderRadius: theme.shape.borderRadius,
  },
  metricTitle: {
    color: '#707070',
  },
  tabPanel: {
    padding: 16,
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  buttonMain: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitButton: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(2),
    textTransform: 'capitalize',
  },
  balloon: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    maxWidth: '200px',
    minHeight: '30px',
    minWidth: '60px',
    overflow: 'visible',
    padding: theme.spacing(1.3, 2),
    border: '1px solid #5FD726',
    color: '#5FD726 !important',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '-7px',
      left: '20px',
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: '7px solid #5FD726',
    },
  },
  chipSuccess: {
    color: '#5fd726',
    borderColor: '#c6eab6',
  },
  chipFailure: {
    color: '#ff0000',
    borderColor: '#ffd0d0',
    margin: theme.spacing(0, 2),
  },
  chipWarning: {
    color: '#d09c0c',
    borderColor: '#ffd358',
  },
}));

const TestStep = ({
  title, time, status, details,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getChipClass = (chip) => {
    if (chip.label === 3) {
      return classes.chipSuccess;
    }
    if (chip.label === 1) {
      return classes.chipFailure;
    }
    return classes.chipWarning;
  };

  const chipData = [
    { label: '3' },
    { label: '1' },
    { label: '0' },
  ];

  return (
    <>
      <Box className={classes.stepHeaderMain}>
        <Box>
          <Typography variant="h6" sx={{ maxWidth: '90%' }}>Adding product to cart</Typography>
          <Typography variant="body1" sx={{ maxWidth: '70%' }} color="textSecondary">cypress/e2e/test/addtocart.cy.js</Typography>
        </Box>
        <Box className={classes.stepSummary}>
          <Box className={classes.stepIcons}>
            <Box mx={2} display="flex" alignItems="center">
              <AccessTimeIcon fontSize="small" />
              <Typography variant="body2" color="textSecondary" ml={0.5}>9:25</Typography>
            </Box>
          </Box>
          <Box>
            {chipData.map((chip) => (
              <Chip
                variant="outlined"
                key={chip.id}
                label={chip.label}
                className={getChipClass(chip)}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box className={classes.root}>
        <Box className={classes.stepHeader}>
          <Box className={classes.stepTitle}>
            <CircleIcon className={status === 'error' ? classes.iconError : classes.iconSuccess} />
            <Typography variant="body1" sx={{ maxWidth: '70%' }}>{title}</Typography>
          </Box>
          <Box className={classes.stepSummary}>
            <Button className={classes.investigateButton}>TO INVESTIGATE</Button>
            <Box className={classes.stepIcons}>
              <Box mx={2} display="flex" alignItems="center">
                <AccessTimeIcon fontSize="small" />
                <Typography variant="body2" color="textSecondary" ml={0.5}>{time}</Typography>
              </Box>
              <IconButton size="small" onClick={handleExpandClick}>
                <ExpandMoreIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Collapse in={expanded}>
          <Box className={classes.stepDetails}>
            <Box className={classes.browserInfo}>
              <img src={GoogleLogo} alt="google logo" width={20} style={{ marginRight: 4 }} />
              <Typography variant="body2" color="textSecondary">Chrome 115</Typography>
              <WindowIcon sx={{ mr: 0.5, ml: 1, color: '#00ADEF' }} />
              <Typography variant="body2" color="textSecondary">win</Typography>
            </Box>
            {details.map((detail, index) => (
              <Box key={index.id} display="flex" alignItems="center" className={classes.category}>
                <CircleIcon className={detail.status === 'success' ? classes.iconSuccess : classes.iconError} />
                <Typography variant="body2" className={classes.metricTitle}>{detail.text}</Typography>
                <AccessTimeIcon fontSize="small" style={{ marginLeft: 'auto' }} />
                <Typography variant="body2" color="textSecondary" style={{ marginLeft: 8 }}>{detail.time}</Typography>
              </Box>
            ))}
          </Box>
        </Collapse>
      </Box>
    </>
  );
};
const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};
const MainTabPanel = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [issueType, setIssueType] = useState('');
  const [description, setDescription] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleIssueTypeChange = (event) => {
    setIssueType(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = () => {
    // Handle form submission here
    console.log('Issue Type:', issueType);
    console.log('Description:', description);
  };

  return (
    <div>
      <Box position="static" mb={2}>
        <Tabs
          indicatorColor="none"
          textColor="primary"
          alignItems="center"
          sx={{
            '& .MuiTabs-flexContainer': {
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
          value={value}
          onChange={handleChange}
        >
          <Tab className={value === 0 ? classes.balloon : ''} label="Product Bug" />
          <Tab className={value === 1 ? classes.balloon : ''} label="Automation Bug" />
          <Tab className={value === 2 ? classes.balloon : ''} label="System Issue" />
          <Tab className={value === 3 ? classes.balloon : ''} label="To Investigate" />
        </Tabs>
      </Box>
      <Box className={classes.root}>
        <Typography fontWeight="bold" variant="body1" ml={3} mt={3}>Create Jira Ticket</Typography>
        <TabPanel value={value} index={0}>
          <Box className={classes.form}>
            <TextField
              sx={{ width: '40%' }}
              size="small"
              select
              label="Issue Type"
              value={issueType}
              onChange={handleIssueTypeChange}
            >
              <MenuItem value="Product Bug">Product Bug</MenuItem>
              <MenuItem value="Automation Bug">Automation Bug</MenuItem>
              <MenuItem value="System Issue">System Issue</MenuItem>
              <MenuItem value="To Investigate">To Investigate</MenuItem>
            </TextField>
            <TextField
              sx={{ width: '60%' }}
              size="small"
              label="Description"
              rows={1}
              value={description}
              onChange={handleDescriptionChange}
            />
          </Box>
          <Box className={classes.buttonMain}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={classes.submitButton}
            >
              Create Jira
            </Button>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box className={classes.form}>
            {/* Similar form for Automation Bug */}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box className={classes.form}>
            {/* Similar form for System Issue */}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box className={classes.form}>
            {/* Similar form for To Investigate */}
          </Box>
        </TabPanel>
      </Box>
    </div>
  );
};

const TestDetails = () => {
  const classes = useStyles();
  const getChipClass = (chip) => {
    if (chip.label === 3) {
      return classes.chipSuccess;
    }
    if (chip.label === 1) {
      return classes.chipFailure;
    }
    return classes.chipWarning;
  };

  const chipData = [
    { label: '3' },
    { label: '1' },
    { label: '0' },
  ];
  const testData = {
    title: 'Should add product to the cart from products search page',
    time: '9.0s',
    status: 'error',
    details: [
      { status: 'success', text: 'Verify API Create Account (fred, password789)', time: '43s' },
      { status: 'success', text: 'Verify API Create Account (fred, password789)', time: '43s' },
      { status: 'error', text: 'Verify API Create Account (fred, password789)', time: '43s' },
      { status: 'success', text: 'Verify API Create Account (fred, password789)', time: '43s' },
    ],
  };

  return (
    <>
      <Paper className={classes.summaryBox}>
        <Box className={classes.stepHeaderMain}>
          <Box>
            <Typography variant="h6">Adding product to cart</Typography>
          </Box>
          <Box className={classes.stepSummary}>
            <Box className={classes.stepIcons}>
              <Box mx={2} display="flex" alignItems="center">
                <AccessTimeIcon fontSize="small" />
                <Typography variant="body2" color="textSecondary" ml={0.5}>9:25</Typography>
              </Box>
            </Box>
            <Box>
              {chipData.map((chip) => (
                <Chip
                  variant="outlined"
                  key={chip.id}
                  label={chip.label}
                  className={getChipClass(chip)}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Paper>
      <Grid container spacing={2}>
        <Grid item sm={12} lg={6}>
          <Paper className={classes.summaryBox}>
            <TestStep
              title={testData.title}
              time={testData.time}
              status={testData.status}
              details={testData.details}
            />
          </Paper>
        </Grid>
        <Grid item sm={12} lg={6}>
          <Paper className={classes.summaryBox}>
            <MainTabPanel />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default TestDetails;
