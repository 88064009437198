import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import Tooltip from '@mui/material/Tooltip';
import { useParams, useSearchParams } from 'react-router-dom';
import Loader from '../../utils/loader/Loader';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import { TestStatus } from '../../utils/constants';
import commonUtil from '../../utils/common/common';

import {
  ParentBox,
  reportStyles,
} from '../HtmlreportList/style';
import Breadcrumb from '../breadcrumbs/Breadcrumb';
import projectService from '../../services/project.service';
import FullScreenModal from '../reportList/FullScreenModal';

function Projects() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const style = reportStyles();
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [active, setActive] = useState(-1);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTests, setSelectedTests] = useState([]);
  const [userProject, setUserProject] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleClick = (index) => {
    setActive(index === active ? -1 : index);
  };

  const onTestSuitClick = (tests) => {
    setSelectedTests(tests);
    setOpenDrawer(true);
  };
  setSearchParams(searchParams);
  useEffect(() => {
    async function getProjectById() {
      try {
        setLoading(true);
        const res = await projectService.getProjectById(id);
        setUserProject(res);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error.message}`,
        });
      } finally {
        setLoading(false);
      }
    }
    getProjectById();
  }, [id]);
  function calculateTimestamp(val) {
    let month = null;
    let year = null;
    let dayDate = null;
    const repDate = val?.createAt;
    const reportDate = repDate?.indexOf('IST') >= 0
      ? repDate.split(' IST')[0] : repDate;
    if (reportDate) {
      const newReportDate = new Date(reportDate);
      month = commonUtil.months[newReportDate?.getMonth()];
      year = newReportDate?.getFullYear();
      dayDate = `${newReportDate?.getDate() > 9 ? newReportDate?.getDate()
        : 0 + (newReportDate?.getDate())}`;
    }
    return { month, year, dayDate };
  }

  return (
    <>
      <ParentBox>
        <Breadcrumb data={['User Reports']} />
        {loading && <Loader />}
        {userProject?.xmlTestReports?.content?.length ? <Grid item sm={12} xs={12}>
          {userProject?.xmlTestReports.content && userProject?.xmlTestReports.length !== 0 && (
            <List
              sx={{ width: '100%', bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {userProject?.xmlTestReports?.content?.map((val, index) => {
                const { month, year, dayDate } = calculateTimestamp(val);
                let test = val?.testingResults?.suite?.test;
                if (
                  test && !Array.isArray(test)
                ) {
                  test = [test];
                }
                return (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton
                        key={val.id}
                        onClick={() => handleClick(index)}
                      >
                        {active !== index ? (
                          <ChevronRightIcon />
                        ) : (
                          <ExpandMore />
                        )}
                        <ListItemIcon>
                          {val?.testingResults?.failed === 0 && (
                            <CheckIcon sx={{ color: 'green', mr: 2 }} />
                          )}
                          {val?.testingResults?.failed !== 0 && (
                            <ReportProblemIcon sx={{ color: 'red', mr: 2 }} />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={val?.testingResults?.suite?.name}
                        />
                        <ListItemText
                          primary={dayDate + month + year}
                        />
                        <Typography
                          component="div"
                          className={style.reportHeader}
                        >
                          <Typography
                            component="h1"
                            className={style.reportTime}
                          >
                            Suite
                          </Typography>
                          <Tooltip title="Passed">
                            <Button
                              variant="contained"
                              className={style.testButton}
                            >
                              {val?.testingResults?.passed}
                            </Button>
                          </Tooltip>
                          <Tooltip title="Failed">
                            <Button
                              variant="contained"
                              sx={{ backgroundColor: '#f04438' }}
                              className={reportStyles.failedButton}
                            >
                              {val?.testingResults?.failed}
                            </Button>
                          </Tooltip>
                          <Tooltip title="Skipped">
                            <Button
                              variant="contained"
                              sx={{ backgroundColor: '#6c647d' }}
                              className={reportStyles.failedButton}
                            >
                              {val?.testingResults?.skipped}
                            </Button>
                          </Tooltip>
                          <Tooltip title="Retried">
                            <Button
                              variant="contained"
                              sx={{ backgroundColor: '#9ACEEB' }}
                              className={reportStyles.failedButton}
                            >
                              {val?.testingResults?.retried || 0}
                            </Button>
                          </Tooltip>
                          <Button>
                            <Tooltip title="Show all details">
                              <FormatAlignLeftIcon />
                            </Tooltip>
                          </Button>
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                    <Collapse
                      in={active === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {val?.testingResults?.suite?.test.map((item, i) => {
                          const methods = item?.class['test-method'];
                          const passTest = methods?.length ? methods.filter(
                            (a) => a.status === TestStatus.PASS,
                          ) : [];
                          const failedTest = methods?.length ? methods.filter(
                            (a) => a.status === TestStatus.FAIL,
                          ) : [];
                          return (
                            <>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => onTestSuitClick(item?.class['test-method'])}
                              >
                                <ListItemIcon>
                                  <SearchIcon className={style.searchicon} />
                                  {failedTest.length ? (
                                    <ReportProblemIcon
                                      className={style.failIcon}
                                    />
                                  ) : (
                                    <CheckIcon sx={{ color: 'green', mr: 2 }} />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  primary={item?.name}
                                />

                                <Typography
                                  component="div"
                                  className={style.accordianTime}
                                >
                                  <Typography
                                    component="h1"
                                    className={style.accordianHeader}
                                  >
                                    Test
                                  </Typography>
                                  <Tooltip title="Passed">
                                    <Button
                                      variant="contained"
                                      className={style.testlistButton}
                                    >
                                      {passTest.length}
                                    </Button>
                                  </Tooltip>
                                  <Tooltip title="Failed">
                                    <Button
                                      variant="contained"
                                      className={style.failedlistButton}
                                    >
                                      {failedTest.length}
                                    </Button>
                                  </Tooltip>
                                  <Button>
                                    <Tooltip title="Show all details">
                                      <FormatAlignLeftIcon />
                                    </Tooltip>
                                  </Button>
                                </Typography>
                              </ListItemButton>
                              {i !== test?.length - 1 && (
                                <Divider />
                              )}
                            </>
                          );
                        })}
                      </List>
                    </Collapse>
                    {index !== userProject.length - 1 && <Divider />}
                  </>
                );
              })}
            </List>
          )}
        </Grid> : <h4 style={{ padding: '30px' }}> Unable to extract Data</h4>}
        {openDrawer && <FullScreenModal
          tests={selectedTests}
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
        />}
        {snackbar.state && (
          <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
        )}
      </ParentBox>
    </>
  );
}

export default Projects;
