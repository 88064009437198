import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import {
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  Tooltip,
  AppBar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Chip,
  Badge,
  Link,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
// import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Loader from '../../utils/loader/Loader';
import {
  exportToExcel, downloadPdf, resultList, testReportInvite,
} from '../../services/uploadFile.service';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import {
  SortingType,
  TestStatus,
  FileType,
  ReportSchema,
  UserPermission,
  InviteStatus,
} from '../../utils/constants';

import {
  ParentBox,
  reportStyles,
} from './style';
import Breadcrumb from '../breadcrumbs/Breadcrumb';
import Dailog from './Dialog';
import sorting from '../../utils/xml';
import { useCommonContext } from '../header/context';
import { readableTime } from '../../utils';
import { parseDate } from '../dashboard/utils';
import projectService from '../../services/project.service';
import commentService from '../../services/comment.service';
import csvIcon from '../../assets/csv-file.svg';
import pdfIcon from '../../assets/pdf-icon.svg';
import FullScreenModal from './FullScreenModal';
import jenkinsImg from '../../assets/jenkins.png';
import JiraIcon from '../../assets/jira-svgrepo-com.svg';

// eslint-disable-next-line prefer-const
let counter = {
  mount: 0,
  handleChange: 0,
  onTestSuitClick: 0,
};

const tagEnum = {
  PRODUCT_BUG: 'Product Bug',
  AUTOMATION_BUG: 'Automation Bug',
  SYSTEM_ISSUE: 'System Issue',
  TO_INVESTIGATE: 'To Investigate',
};

const backgroundEnum = {
  PRODUCT_BUG: '#ec3900',
  AUTOMATION_BUG: '#ffb743',
  SYSTEM_ISSUE: '#0274d1',
  TO_INVESTIGATE: 'green',
};

function ReportList() {
  const [dataList, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const style = reportStyles();
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [active, setActive] = useState(-1);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTests, setSelectedTests] = useState({
    id: null, tests: [], testName: '', project: '',
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [userList, setUserList] = React.useState([]);
  const [tId, setTId] = React.useState('');
  const [search, setSearch] = useState('');
  const [tags, setTags] = useState({});
  const [openInviteProjectModal, setOpenInviteProjectModal] = React.useState(false);
  const [order, setOrder] = useState('ASC');
  const {
    user,
    selectedfilter,
    setSelectedFilter,
    notification,
    reportType,
    setReportType,
  } = useCommonContext();

  const handleClick = (index) => {
    setActive(index === active ? -1 : index);
  };

  const onTestSuitClick = (tests, id, testName, project) => {
    counter.onTestSuitClick += 1;
    setSelectedTests({
      tests, id, testName, project,
    });
    setOpenDrawer(true);
  };

  const onInvite = async (val) => {
    setTId(val?.id);
    try {
      const users = await projectService.getMemberList(val?.companyId, val?.projectId);
      setUserList(users);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    }
    setOpenInviteProjectModal(true);
  };
  useEffect(() => {
    counter.mount += 1;
  }, []);

  const xmlReportPageByCurrentInvitedUser = async () => {
    try {
      const res = await testReportInvite(page, rowsPerPage);
      if (res) {
        setTotalElements(res.totalElements || 0);
        setData(res.content);
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    }
  };
  const xmlResultList = async () => {
    try {
      setLoading(true);
      const res = await resultList(page, rowsPerPage, search);
      if (res) {
        setTotalElements(res.totalElements || 0);
        setLoading(false);
        setData(res.content);
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setReportType(FileType.XML);
    if (user?.role?.userPermissionsList.includes(UserPermission.GUEST_USER)) {
      xmlReportPageByCurrentInvitedUser(page, rowsPerPage);
    } else if (user?.role?.userPermissionsList) {
      xmlResultList(page, rowsPerPage);
    }
  }, [user?.role?.roleId, page, rowsPerPage, JSON.stringify(notification)]);

  const myStyle = {
    boxShadow: 'none',
    position: 'relative',
    background: '#fff',
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    zIndex: 1,
    padding: '5px',
    marginBottom: '12px',
    border: '1px solid #3f639859',
    borderRadius: '4px',
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newValue = event.target.value;
    if (newValue === totalElements) {
      setRowsPerPage(totalElements);
      setPage(0);
    } else {
      setRowsPerPage(parseInt(newValue, 10));
      setPage(0);
    }
  };

  const handleSort = (type) => {
    let sData;
    let sOrder;
    if (order === 'ASC') {
      sData = sorting(dataList, type, order);
      sOrder = 'DSC';
    }
    if (order === 'DSC') {
      sData = sorting(dataList, type, order);
      sOrder = 'ASC';
    }
    setData(sData);
    setOrder(sOrder);
  };
  const reportDownload = async (id, name) => {
    try {
      setLoading(true);
      const res = await downloadPdf({
        testReportId: id,
        fileType: FileType.XML,
      });
      if (res) {
        const url = new Blob([res], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(url);
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const getAllTags = (id, filter, index) => {
    // setLoading(true);
    commentService.getTagNamebyReportId(id).then((res) => {
      if (Object.keys(res).length) {
        setTags(res);
      } else {
        console.error('Invalid response format:', res);
      }
      if (index !== null) {
        setActive(index === active && selectedfilter
          === filter ? -1 : index);
      }
      setLoading(false);
    }).catch((error) => {
      console.error('Error loading comments:', error);
      setLoading(false);
    });
  };

  const reportExportToExecl = async (id, name) => {
    try {
      setLoading(true);
      const res = await exportToExcel({
        testReportId: id,
        fileType: FileType.XML,
      });
      if (res) {
        const url = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(url);
        link.setAttribute('exportToExcel', name);
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    getAllTags(selectedTests.id, TestStatus.FAIL, null);
    setOpenDrawer(false);
  };

  const hasBuildVersion = dataList?.some((data) => data.jenkinsBuildVersion !== null);

  const renderData = ({ testData, id, project }) => {
    let listData = testData;
    if (testData?.test) {
      if (!Array.isArray(testData?.test)) {
        listData = [testData?.test];
      } else {
        listData = testData?.test;
      }
    }
    return listData?.map((details, i) => {
      const originalItem = testData?.test ? details : details?.test;

      const itemArray = Array.isArray(originalItem) ? originalItem : [originalItem];

      return itemArray?.map((item) => {
        const methods = item?.class?.['test-method'];
        const failedTest = methods?.length ? methods.filter(
          (a) => a.status === TestStatus.FAIL,
        ) : [];
        const tagVal = selectedfilter === TestStatus.FAIL ? (tags[item?.name] || { tagName: 'TO_INVESTIGATE' }) : '';
        if (selectedfilter === TestStatus.PASS && failedTest.length) {
          return null;
        }
        if (selectedfilter === TestStatus.FAIL && failedTest.length === 0) {
          return null;
        }

        return (
          <>
            <ListItemButton
              sx={{ pl: 2 }}
              onClick={() => onTestSuitClick(item?.class['test-method'], id, item?.name, project)}
              key={item?.name}
            >
              <ListItemIcon>
                <SearchIcon className={style.searchicon} />
                {failedTest.length ? (
                  <ReportProblemIcon
                    className={style.failIcon}
                  />
                ) : (
                  <CheckIcon sx={{ color: 'green', mr: 2 }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={item?.name}
                secondary={item?.class?.name}
              />
              <Typography
                component="div"
                className={style.accordianTime}
              >
                <Typography
                  component="h1"
                  className={style.accordianHeader}
                >
                  <div>
                    {TestStatus.FAIL && !!tagVal && (
                      <>
                        <Chip
                          label={tagEnum[tagVal?.tagName]}
                          style={{
                            margin: '5px',
                            height: '38px',
                            textTransform: 'capitalize',
                            borderRadius: '9px',
                            fontSize: '10px',
                            fontWeight: 600,
                            background: backgroundEnum[tagVal?.tagName],
                            color: '#fff',
                          }}
                        />
                        {tagVal.jiraTicketId && <Chip
                          label={tagVal.jiraTicketId}
                          component={Link}
                          href={tagVal?.ticketReturnUrl}
                          target="_blank"
                          icon={<img width="20px" height="20px" src={JiraIcon} alt="jira" />}
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            margin: '5px',
                            width: '100px',
                            height: '40px',
                            fontFamily: 'Open Sans',
                            textTransform: 'capitalize',
                            borderRadius: '9px',
                            fontSize: '10px',
                            fontWeight: 600,
                            background: '#f5eced',
                            color: '#000',
                          }}
                        />}
                      </>
                    )}
                  </div>
                </Typography>
                {
                  failedTest.length ? <Tooltip title="Failed">
                    <Button
                      variant="contained"
                      className={style.failedlistButton}
                    >
                      {1}
                    </Button>
                  </Tooltip> : <Tooltip title="Passed">
                    <Button
                      variant="contained"
                      className={style.testlistButton}
                    >
                      {1}
                    </Button>
                  </Tooltip>
                }
              </Typography>
            </ListItemButton>
            {i !== listData?.length - 1 && (
              <Divider />
            )}
          </>
        );
      });
    });
  };

  return (
    <>
      <ParentBox>
        <Breadcrumb data={[ReportSchema[reportType]?.name]} />
        <AppBar
          style={myStyle}
        >
          <Box
            component="form"
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'end',
              width: 265,
              boxShadow: 'none',
              border: '1px solid #3f639859',
            }}
            elevation="0"
          >
            <Typography>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="XML Report Search"
                inputProps={{ 'aria-label': 'XML Report Search' }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <IconButton
                type="button"
                sx={{
                  p: '10px',
                  fontSize: '13px',
                  color: 'white',
                  border: '1px solid white',
                  borderRadius: '5px',
                  background: '#466197',
                  width: '80px',
                  '&:hover': {
                    background: '#2e4166',
                  },
                }}
                onClick={() => xmlResultList()}
                aria-label="search"
              >
                Search
              </IconButton>
            </Typography>
          </Box>
          <Box>
            <Button sx={{ mr: 2 }} size="large" variant="text" onClick={() => setSelectedFilter(null)}>
              <RefreshIcon />
              Reset
            </Button>
          </Box>
        </AppBar>
        {loading && <Loader />}
        {
          dataList?.length === 0 ? '' : <Grid item sm={12} xs={12}>
            {loading ? <Loader /> : <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{ width: '100%', bgcolor: '#3f6398' }}>
                    <TableCell />
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleSort(SortingType.REPORTNAME)}
                    >
                      Report Name
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleSort(SortingType.DATE)}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                    >
                      Execution Time
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                      }}
                    >
                      Company
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                      }}
                    >
                      Project
                    </TableCell>
                    {hasBuildVersion && <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        textAlign: 'center',
                      }}
                    >
                      Build Version
                    </TableCell>}
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        textAlign: 'center',
                      }}
                      onClick={() => handleSort(SortingType.PASSED)}
                    >
                      Result
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: '600',
                        color: 'background.paper',
                        fontSize: '16px',
                        textAlign: 'center',
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataList?.length && dataList?.map((data, index) => (
                    <>
                      <TableRow key={data.id} sx={{ border: '1px solid #e0e0e0', 'td, th': { border: 0 } }}>
                        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton onClick={() => {
                            handleClick(index);
                            setSelectedFilter(TestStatus.ALL);
                            getAllTags(data.id, TestStatus.ALL, index);
                          }}
                          >
                            {active !== index ? (
                              <ChevronRightIcon />
                            ) : (
                              <ExpandMore />
                            )}
                          </IconButton>
                          <SummarizeIcon sx={{ color: '#3f6398', mr: 2 }} />
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '14px',
                          }}
                        >
                          {data?.testingResults['testng-results']?.suite?.name || 'Smoke test'}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '14px',
                          }}
                        >
                          {parseDate(data?.testingResults['testng-results']?.suite['started-at'] || data?.testingResults['testng-results']?.suite[0]['started-at'])}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '14px',
                          }}
                        >
                          {readableTime(Math.round((data?.testingResults['testng-results']?.suite['duration-ms'] || 0) / 1000))}
                        </TableCell>
                        <Tooltip title="Company">
                          <TableCell
                            sx={{
                              fontSize: '14px',
                              cursor: 'pointer',
                            }}
                          >
                            {data?.companyName}
                          </TableCell>
                        </Tooltip>
                        <Tooltip title="Project">
                          <TableCell
                            sx={{
                              fontSize: '14px',
                              cursor: 'pointer',
                            }}
                          >
                            {data?.projectName}
                          </TableCell>
                        </Tooltip>
                        {hasBuildVersion && <TableCell
                          align="center"
                          sx={{
                            justifyContent: 'center',
                            fontSize: '14px',
                            cursor: 'pointer',
                          }}
                        >
                          <Badge max={Number.MAX_VALUE} badgeContent={data?.jenkinsBuildVersion === 'null' ? null : data?.jenkinsBuildVersion} color="primary" sx={{ marginRight: '0px' }}>
                            {data?.jenkinsBuildVersion !== 'null' && <img src={jenkinsImg} alt="build-version" height={35} />}
                          </Badge>
                        </TableCell>}
                        <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center', padding: '32px' }}>
                          <Tooltip title="Passed">
                            <Grid
                              sx={{
                                backgroundColor: '#fff',
                                border: '11px solid #10b981',
                                borderRadius: '100px',
                                height: '38px',
                                width: '38px',
                                position: 'relative',
                                cursor: 'pointer',
                                ml: 2,
                              }}
                              onClick={() => {
                                setSelectedFilter(TestStatus.PASS);
                                getAllTags(data.id, TestStatus.PASS, index);
                              }}
                            >
                              <Grid
                                sx={{
                                  position: 'absolute',
                                  top: '-21px',
                                  right: '-11px',
                                  background: '#fff',
                                  minWidth: '25px',
                                  borderRadius: '9px',
                                  border: '1px solid #10b981',
                                  fontWeight: 600,
                                  padding: '1px',
                                }}
                              >
                                {data?.testingResults['testng-results']?.passed}
                              </Grid>
                            </Grid>
                          </Tooltip>
                          <Tooltip title="Failed">
                            <Grid
                              sx={{
                                backgroundColor: '#fff',
                                border: '11px solid #f04438',
                                borderRadius: '100px',
                                height: '38px',
                                width: '38px',
                                position: 'relative',
                                cursor: 'pointer',
                                ml: 2,
                              }}
                              onClick={() => {
                                setSelectedFilter(TestStatus.FAIL);
                                getAllTags(data.id, TestStatus.FAIL, index);
                              }}
                            >
                              <Grid
                                sx={{
                                  position: 'absolute',
                                  top: '-21px',
                                  right: '-11px',
                                  background: '#fff',
                                  minWidth: '25px',
                                  borderRadius: '9px',
                                  border: '1px solid #e93e3d',
                                  fontWeight: 600,
                                }}
                              >
                                {data?.testingResults['testng-results']?.failed}
                              </Grid>
                            </Grid>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Download Pdf">
                            <IconButton
                              onClick={(e) => {
                                reportDownload(data?.id, data?.testingResults['testng-results']?.suite?.name);
                                e.stopPropagation();
                              }}
                              size="small"
                            >
                              {/* <FileDownloadIcon /> */}
                              <img style={{ width: '25px' }} src={pdfIcon} alt="pdf-icon" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Download CSV">
                            <IconButton
                              onClick={(e) => {
                                reportExportToExecl(data?.id, data?.testingResults['testng-results']?.suite?.name);
                                e.stopPropagation();
                              }}
                              size="small"
                            >
                              <img style={{ width: '25px' }} src={csvIcon} alt="csv-icon" />
                            </IconButton>
                          </Tooltip>
                          {
                            user?.role?.userPermissionsList.includes(UserPermission.INVITE_REPORT)
                            && <Button
                              onClick={(e) => {
                                onInvite(data);
                                e.stopPropagation();
                              }}
                              style={{
                                cursor: 'pointer',
                                marginLeft: '4px',
                              }}
                              color="info"
                              variant="outlined"
                              disabled={data?.companyStatus === InviteStatus.BLOCKED
                                || data?.projectStatus === InviteStatus.BLOCKED}
                            >
                              Invite Report
                            </Button>
                          }

                        </TableCell>
                      </TableRow>
                      {active === index ? (
                        <TableRow>
                          <TableCell colSpan="10">
                            <Collapse
                              in={active === index}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {renderData({ testData: data?.testingResults['testng-results']?.suite, id: data?.id, project: data?.projectName })}
                              </List>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>}
          </Grid>
        }
        {openDrawer && <FullScreenModal
          tests={selectedTests.tests}
          id={selectedTests.id || ''}
          selectedTestName={selectedTests?.testName}
          project={selectedTests?.project}
          tagVal={tags[selectedTests?.testName]}
          open={openDrawer}
          handleClose={handleClose}
          setSnackbarData={(data) => setSnackbar(data)}
        />}
        {snackbar.state && (
          <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
        )}
        {dataList?.length ? <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, totalElements]}
          count={totalElements}
          component="div"
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> : null}
        {
          dataList?.length === 0 ? <Typography variant="h5" align="center"> Data Not Found </Typography> : ''
        }
        {
          dataList?.length ? dataList?.map((a) => <Dailog
            openDailog={dataList && openInviteProjectModal}
            tId={tId}
            userList={userList}
            cid={a.companyId}
            pid={a.projectId}
            onOpen={(value) => setOpenInviteProjectModal(value)}
            setSnackbarData={(data) => setSnackbar(data)}
          />)
            : ''
        }
      </ParentBox>
    </>
  );
}

export default ReportList;
