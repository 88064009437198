import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../breadcrumbs/Breadcrumb';
import {
  ParentBox,
  ChildBox,
  CreateGroupStyle,
  ButtonStyle,
  Item,
} from './style';
import groupService from '../../services/group.service';
import route from '../../utils/route';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import { GroupStatus } from '../../utils/constants';

const CreateGroup = () => {
  const styles = CreateGroupStyle();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [groupdetail, setGroupDetail] = useState({
    groupName: '',
    groupStatus: GroupStatus.STATUS,
    companyId: id,
  });
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });

  const handleClick = async () => {
    try {
      setLoading(true);
      await groupService.addGroup(groupdetail);
      navigate(`/${route.GROUP_LIST}`);
    } catch (err) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err.data.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      (
      <Box
        sx={{
          marginTop: '100px',
          marginLeft: 4,
          marginRight: 4,
        }}
      >
        <ParentBox>
          <Grid container rowSpacing={3}>
            <Grid
              item
              xs={12}
            >
              <Breadcrumb data={['Groups']} />
              <ChildBox>
                <item>
                  <Typography className={styles.itemHeadingBox} component="div">
                    <ApartmentIcon />
                    <Typography className={styles.itemHeading} variant="h6" component="div">
                      Create Group
                    </Typography>
                    <InfoIcon />
                  </Typography>
                </item>
              </ChildBox>
            </Grid>
          </Grid>
        </ParentBox>
        <Box>
          <Grid container rowSpacing={3} style={{ marginTop: '9px' }}>
            <Grid item xs={7}>
              <TextField
                label="Group Name *"
                name="groupName"
                value={groupdetail.groupName}
                onChange={(e) => setGroupDetail({
                  ...groupdetail,
                  groupName: e.target.value,
                })}
                placeholder="Group Detail"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={3} sx={{ mt: 1 }}>
            <Grid item xs={4} style={{ cursor: 'pointer' }}>
              {loading && <CircularProgress style={{ marginLeft: '75px' }} />}
              {
                !loading && <Item><ButtonStyle variant="contained" onClick={handleClick} style={{ width: '155px' }}>SUBMIT</ButtonStyle></Item>
              }
            </Grid>
          </Grid>
        </Box>
        {
          snackbar.state
          && (
            <NavigateSnackbar
              snackbarObj={snackbar}
              setSnackbar={setSnackbar}
            />
          )
        }
      </Box>
      )
    </>
  );
};

export default CreateGroup;
