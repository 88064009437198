import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AccordionContainer from './AccordionContainer';
import CommentWrapper from '../commentWrapper';
import { HtmlStatus } from '../../utils/constants';
import { TestsPropType } from '../../Types/test';

const HtmlDescription = ({ tests, id, name }) => {
  const [expanded, setExpanded] = React.useState([true]);
  const handleChange = (panel) => {
    setExpanded((pre) => {
      const newState = [...pre];
      newState[panel] = !newState[panel];
      return newState;
    });
  };

  return (
    <div>
      {tests.length && tests?.map((test, index) => (
        <Accordion expanded={expanded[index]} onChange={() => handleChange(index)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
          >
            {test.status === HtmlStatus.PASSED && <CheckIcon sx={{ color: 'green', mr: 2 }} />}
            {test.status === HtmlStatus.FAILED && <ReportProblemIcon sx={{ color: 'red', mr: 2 }} />}
            {test.status === HtmlStatus.PASSED && <Typography sx={{ width: '80%', fontSize: '14px' }}>
              Passed Description
            </Typography>}
            {test.status === HtmlStatus.FAILED && <Typography sx={{ width: '80%', fontSize: '14px' }}>
              Failed Description
            </Typography>}
          </AccordionSummary>
          <AccordionDetails>
            <AccordionContainer test={test} />
            <CommentWrapper testName={name} testReportId={id} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

HtmlDescription.propTypes = {
  tests: TestsPropType,
  id: PropTypes.string,
  name: PropTypes.string,
};
HtmlDescription.defaultProps = {
  tests: [],
  id: '',
  name: '',
};
export default HtmlDescription;
