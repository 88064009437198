import React from 'react';
import {
  Box,
} from '@mui/material';
import expiredImg from '../../assets/linkExpired.jpg';

const imgStyle = {
  height: '100%',
  width: '50%',
};

const textStyle = {
  position: 'absolute',
  left: '65%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
};

function InviteExpired() {
  return (
    <Box>
      <div>
        <img src={expiredImg} style={imgStyle} alt="expired" />
        <div style={textStyle}>
          <div style={{ fontSize: 50 }}>
            Oops, this link is expired
          </div>
          <div>
            This URL is not valid anymore
          </div>
        </div>
      </div>
    </Box>
  );
}

export default InviteExpired;
