/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import {
  Grid,
  Chip,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Tooltip,
  ListItemText,
  List,
  ListItem,
  ListItemIcon,
  CardContent,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckIcon from '@mui/icons-material/Check';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { ExpandMore } from '@mui/icons-material';
import CommentWrapper from '../../commentWrapper';
import commentService from '../../../services/comment.service';
import { CypressTestStatus, DashboardEnum } from '../../../utils/constants';
import DonutChart from '../../chart/DonutChart';
import { useCommonContext } from '../../header/context';
import JiraWarpper from '../../jiraWrapper';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const tagEnum = {
  PRODUCT_BUG: 'Product Bug',
  AUTOMATION_BUG: 'Automation Bug',
  SYSTEM_ISSUE: 'System Issue',
  TO_INVESTIGATE: 'To Investigate',
};

const backgroundEnum = {
  PRODUCT_BUG: '#ec3900',
  AUTOMATION_BUG: '#ffb743',
  SYSTEM_ISSUE: '#0274d1',
  TO_INVESTIGATE: 'green',
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
}));

const CypressDrawer = ({
  report,
  handleClose,
  open,
  setSnackbarData,
  guestUser,
  reportId,
  project,
}) => {
  const {
    setSelectedFilter, selectedfilter,
  } = useCommonContext();
  console.log('report', report);
  console.log('selectedfilter', selectedfilter);
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedChip, setSelectedChip] = useState('TO_INVESTIGATE');
  const [jiraTickets, setJiraTickets] = useState([]);
  const [tags, setTags] = useState({});
  const [selectedTests, setSelectedTests] = useState({
    test: '', testName: '', testState: '',
  });

  const createComment = async (chip) => {
    try {
      setLoading(true);
      const commentData = {
        testName: selectedTests.testName,
        testReportId: reportId,
        comment: commentText,
        tagName: chip,
      };
      await commentService.create(commentData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChipClick = (chipName) => {
    let chip = null;
    if (selectedChip !== chipName) {
      setSelectedChip(chipName);
      chip = chipName;
    } else {
      setSelectedChip(null);
      chip = null;
    }
    createComment(chip);
  };

  const RenderTime = ({ data }) => {
    let result = `${data} ms`;

    if (data >= 1000 && data < 60000) {
      result = `${(data / 1000).toFixed(1)} s`;
    }
    if (data >= 60000) {
      result = `${(data / 60000).toFixed(1)} mins`;
    }
    return result;
  };

  const getFirstTest = (suites) => {
    let firstTest = null;
    suites.some((suite) => {
      if (suite.tests && suite.tests.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        firstTest = suite.tests[0];
        return true;
      }
      if (suite.suites && suite.suites.length > 0) {
        firstTest = getFirstTest(suite.suites);
        return firstTest !== null;
      }
      return false;
    });
    return firstTest;
  };
  const getAllTags = (id) => {
    setLoading(true);
    commentService.getTagNamebyReportId(id).then((res) => {
      if (Object.keys(res).length) {
        setTags(res);
      } else {
        console.error('Invalid response format:', res);
      }
      setLoading(false);
    }).catch((error) => {
      console.error('Error loading comments:', error);
      setLoading(false);
    });
  };
  useEffect(() => {
    let firstTest = null;

    report?.results?.some((item) => {
      firstTest = getFirstTest([item]);
      return firstTest !== null;
    });
    setSelectedTests({
      ...selectedTests,
      test: firstTest,
      testName: firstTest.title,
      testState: firstTest.state,
    });
  }, []);

  useEffect(() => {
    if (reportId) {
      getAllTags(reportId);
    }
  }, [reportId, selectedChip]);

  const renderTests = ({ tests }) => <>
    {tests?.map((test) => {
      const attachments = JSON.parse(test.context);
      const images = attachments?.filter((media) => media.value.startsWith('data:image/'));
      const videos = attachments?.filter((media) => media.value.endsWith('.mp4'));

      return (
        <Accordion key={test?.uuid}>
          <AccordionSummary sx={{ borderLeft: `3px solid ${test.pass ? 'green' : 'red'}` }}>
            <List sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <ListItem>
                <ListItemIcon>
                  {test.pass && <CheckCircleIcon color="success" />}
                  {test.fail && <CancelIcon color="error" />}
                </ListItemIcon>
                <ListItemText primary={test?.title} />
              </ListItem>
              <ListItem sx={{ justifyContent: 'flex-end' }}>
                {test.fail ? (
                  <Chip
                    onClick={() => setSelectedTests({
                      ...selectedTests,
                      test,
                      testName: test?.title,
                      testState: test?.state,
                    })}
                    label={tagEnum[tags?.[test?.title] || 'TO_INVESTIGATE']}
                    style={{
                      margin: '5px',
                      height: '38px',
                      textTransform: 'capitalize',
                      borderRadius: '9px',
                      fontSize: '10px',
                      fontWeight: 600,
                      background: backgroundEnum[tags?.[test?.title] || 'TO_INVESTIGATE'],
                      color: '#fff',
                    }}
                  />
                ) : (
                  <Button
                    variant={selectedTests.testName === test.title ? 'contained' : 'outlined'}
                    onClick={() => setSelectedTests({
                      ...selectedTests,
                      test,
                      testName: test?.title,
                      testState: test?.state,
                    })}
                  >
                    {selectedTests.testName === test?.title ? 'Active' : 'Select'}
                  </Button>
                )}
              </ListItem>
              <ListItem
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '300px',
                }}
              >
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <ChatBubbleOutlineOutlinedIcon />
                </ListItemIcon>
                <RenderTime data={test?.duration} />
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <TimerOutlinedIcon />
                </ListItemIcon>
              </ListItem>
            </List>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, borderLeft: `3px solid ${test.pass ? 'green' : 'red'}` }}>
            <Box>
              <Paper>
                <CardContent>
                  <Typography
                    sx={{
                      border: '1px solid #f2f2f2',
                      p: 2,
                      backgroundColor: '#f2f2f2',
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5,
                    }}
                    variant="body1"
                  >
                    {test?.code}
                  </Typography>
                  <Box
                    sx={{
                      p: 2,
                      border: '1px solid #f2f2f2',
                      borderBottomLeftRadius: 5,
                      borderBottomRightRadius: 5,
                    }}
                  >
                    <Typography variant="h6">Additional Test Attachment</Typography>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                      <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                        {images?.map((item) => (
                          <Box sx={{ width: '45%', marginTop: 1, padding: 2 }}>
                            <a href={`${item.value}`} download={`${item?.value}`} style={{ textDecoration: 'none', color: '#333' }}>
                              <Typography variant="subtitle1">{item?.title}</Typography>
                              <img src={item?.value} alt={item?.title} style={{ width: '100%' }} />
                            </a>
                          </Box>
                        ))}
                      </Box>
                      <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                        {videos?.map((item) => (
                          <Box sx={{ width: '45%', marginTop: 1, padding: 2 }}>
                            <Typography variant="subtitle1">{item?.title}</Typography>
                            <video width="100%" controls>
                              <source src={`${process.env.REACT_APP_BACKEND_BASE_URL}/user/getImage/SCREENSHOT/${item.value.split('videos/')[1]}`} />
                            </video>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Paper>
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    })}
  </>;

  const suiteSummary = ({
    duration, tests, passed, failures,
  }) => <List sx={{
    display: 'flex', flexDirection: 'row', padding: 0, margin: 0,
  }}
  >
    {!!duration && (
      <ListItem sx={{ width: 'auto' }} title="Duration">
        <TimerOutlinedIcon fontSize="small" />
        <ListItemText>
          <Typography>
            <RenderTime data={duration} />
          </Typography>
        </ListItemText>
      </ListItem>
    )}
    {!!tests && (
      <ListItem sx={{ width: 'auto' }} title="Tests">
        <AssignmentIcon fontSize="small" />
        <ListItemText>
          <Typography>{tests}</Typography>
        </ListItemText>
      </ListItem>
    )}
    {!!passed && (
      <ListItem sx={{ width: 'auto', color: 'success.main' }} title="Passed">
        <CheckIcon fontSize="small" />
        <ListItemText>
          <Typography>{passed}</Typography>
        </ListItemText>
      </ListItem>
    )}
    {!!failures && (
      <ListItem sx={{ width: 'auto', color: 'error.main' }} title="Failures">
        <CheckCircleIcon fontSize="small" />
        <ListItemText>
          <Typography>{failures}</Typography>
        </ListItemText>
      </ListItem>
    )}
  </List>;

  const renderSuites = ({ suites, index }) => <>
    {suites?.map((suite) => (
      <Accordion defaultExpanded={index === 0} key={suite?.uuid} sx={{ mt: 2 }}>
        <AccordionSummary
          sx={{
            alignItems: 'baseline',
            justifyContent: 'space-between',
            '.MuiAccordionSummary-content': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
            mt: 2,
          }}
          expandIcon={<ExpandMore />}
        >
          <List sx={{
            width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column',
          }}
          >
            <ListItemText primary={suite?.title} secondary={suite?.file} />
            {suiteSummary({
              duration: suite?.duration,
              tests: suite?.tests?.length,
              passed: suite?.passes?.length,
              failures: suite?.failures?.length,
            })}
          </List>
          {!!(suite.passes.length || suite.failures.length) && !loading && (
            <DonutChart
              donutChartSuitesData={{
                passed: suite?.passes?.length,
                failures: suite?.failures?.length,
              }}
            />
          )}
        </AccordionSummary>
        <AccordionDetails sx={{ p: 2 }}>
          {suite.suites.length > 0 && renderSuites({ suites: suite.suites })}
          {suite.tests.length > 0 && renderTests({ tests: suite.tests })}
        </AccordionDetails>
      </Accordion>
    ))}
  </>;

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{
          position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
        }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" fontWeight={600} component="div">
              Cypress-Framework-main
            </Typography>
          </Toolbar>
          <Toolbar>
            <Tooltip title="Duration">
              <Toolbar>
                <TimerOutlinedIcon />
                <RenderTime data={report?.stats?.duration} />
              </Toolbar>
            </Tooltip>
            <Tooltip title="Suites">
              <Toolbar>
                <FolderCopyIcon />
                {report?.stats?.suites}
              </Toolbar>
            </Tooltip>
            <Tooltip title="Tests">
              <Toolbar>
                <DescriptionIcon />
                {report?.stats?.tests}
              </Toolbar>
            </Tooltip>
            <Tooltip title="Passed">
              <Toolbar>
                <CheckCircleIcon
                  onClick={() => setSelectedFilter(CypressTestStatus.PASSES)}
                  color="success"
                  sx={{
                    cursor: 'pointer',
                    transition: 'transform 0.3s, color 0.3s',
                    '&:hover': {
                      transform: 'scale(1.2)',
                      color: 'success.dark',
                    },
                  }}
                />
                {report?.stats?.passes}
              </Toolbar>
            </Tooltip>
            <Tooltip title="Failed">
              <Toolbar>
                <CheckCircleIcon
                  onClick={() => setSelectedFilter(CypressTestStatus.FAILURES)}
                  color="error"
                  sx={{
                    cursor: 'pointer',
                    transition: 'transform 0.3s, color 0.3s',
                    '&:hover': {
                      transform: 'scale(1.2)',
                      color: 'error.dark',
                    },
                  }}
                />
                {report?.stats?.failures}
              </Toolbar>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box sx={{ flexGrow: 1, m: 4 }}>
          {report.results.length === 0
          || (selectedfilter !== CypressTestStatus.ALL && !report.stats[selectedfilter]) ? (
            <p style={{ marginLeft: '6%' }}>No data found</p>
            ) : <Grid container spacing={4}>
              <Grid item xs={6}>
                {report?.results.map((item, index) => {
                  const { suites } = item;
                  console.log(suites);
                  return renderSuites({ suites, index });
                })}
              </Grid>
              <Grid item xs={6}>
                { selectedTests?.testState === CypressTestStatus.FAILED && (
                  <>
                    <Item>
                      <Typography
                        variant="subtitle1"
                        fontSize="15px"
                        fontWeight="bold"
                        fontFamily="Open Sans"
                        textAlign="left"
                        color="#323232"
                        p={1.3}
                      >
                        Select Defect Manually
                      </Typography>
                      <Grid display="flex" flexDirection="row" p={1}>
                        <Chip
                          label="Product Bug"
                          disabled={guestUser}
                          onClick={() => handleChipClick('PRODUCT_BUG')}
                          style={{
                            margin: '5px',
                            width: '140px',
                            height: '40px',
                            fontFamily: 'Open Sans',
                            textTransform: 'capitalize',
                            borderRadius: '9px',
                            fontSize: '10px',
                            fontWeight: 600,
                            background: `${selectedChip === 'PRODUCT_BUG' ? '#ec3900' : '#f5eced'}`,
                            color: `${selectedChip === 'PRODUCT_BUG' ? '#fff' : '#000'}`,
                          }}
                        />
                        <Chip
                          label="Automation Bug"
                          disabled={guestUser}
                          onClick={() => handleChipClick('AUTOMATION_BUG')}
                          style={{
                            margin: '5px',
                            width: '140px',
                            height: '40px',
                            fontFamily: 'Open Sans',
                            textTransform: 'capitalize',
                            borderRadius: '9px',
                            fontSize: '10px',
                            fontWeight: 600,
                            background: `${selectedChip === 'AUTOMATION_BUG' ? '#ffb743' : '#f5eced'}`,
                            color: `${selectedChip === 'AUTOMATION_BUG' ? '#fff' : '#000'}`,
                          }}
                        />
                        <Chip
                          label="System Issue"
                          disabled={guestUser}
                          onClick={() => handleChipClick('SYSTEM_ISSUE')}
                          style={{
                            margin: '5px',
                            width: '140px',
                            height: '40px',
                            fontFamily: 'Open Sans',
                            textTransform: 'capitalize',
                            borderRadius: '9px',
                            fontSize: '10px',
                            fontWeight: 600,
                            background: `${selectedChip === 'SYSTEM_ISSUE' ? '#0274d1' : '#f5eced'}`,
                            color: `${selectedChip === 'SYSTEM_ISSUE' ? '#fff' : '#000'}`,
                          }}
                        />
                        <Chip
                          label="To Investigate"
                          disabled={guestUser}
                          onClick={() => handleChipClick('TO_INVESTIGATE')}
                          style={{
                            margin: '5px',
                            width: '140px',
                            height: '40px',
                            fontFamily: 'Open Sans',
                            textTransform: 'capitalize',
                            borderRadius: '9px',
                            fontSize: '10px',
                            fontWeight: 600,
                            background: `${selectedChip === 'TO_INVESTIGATE' ? 'green' : '#f5eced'}`,
                            color: `${selectedChip === 'TO_INVESTIGATE' ? '#fff' : '#000'}`,
                          }}
                        />
                      </Grid>
                    </Item>
                    {selectedTests.test && <Item>
                      <JiraWarpper
                        project={project}
                        test={selectedTests.test}
                        id={reportId}
                        selectedTestName={selectedTests.testName}
                        setSnackbarData={setSnackbarData}
                        jiraTickets={jiraTickets}
                        setJiraTickets={setJiraTickets}
                        reportType={DashboardEnum.CYPRESS}
                      />
                    </Item>}
                  </>
                )}
                <Item sx={{ mt: 2 }}>
                  <Typography
                    variant="subtitle1"
                    fontSize="15px"
                    fontWeight="bold"
                    fontFamily="Open Sans"
                    textAlign="left"
                    color="#323232"
                    px={1.3}
                    pt={1.3}
                  >
                    Post Comment
                  </Typography>
                  <CommentWrapper
                    selectedTestName={selectedTests.testName}
                    testReportId={reportId}
                    setComments={setComments}
                    comments={comments}
                    loading={loading}
                    setLoading={setLoading}
                    setCommentText={setCommentText}
                    commentText={commentText}
                    selectedChip={selectedChip}
                    guestUser={guestUser}
                    setSelectedChip={setSelectedChip}
                    jiraTickets={jiraTickets?.content}
                    setSnackbarData={setSnackbarData}
                  />
                </Item>
              </Grid>
            </Grid>}
        </Box>
      </Dialog>
    </>
  );
};

CypressDrawer.propTypes = {
  reportId: PropTypes.number,
  report: PropTypes.arrayOf(),
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  setSnackbarData: PropTypes.func,
  guestUser: PropTypes.bool,
  project: PropTypes.string,
};

CypressDrawer.defaultProps = {
  report: [],
  handleClose: () => { },
  open: false,
  setSnackbarData: PropTypes.func,
  guestUser: false,
  reportId: null,
  project: '',
};

export default CypressDrawer;
