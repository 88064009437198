import React from 'react';
import { CircularProgress } from '@mui/material';
import Boxstyle from './style';

function Loader() {
  return (
    <Boxstyle>
      <CircularProgress />
    </Boxstyle>
  );
}

export default Loader;
