import * as React from 'react';
import {
  Button,
  DialogTitle,
  Autocomplete,
  Checkbox,
  DialogActions,
  Divider,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { IconButtonStyle } from '../Modal/style';
import {
  Form, icon,
  checkedIcon,
} from '../projectlist/style';
import invitereportService from '../../services/invitereport.service';
import { FileType } from '../../utils/constants';

export default function ScrollDialog(
  {
    openDailog,
    tId,
    userList,
    onOpen,
    setSnackbarData,
    cid,
    pid,
    isReport,
  },
) {
  const [selectedxmlUsers, setSelectedXmlUsers] = React.useState([]);
  const [userEmail, setUserEmail] = React.useState('');

  const onSubmit = async (e) => {
    e.preventDefault();
    const dateTime = new Date().toISOString();
    onOpen(false);
    if (isReport && userEmail) {
      try {
        await invitereportService.inviteGuestForReport({
          email: userEmail,
          testReportId: tId,
        });
        setSnackbarData({
          navigateText: '',
          severity: 'success',
          state: true,
          message: 'Report Invited Successfully ..!',
        });
      } catch (error) {
        setSnackbarData({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error.data.message}`,
        });
      } finally {
        setUserEmail('');
      }
    } else {
      try {
        await invitereportService.inviteForReport({
          companyId: cid,
          createAt: dateTime,
          listInvitedTo: selectedxmlUsers,
          testReportFileFormat: FileType.XML,
          testReportId: tId,
          updateAt: dateTime,
          projectId: pid,
        });
        setSnackbarData({
          navigateText: '',
          severity: 'success',
          state: true,
          message: 'Report Invited Successfully ..!',
        });
      } catch (error) {
        setSnackbarData({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error.data.message}`,
        });
      } finally {
        setSelectedXmlUsers([]);
      }
    }
  };
  const onClose = () => {
    setSelectedXmlUsers([]);
    onOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openDailog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDailog]);
  return (
    <div>
      <Dialog
        open={openDailog}
        fullWidth
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <DialogTitle>
          Invite Report
        </DialogTitle>
        <Divider />
        <DialogContent />
        <IconButtonStyle
          onClick={() => onOpen(false)}
        >
          <CloseIcon />
        </IconButtonStyle>
        <Form onSubmit={(e) => onSubmit(e)}>
          {isReport
            ? <TextField sx={{ m: 1, width: '25ch' }} onChange={(e) => setUserEmail(e.target.value)} id="standard-basic" margin="normal" label="Enter email address" variant="standard" />
            : <Autocomplete
                multiple
                value={selectedxmlUsers}
                options={userList?.map((user) => user?.invitedTo?.email)}
                disableCloseOnSelect
                onChange={(event, newValue) => {
                  setSelectedXmlUsers(newValue);
                }}
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Checkboxes" />
                )}
            />}
          <DialogActions>
            <Button autoFocus color="primary" variant="contained" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" autoFocus disabled={isReport ? !userEmail : selectedxmlUsers.length === 0} color="primary" variant="contained">
              Send
            </Button>
          </DialogActions>
        </Form>
        <DialogContent />
      </Dialog>
    </div>
  );
}

ScrollDialog.propTypes = {
  openDailog: PropTypes.bool,
  tId: PropTypes.string,
  cid: PropTypes.string,
  pid: PropTypes.string,
  userList: PropTypes.instanceOf(Array),
  onOpen: PropTypes.func,
  setSnackbarData: PropTypes.func,
  isReport: PropTypes.bool,
};

ScrollDialog.defaultProps = {
  openDailog: false,
  tId: '',
  cid: '',
  pid: '',
  userList: [],
  onOpen: PropTypes.func,
  setSnackbarData: PropTypes.func,
  isReport: false,
};
