import { styled } from '@mui/material/styles';

const WelcomeTextStyling = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.primary.main,
  display: 'block',
  textAlign: 'center',
  borderBottom: '2px solid',
  borderColor: '#e2e5e8',
  paddingBottom: '10px',
  fontSize: theme.fontSize.headerFontSize,
}));

export default WelcomeTextStyling;
