import axiosInstance from './axiosInstance';

class CommentService {
  async create(payload) {
    try {
      const response = await axiosInstance.post('/review-report/addReview', payload);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getAll(payload) {
    try {
      const response = await axiosInstance.get(`/public/getAllReviewsByTestName?testName=${encodeURI(payload.reportName)}&testReportId=${payload.id}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getTagsForGraph(testReportId) {
    try {
      const response = await axiosInstance.get(`/review-report/getTagGraphByReportId/${testReportId}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getTagNamebyReportId(id) {
    try {
      const response = await axiosInstance.get(`/public/getTagNameByReportId?testReportId=${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getTagCountByReportId(id) {
    try {
      const response = await axiosInstance.get(`/public/getTagCountByReportId?testReportId=${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }
}
export default new CommentService();
