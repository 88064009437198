import ApartmentIcon from '@mui/icons-material/Apartment';
import SourceIcon from '@mui/icons-material/Source';
import AssessmentIcon from '@mui/icons-material/Assessment';
import React from 'react';
import route from '../../utils/route';
import { UserPermission } from '../../utils/constants';
import playwrightLogo from '../../assets/playwright.png';

export const MENU_LIST = [
  {
    title: 'Companies',
    icon: <ApartmentIcon />,
    permission: UserPermission.COMPANY,
    href: `/${route.ROUTE_COMPANIES}`,
    children: [],
  },
  {
    title: 'Projects',
    icon: <SourceIcon />,
    permission: UserPermission.PROJECT,
    href: `/${route.PROJECT_LIST}`,
    children: [],
  },
  {
    title: 'Selenium Report',
    type: 'SELENIUM',
    icon: <AssessmentIcon />,
    permission: UserPermission.VIEW_REPORT,
    href: '',
    children: [
      {
        name: 'XML Reports',
        href: `/${route.REPORT_LIST}`,
        icon: '',
      },
      {
        name: 'HTML Reports',
        href: `/${route.HTML_REPORT}`,
        icon: '',
      },
    ],
  },
  {
    title: 'Playwright Report',
    type: 'PLAY_WRIGHT',
    icon: <img src={playwrightLogo} alt="logo" width={20} height={20} />,
    permission: UserPermission.VIEW_REPORT,
    href: `/${route.PLAYWRIGHT_LIST}`,
    children: [],
  },
  {
    title: 'Cypress Report',
    type: 'CYPRESS',
    icon: <AssessmentIcon />,
    permission: UserPermission.VIEW_REPORT,
    href: `/${route.CYPRESS_LIST}`,
    children: [],
  },
];

export const USER_MENU_LIST = [
  {
    title: 'Invited Projects',
    icon: <SourceIcon />,
    permission: UserPermission.GUEST_USER,
    href: `/${route.PROJECT_LIST}`,
    children: [],
  },
  {
    title: 'Invited Reports',
    icon: <AssessmentIcon />,
    permission: UserPermission.GUEST_USER,
    href: '',
    children: [
      {
        name: 'XML Reports',
        href: `/${route.REPORT_LIST}`,
        icon: '',
      },
      {
        name: 'HTML Reports',
        href: `/${route.HTML_REPORT}`,
        icon: '',
      },
    ],
  },
];

export default MENU_LIST;
