/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FileType } from '../../utils/constants';
import useHandleChipClick from '../../utils/FilterChipHook';

const chartOptions = {
  chart: {
    type: 'bar',
    padding: 2,
  },
  title: {
    text: null,
  },
  responsive: true,
  xAxis: {
    categories: [],
    lineWidth: 1,
    lineColor: '#aeaeae',
    labels: {
      enabled: true,
      style: {
        textOverflow: 'ellipsis',
        width: '100px',
      },
    },
  },
  yAxis: {
    lineWidth: 1,
    lineColor: '#aeaeae',
    gridLineWidth: 0,
    labels: {
      enabled: false,
    },
    title: {
      text: null,
    },
  },
  legend: {
    align: 'center',
    verticalAlign: 'bottom',
    symbolRadius: 50,
    itemMarginBottom: 10,
    enabled: true,
    itemStyle: {
      fontWeight: 'normal',
      fontSize: '12px',
    },
  },
  plotOptions: {
    series: {
      stacking: 'normal',
      groupPadding: 0.1,
      pointPadding: 0.1,
      cursor: 'pointer',
    },
    bar: {
      borderWidth: 0.5,
      pointWidth: 10,
    },
  },
  colors: ['#5FD726', '#FF0000', '#FFC72B', '#000000'], // Green, Red, Yellow, Black
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
};

const HorizontalStackedBarChart = ({ data, reportType, loading }) => {
  const [chartDataOption, setChartDataOption] = useState({});
  const navigate = useNavigate();
  const handleChipClick = useHandleChipClick();

  useEffect(() => {
    if (data) {
      const categories = data && data?.map((item) => item.reportName);
      let series = [];
      switch (reportType) {
        case FileType.SELENIUM:
        case FileType.XML:
          series = [
            {
              name: 'Passed',
              data: data?.map((report) => ({
                y: report?.passed || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
            {
              name: 'Failed',
              data: data?.map((report) => ({
                y: report?.failed || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
            {
              name: 'Skipped',
              data: data?.map((report) => ({
                y: report?.skipped || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
            {
              name: 'Ignored',
              data: data?.map((report) => ({
                y: report?.ignored || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
          ];
          break;
        case FileType.PLAYWRIGHT:
          series = [
            {
              name: 'Passed',
              data: data?.map((report) => ({
                y: report?.expected || report?.passed || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
            {
              name: 'Failed',
              data: data?.map((report) => ({
                y: report?.unexpected || report?.failed || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
            {
              name: 'Skipped',
              data: data?.map((report) => ({
                y: report?.skipped || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
            {
              name: 'Flaky',
              data: data?.map((report) => ({
                y: report?.flaky || report?.ignored || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
          ];
          break;
        case FileType.CYPRESS:
          series = [
            {
              name: 'Passed',
              data: data?.map((report) => ({
                y: report?.passes || report?.passed || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
            {
              name: 'Failed',
              data: data?.map((report) => ({
                y: report?.failures || report?.failed || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
            {
              name: 'Skipped',
              data: data?.map((report) => ({
                y: report?.skipped || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
            {
              name: 'Pending',
              data: data?.map((report) => ({
                y: report?.pending || report?.ignored || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
          ];
          break;
        default:
          series = [
            {
              name: 'Passed',
              data: data?.map((report) => ({
                y: report?.passed || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
            {
              name: 'Failed',
              data: data?.map((report) => ({
                y: report?.failed || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
            {
              name: 'Skipped',
              data: data?.map((report) => ({
                y: report?.skipped || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
            {
              name: 'Retried',
              data: data?.map((report) => ({
                y: report?.retried || report?.ignored || 0,
                key: report?.reportId,
                type: report?.fileType,
              })),
            },
          ];
          break;
      }

      const dataList = {
        ...chartOptions,
        xAxis: {
          ...chartOptions.xAxis,
          categories,
        },
        series,
        plotOptions: {
          ...chartOptions.plotOptions,
          series: {
            ...chartOptions.plotOptions.series,
            point: {
              events: {
                click() {
                  const { options } = this;
                  handleChipClick(this?.series?.name);
                  navigate(`/build/${options?.type}/${options?.key}`);
                },
              },
            },
          },
        },
      };

      setChartDataOption(dataList);
    }
  }, [data, reportType]);

  return (
    <div style={{ width: '100%' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={chartDataOption} />
      )}
    </div>
  );
};

export default HorizontalStackedBarChart;
