import axiosInstance from './axiosInstance';

class NotificationService {
  async getNotification(pageSize) {
    try {
      const response = await axiosInstance.get(`/notification/all?pageNumber=0&pageSize=${pageSize}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async projectandReport() {
    try {
      const response = await axiosInstance.get('/notification/projectandreport');
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async updateNotification(payload) {
    try {
      const response = await axiosInstance.post('/notification/read', payload);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }
}
export default new NotificationService();
