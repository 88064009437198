import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearIndeterminate({ progress }) {
  LinearIndeterminate.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    progress: PropTypes.number.isRequired,
  };
  return (
    <Box sx={{ width: '100%', mr: 1 }}>
      { progress }
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    </Box>
  );
}
