import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Tabs,
  Tab,
  Menu,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import pieChart from '../../assets/images/pie-chart.png';
import Engineering from '../../assets/images/engineering.png';
import Disruption from '../../assets/images/disruption.png';
import Statistics from '../../assets/images/statistics.png';
import Execution from '../../assets/images/execution.png';
import { useCommonContext } from '../header/context';
import {
  fetchKeyInsightsCypress,
  fetchKeyInsightsPlaywright,
  fetchKeyInsightsSeleniumHTML,
  fetchKeyInsightsSeleniumXML,
} from '../../services/home.services';
import {
  cypressTestResultList,
  playwrightTestResultList,
  seleniumHTMLTestResultList,
  seleniumTestResultList,
} from '../../services/uploadFile.service';
import { calculateDateRange } from '../dashboard/utils';
import companyService from '../../services/company.service';
import reportService from '../../services/report.service';
import TestExecutionList from './TestExecution';
import { homeStyles } from '../header/style';
import {
  segregateSeleniumData, segregatePlaywrightData, segregateCypressData, segregateSeleniumHTMLData,
} from './TestData';
import {
  FileType, UserRole, UserType,
} from '../../utils/constants';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import ProjectSetUp from '../onBoarding/ProjectSetUp';

const Home = () => {
  const classes = homeStyles();
  const navigate = useNavigate();
  const {
    reportType, user, setReportType, setActiveItem,
  } = useCommonContext();
  const [insights, setInsights] = useState();
  const [tests, setTests] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedTab, setSelectedTab] = useState(1);
  const [sortOrder] = useState('desc');
  const [anchorEl, setAnchorEl] = useState(null);
  const [startValue, setStartValue] = useState(dayjs());
  const [endValue, setEndValue] = useState(dayjs());
  const [dateRange, setDateRange] = useState(calculateDateRange('7D'));
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [projectId, setprojectId] = useState(null);
  const [project, setProject] = useState(null);
  const [testReportName, setTestReportName] = useState('');
  const [loading, setLoading] = useState(false);
  const [jenkinsBuildVersion, setJenkinsBuildVersion] = useState('');
  const [isPagination, setIsPagination] = useState(false);
  const selectRef = useRef(null);
  const [selectKey, setSelectKey] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  const [isFirstLogin, setIsFirstLogin] = useState(false);

  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });

  const labels = ['1D', '7D', '30D', '6M', '1Y', '2Y', 'Custom'];

  const keyInsights = [
    {
      id: 'product-health', title: 'Project Health', value: `${insights?.productHealth || 0}%`, change: '9%', image: pieChart,
    },
    {
      id: 'unique-test-executions', title: 'Unique Test Executions', value: `${insights?.uniqueTestExecutions || 0}`, change: '9%', image: Engineering,
    },
    {
      id: 'new-failures', title: 'New Failures', value: `${insights?.newFailure || 0}`, change: '9%', image: Disruption,
    },
    {
      id: 'always-failing', title: 'Always Failing', value: `${insights?.alwaysFailing || 0}`, change: '200%', image: Statistics,
    },
    {
      id: 'executions', title: 'Executed Tests', value: `${insights?.executions || 0}`, change: '0%', image: Execution,
    },
  ];

  const handleTabChange = (event, newValue) => {
    setPage(1);
    setSelectedTab(newValue);
    const selectedLabel = labels[newValue];
    if (selectedLabel === 'Custom') {
      setAnchorEl(event.currentTarget);
    } else {
      const newDateRange = calculateDateRange(selectedLabel);
      setDateRange(newDateRange);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    const newDateRange = {
      startDate: startValue.startOf('day').format('YYYY-MM-DD'),
      endDate: endValue.endOf('day').format('YYYY-MM-DD'),
    };
    setDateRange(newDateRange);
  };

  const generateQuery = (filterParams = {}) => {
    const timestring = ![FileType.SELENIUM, FileType.XML, FileType.HTML].includes(reportType) ? 'startTime' : 'startedAt';
    let filterAnd = `${timestring}|gte|${dateRange.startDate}&${timestring}|lte|${dateRange.endDate}`;
    let selectedCompanyType;
    if (filterParams.companyId) {
      filterAnd += `&companyId|eq|${filterParams.companyId}`;
      const selectedCompany = companyDropdown.find((item) => item.companyId
        === filterParams.companyId);
      selectedCompanyType = selectedCompany?.projects[0]?.dashboardType;
      setReportType(selectedCompanyType);
    }

    if (filterParams.projectId) {
      filterAnd += `&projectId|eq|${filterParams.projectId}`;
    }

    if (filterParams.testReportName) {
      filterAnd += `&testReportName|lic|${filterParams.testReportName}`;
    }

    if (filterParams.jenkinsBuildVersion) {
      filterAnd += `&jenkinsBuildVersion|eq|${filterParams.jenkinsBuildVersion}`;
    }

    const params = {
      pageNumber: `${page - 1}`,
      pageSize: `${rowsPerPage}`,
      orders: `${timestring}|${sortOrder}`,
      filterAnd,
    };
    return {
      query: new URLSearchParams(params).toString(),
      dashboardType: selectedCompanyType, // Return the dashboardType
    };
  };

  const getKeyInsights = async (filterParams = {}) => {
    try {
      const { query, dashboardType } = generateQuery(filterParams);
      let res;
      switch (dashboardType || reportType) {
        case FileType.SELENIUM:
        case FileType.XML:
          res = await fetchKeyInsightsSeleniumXML(query);
          break;
        case FileType.HTML:
          res = await fetchKeyInsightsSeleniumHTML(query);
          break;
        case FileType.PLAYWRIGHT:
          res = await fetchKeyInsightsPlaywright(query);
          break;
        case FileType.CYPRESS:
          res = await fetchKeyInsightsCypress(query);
          break;
        default:
          throw new Error('Unsupported report type');
      }
      setInsights(res);
    } catch (error) {
      console.log(error);
    }
  };

  const getTestExecutions = async (filterParams = {}) => {
    try {
      setLoading(true);
      const { query, dashboardType } = generateQuery(filterParams);
      let res;
      let segregatedData;
      switch (dashboardType || reportType) {
        case FileType.SELENIUM:
        case FileType.XML:
          res = await seleniumTestResultList(query);
          segregatedData = segregateSeleniumData(res.content);
          break;
        case FileType.HTML:
          res = await seleniumHTMLTestResultList(query);
          segregatedData = segregateSeleniumHTMLData(res.content);
          break;
        case FileType.PLAYWRIGHT:
          res = await playwrightTestResultList(query);
          segregatedData = segregatePlaywrightData(res.content);
          break;
        case FileType.CYPRESS:
          res = await cypressTestResultList(query);
          segregatedData = segregateCypressData(res.content);
          break;
        default:
          throw new Error('Unsupported report type');
      }
      setTests(segregatedData);
      setTotalPages(res.totalPages);
      setIsPagination(res.totalElements > 5);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message || error?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const companyList = async () => {
    if (user?.role?.roleName === UserRole.SUPER_ADMIN && user?.userType === UserType.INTERNAL) {
      try {
        const res = await companyService.getAll();
        setCompanyDropdown(res);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const getProjectName = async () => {
    if (companyId) {
      try {
        const res = await reportService.getProjectById(companyId);
        setProject(res);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleFilter = (type) => {
    setPage(1);
    const filterParams = {};
    if (type === 'apply') {
      if (companyId) {
        filterParams.companyId = companyId;
      }
      if (projectId) {
        filterParams.projectId = projectId;
      }
      if (testReportName) {
        filterParams.testReportName = testReportName;
      }
      if (jenkinsBuildVersion) {
        filterParams.jenkinsBuildVersion = jenkinsBuildVersion;
      }
    }
    setFilterValues(filterParams);
  };

  const handleClear = () => {
    setCompanyId(null);
    setprojectId(null);
    setTestReportName('');
    setJenkinsBuildVersion('');
    setSelectKey((prevKey) => prevKey + 1);
    handleFilter('clear');
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handleTestClick = (id, fileType) => {
    navigate(`/build/${fileType}/${id}`);
  };

  useEffect(() => {
    if (reportType) {
      getTestExecutions(filterValues);
    }
  }, [reportType, page, rowsPerPage, dateRange, filterValues]);

  useEffect(() => {
    companyList();
    setIsFirstLogin(user?.firstLogin);
  }, [user?.role?.roleId]);

  useEffect(() => {
    getProjectName();
  }, [companyId]);

  useEffect(() => {
    if (user && reportType) getKeyInsights(filterValues);
  }, [dateRange, reportType, filterValues]);

  useEffect(() => {
    setActiveItem('Home');
  }, []);

  const handleChangeRowsPerPage = (
    event,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <>
      <Box>
        <Box className={classes.heading}>
          <Typography variant="h4" className={classes.title}>
            Home
          </Typography>
        </Box>
        <Box className={classes.header}>
          <Typography fontWeight="600" variant="h6">Key Insight</Typography>
          <Tabs
            value={selectedTab}
            indicatorColor="none"
            textColor="primary"
            alignItems="center"
            sx={{
              mx: 2,
              fontSize: 2,
              '& .MuiTabs-flexContainer': {
                alignItems: 'center',
              },
            }}
          >
            {labels && labels?.map((item) => <Tab
              key={item}
              className={`${classes.tab} ${labels[selectedTab] === item ? classes.balloon : ''}`}
              label={item}
              onClick={(event) => {
                if (item === 'Custom') {
                  handleTabChange(event, labels.indexOf(item));
                } else {
                  handleTabChange(event, labels.indexOf(item));
                }
              }}
            />)}
          </Tabs>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                padding: '16px',
              },
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box p={2}>
                <Typography variant="h6" gutterBottom>
                  Select Date Range
                </Typography>
                <DemoContainer components={['DatePicker']}>
                  <Box mb={2}>
                    <DatePicker
                      label="Start Date"
                      disableFuture
                      value={startValue}
                      onChange={(newValue) => setStartValue(newValue)}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </Box>
                  <DatePicker
                    label="End Date"
                    disableFuture
                    value={endValue}
                    onChange={(newValue) => setEndValue(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </DemoContainer>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button className={classes.contactButton} onClick={handleMenuClose} variant="contained">
                    Apply
                  </Button>
                </Box>
              </Box>
            </LocalizationProvider>
          </Menu>
          <Chip
            variant="outlined"
            label={`${dateRange.startDate} / ${dateRange.endDate}`}
            className={classes.chipLabel}
          />
        </Box>
        <Box className={classes.header} sx={{ display: 'flex', gap: 2 }}>
          {user?.role?.roleName === UserRole.SUPER_ADMIN && <>
            <Box sx={{ minWidth: 200 }}>
              <FormControl sx={{ width: '100%', backgroundColor: '#fff' }}>
                <InputLabel size="small">
                  Company
                </InputLabel>
                <Select
                  ref={selectRef}
                  key={selectKey}
                  size="small"
                  label="Company"
                  value={companyId || ''}
                  onChange={(e) => {
                    setCompanyId(e.target.value);
                    setprojectId(null);
                    setFilterValues({ companyId: e.target.value || null });
                  }}
                >
                  {!companyDropdown.length
                    && <MenuItem style={{ color: '#999' }}>
                      No Company Found
                    </MenuItem>}
                  {companyDropdown && companyDropdown?.map((menuitem) => (
                    <MenuItem value={menuitem?.companyId} key={menuitem?.companyId}>
                      {menuitem?.companyName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 200 }}>
              <FormControl sx={{ width: '100%', backgroundColor: '#fff' }}>
                <InputLabel size="small">
                  Projects
                </InputLabel>
                {project && project.length > 0
                  ? <Select
                      ref={selectRef}
                      key={selectKey}
                      size="small"
                      label="Projects"
                      value={projectId || ''}
                      onChange={(e) => {
                        setprojectId(e.target.value);
                        setFilterValues({ projectId: e.target.value || null });
                      }}
                  >
                    {project && project?.map((menuitem) => (
                      <MenuItem value={menuitem?.projectId} key={menuitem?.projectId}>
                        {menuitem?.projectName}
                      </MenuItem>
                    ))}
                  </Select> : <Select
                    label="Projects"
                    sx={{
                      cursor: 'none',
                      '& .MuiSelect-outlined': {
                        padding: '8.5px 14px;',
                      },
                    }}
                  >
                    <MenuItem style={{ color: '#999' }}>
                      Projects
                    </MenuItem>
                  </Select>}
              </FormControl>
            </Box>
          </>}
          <Box sx={{ minWidth: 200, backgroundColor: '#fff' }}>
            <TextField
              label="Test Report Name"
              value={testReportName}
              onChange={(e) => setTestReportName(e.target.value)}
              fullWidth
              size="small"
            />
          </Box>
          <Box sx={{ minWidth: 200, backgroundColor: '#fff' }}>
            <TextField
              label="Jenkins Build Version"
              value={jenkinsBuildVersion}
              onChange={(e) => setJenkinsBuildVersion(e.target.value)}
              fullWidth
              size="small"
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button className={classes.contactButton} onClick={() => handleFilter('apply')} variant="contained" color="primary">
              Apply
            </Button>
          </Box>
          {(companyId || projectId || testReportName || jenkinsBuildVersion) && <Box display="flex" justifyContent="flex-end" mx={0}>
            <Typography onClick={handleClear} style={{ fontSize: '13px', color: '#393939', cursor: 'pointer' }}>
              Clear All
            </Typography>
          </Box>}
        </Box>

        <Grid container spacing={3} className={classes.gridContainer}>
          {keyInsights && keyInsights?.map((item) => (
            <Grid item xs={12} md={4} lg={2.4} key={item.id}>
              <Paper className={classes.card}>
                <Box display="flex" justifyContent="center" flexDirection="column">
                  <Grid className={classes.cardFooter}>
                    <img src={item.image} alt={item.title} width={50} />
                    <Typography variant="h4" className={classes.cardLeft}>{item?.value}</Typography>
                    <Typography variant="body1" className={classes.cardTitle}>{item?.title}</Typography>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Typography fontWeight="600" variant="h6" gutterBottom>
          Latest Test Execution
        </Typography>
        {isFirstLogin
        && <ProjectSetUp
          opend={isFirstLogin}
          handleClose={() => setIsFirstLogin(false)}
        />}
        {loading ? <Box sx={{ textAlign: 'center' }}><CircularProgress /></Box> : <TestExecutionList
          tests={tests}
          totalPages={totalPages}
          page={page}
          isPagination={isPagination}
          onTestClick={handleTestClick}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />}
      </Box>
      {
        snackbar.state && (
          <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
        )
      }
    </>
  );
};

export default Home;
/* Grid View design commented */
/* <Grid container spacing={2}>
        {testExecutions.map((execution) => (
          <Grid item sm={12} md={4} key={execution.id}>
            <Paper className={classes.latestTestExecution}>
              <Box className={classes.details}>
                <Box display="flex">
                  <Box>
                    <Typography variant="body1">
                      {execution.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" my={1}>
                      Ran by
                      {` ${execution.ranBy} `}
                      on
                    </Typography>
                    <Typography variant="body2" color="textSecondary" my={1}>
                      {execution.date}
                      {' | '}
                      {execution.time}
                      (IST)
                    </Typography>
                    <Chip label="New Features (1)"
                    variant="outlined" className={classes.chipFeatures} />
                    <Box className={classes.chips}>
                      {execution.chips.map((chip) => (
                        <Chip
                          variant="outlined"
                          key={chip}
                          label={chip}
                          className={getChipClass(chip)}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
                <Typography variant="body1" className={classes.closeIcon}>
                  <CancelIcon />
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
 */
