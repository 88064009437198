const extractBuildSummaryXML = (data) => {
  const stats = data.testingResults['testng-results'];
  return {
    passed: stats.passed || 0,
    failed: stats.failed || 0,
    ignored: stats.ignored || 0,
    skipped: stats.skipped || 0,
    total: stats.total || 0,
  };
};

const extractBuildSummaryHTML = (data) => {
  const stats = data.testingResults;
  return {
    passed: stats.passed || 0,
    failed: stats.failed || 0,
    skipped: stats.skipped || 0,
    retried: stats.retried || 0,
    total: stats.total || 0,
  };
};

const extractBuildSummaryPlaywright = (data) => {
  const { stats } = data.reportResult;
  return {
    passed: stats.expected || 0,
    failed: stats.unexpected || 0,
    flaky: stats.flaky || 0,
    skipped: stats.skipped || 0,
    total: stats.total || 0,
  };
};
const extractBuildSummaryCypress = (data) => {
  const { stats } = data.reportResult.dataRow;
  return {
    passed: stats.passes || 0,
    failed: stats.failures || 0,
    pending: stats.pending || 0,
    skipped: stats.skipped || 0,
    total: stats.tests || 0,
  };
};

export {
  extractBuildSummaryXML,
  extractBuildSummaryCypress,
  extractBuildSummaryPlaywright,
  extractBuildSummaryHTML,
};
