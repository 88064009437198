import {
  styled,
} from '@mui/material/styles';
import {
  Grid,
  Box,
  Typography,
  Button,
} from '@mui/material';

const StyledBox = styled(Box)(() => ({
  marginTop: '95px',
  marginRight: '20px',
  height: '85vh',
  p: '4',
  background: '#f5f5f5',
}));

const StyledGrid = styled(Grid)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '85vh',
}));

const StyledTypography = styled(Typography)(() => ({
  flex: '1 0 100%',
  alignItems: 'center',
  textAlign: 'center',
  // alignItems: 'center',
}));

const StyledButton = styled(Button)(() => ({
  flex: '0 1 10%',
}));

export {
  StyledGrid, StyledBox, StyledTypography, StyledButton,
};
