import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { CircularProgress, Divider, TextField } from '@mui/material';
import {
  IconButtonStyle,
} from './style';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    borderBottom: `3px solid ${theme.palette.warning.main}`,
    borderLeft: `3px solid ${theme.palette.warning.main}`,
  },
}));

export default function CompanyModal({
  open,
  handleClose,
  data,
  onChange,
  handleSubmitForm,
  handleUpdateForm,
  companyEdit,
  companyShow,
}) {
  const { companyName } = data;
  const [loading] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const onSubmit = () => {
    if (!companyName) {
      setCompanyError(true);
      return;
    }
    handleSubmitForm();
  };
  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {companyEdit?.companyName ? 'Edit a Company' : 'Create a Company'}
      </DialogTitle>
      <Divider />
      <IconButtonStyle
        aria-label="close"
        data-testid="UploadReport-box"
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButtonStyle>
      <DialogContent>
        <DialogContentText>
          <form>
            <TextField
              placeholder="Enter Company Name"
              label="Company"
              margin="dense"
              variant="outlined"
              value={companyShow ? companyEdit?.companyName : companyName}
              onChange={(e) => {
                onChange(e);
                setCompanyError(e.target.value.length < 5);
              }}
              helperText={companyError ? 'Company Name should be greater than 5 characters' : ''}
              fullWidth
              style={{ width: '360px' }}
              error={companyError}
            />
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress style={{ marginLeft: '25px' }} />}
        <Button onClick={handleClose} variant="outlined">Cancel</Button>
        {companyEdit?.companyName ? <Button autoFocus color="primary" variant="contained" onClick={handleUpdateForm}>
          Update
        </Button> : <Button autoFocus color="primary" variant="contained" onClick={() => { onSubmit(); }}>
          Submit
        </Button>}
      </DialogActions>
    </StyledDialog>
  );
}
CompanyModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onChange: PropTypes.func.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  handleUpdateForm: PropTypes.func.isRequired,
  companyEdit: PropTypes.string.isRequired,
  companyShow: PropTypes.bool.isRequired,
};
CompanyModal.defaultProps = {
  open: PropTypes.bool,
};
