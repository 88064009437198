import React, { useEffect, useState } from 'react';
import {
  TextField,
  Grid,
  Box,
  CircularProgress,
  Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  BelowWelcomeText,
  ButtonText,
  TextFieldBox,
  CustomButton,
  LoginFormGrid,
  CreateAccountText,
  WelcomeTextStyling,
} from './style';
import commonUtils from '../../utils/common/common';
import route from '../../utils/route';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import userService from '../../services/user.service';

const path = window.location.pathname.split('/');
function Register() {
  const initialValues = {
    firstName: '',
    lastName: '',
    password: '',
    mobile: '',
  };
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const register = async (values) => {
    try {
      setLoading(true);
      await userService.registerUser(path[path.length - 1], { ...values, email });
      setSnackbar({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Register Successfully',
      });
      setTimeout(() => {
        navigate(`/${route.ROUTE_LOGIN}`);
      }, 2000);
    } catch (err) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err.data.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  const signUpSchema = Yup.object({
    firstName: Yup.string().trim().matches(commonUtils.validName, 'Please enter valid Firstname').min(2, 'Must include more than one character')
      .required('Firstname is required'),
    lastName: Yup.string().trim().matches(commonUtils.validName, 'Please enter valid Lastname').min(2, 'Must include more than one character')
      .required('Lastname is required'),
    password: Yup.string().matches(commonUtils.validatePassword, 'Password must contain more than 8 characters, 1 number and one special character').required('Please enter your password'),
    mobile: Yup.string().trim().matches(commonUtils.validatePhone, 'Please enter valid Mobile No.').required('Mobile No. is required'),
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    onSubmit: () => register(values),
  });
  const verifyLink = async () => {
    try {
      const res = await userService.verifyLink(path[path.length - 1]);
      setEmail(res);
    } catch (error) {
      navigate(`/${route.INVITE_EXPIRED}`);
    }
  };
  useEffect(() => {
    verifyLink();
    document.body.classList.add('login-layout');
    return () => {
      document.body.classList.remove('login-layout');
    };
  }, []);

  return (
    <Box>
      <Grid
        container
        data-testid="register-box"
        justifyContent="center"
        height="100vh"
      >
        <LoginFormGrid
          item
          sm={4}
          xs={12}
        >
          <Paper elevation={3} sx={{ py: 2 }}>
            <WelcomeTextStyling>
              Welcome
            </WelcomeTextStyling>
            <BelowWelcomeText>
              Already have an account?
              <CreateAccountText
                onClick={() => { navigate(`/${route.ROUTE_LOGIN}`); }}
                underline="hover"
              >
                Sign In
              </CreateAccountText>
            </BelowWelcomeText>
            <form onSubmit={handleSubmit}>
              <TextFieldBox>
                <TextField
                  fullWidth
                  error={touched.firstName && errors.firstName}
                  helperText={touched.firstName && errors.firstName}
                  value={values.firstName}
                  label="First Name"
                  name="firstName"
                  inputProps={{ 'data-testid': 'name-field' }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </TextFieldBox>
              <TextFieldBox>
                <TextField
                  fullWidth
                  error={touched.lastName && errors.lastName}
                  helperText={touched.lastName && errors.lastName}
                  value={values.lastName}
                  label="Last Name"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </TextFieldBox>
              <TextFieldBox>
                <TextField
                  fullWidth
                  value={email}
                  disabled
                  name="email"
                  inputProps={{ 'data-testid': 'email-field' }}
                />
              </TextFieldBox>
              <TextFieldBox>
                <TextField
                  fullWidth
                  error={touched.mobile && errors.mobile}
                  helperText={touched.mobile && errors.mobile}
                  value={values.mobile}
                  label="Mobile"
                  name="mobile"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </TextFieldBox>
              <TextFieldBox>
                <TextField
                  fullWidth
                  error={touched.password && errors.password}
                  helperText={touched.password && errors.password}
                  value={values.password}
                  inputProps={{ 'data-testid': 'password-field' }}
                  label="Password"
                  name="password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </TextFieldBox>
              {loading && <CircularProgress style={{ marginLeft: '292px' }} />}
              <Grid display="flex" justifyContent="center">
                {
                  !loading && <CustomButton type="submit">
                    <ButtonText>
                      sign up
                    </ButtonText>
                  </CustomButton>
                }
              </Grid>
            </form>
          </Paper>
        </LoginFormGrid>
      </Grid>
      {
        snackbar.state
        && (
          <NavigateSnackbar
            snackbarObj={snackbar}
            setSnackbar={setSnackbar}
          />
        )
      }
    </Box>
  );
}
export default Register;
