import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  // CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ApartmentIcon from '@mui/icons-material/Apartment';
import TablePagination from '@mui/material/TablePagination';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../breadcrumbs/Breadcrumb';
import { ParentBox, ChildBox, groupListStyle } from './style';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import groupService from '../../services/group.service';
import Loader from '../../utils/loader/Loader';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function GroupList() {
  const styles = groupListStyle();
  const [groupdata, setGroupData] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCounts] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const getAllGroups = async () => {
    try {
      setLoading(true);
      const res = await groupService.getAllGroups(page, rowsPerPage);
      if (res) {
        setGroupData(res.content);
        setTotalCounts(res.totalElements);
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllGroups();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const viewGroup = (id) => {
    if (id) {
      navigate(`/groupDetail/${id}`);
    }
  };

  return (
    <>
      (
      <Box
        sx={{
          marginTop: '100px',
          marginLeft: 4,
          marginRight: 4,
        }}
      >
        <ParentBox>
          <Grid container rowSpacing={3}>
            <Grid
              item
              xs={12}
            >
              <Breadcrumb data={['Groups']} />
              {loading && <Loader />}
              <ChildBox>
                <item>
                  <Typography className={styles.itemHeadingBox} component="div">
                    <ApartmentIcon />
                    <Typography className={styles.itemHeading} variant="h6" component="div">
                      All Groups
                    </Typography>
                  </Typography>
                </item>
              </ChildBox>
            </Grid>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Group Name</StyledTableCell>
                    <StyledTableCell>Group Status</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    groupdata && groupdata?.map((item) => (
                      <StyledTableRow key={item.companyId}>
                        <StyledTableCell style={{ width: '40%' }}>{item.groupName}</StyledTableCell>
                        <StyledTableCell style={{ width: '40%' }}>{item.groupStatus}</StyledTableCell>
                        <StyledTableCell style={{ width: '30%' }}>
                          <VisibilityIcon style={{ cursor: 'pointer', width: '33.33%' }} onClick={() => viewGroup(item?.groupId)} />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
            { groupdata?.length && <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              count={totalCount}
              component="div"
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> }
          </Grid>
        </ParentBox>
        {
          snackbar.state
          && (
            <NavigateSnackbar
              snackbarObj={snackbar}
              setSnackbar={setSnackbar}
            />
          )
        }
      </Box>
      )
    </>
  );
}

export default GroupList;
