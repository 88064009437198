import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TablePagination from '@mui/material/TablePagination';
import Breadcrumb from '../breadcrumbs/Breadcrumb';
import route from '../../utils/route';
import { ParentBox, ChildBox, useProfileStyle } from './style';
import profileService from '../../services/profile.service';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Profile() {
  const location = useLocation();
  const styles = useProfileStyle();
  const navigate = useNavigate();
  const [profiledata, setProfileData] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCounts] = useState('');
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getAllProfile = async () => {
    try {
      const res = await profileService.getAllProfile(page, rowsPerPage);
      if (res) {
        setProfileData(res.content);
        setTotalCounts(res.totalElements);
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    }
  };
  useEffect(() => {
    getAllProfile();
  }, [page, rowsPerPage]);

  const viewProfileDetail = (id) => {
    if (id) {
      navigate(`/profileDetail/${id}`);
    }
  };
  return (
    <>
      {
        location.pathname === `/${route.PROFILE_PAGE}` && (
          <Box
            sx={{
              marginTop: '100px',
              marginLeft: 4,
              marginRight: 4,
            }}
          >
            <ParentBox>
              <Grid container rowSpacing={3}>
                <Grid
                  item
                  xs={12}
                >
                  <Breadcrumb data={['Profile']} />
                  <ChildBox>
                    <item>
                      <Typography className={styles.itemHeadingBox} component="div">
                        <ApartmentIcon />
                        <Typography className={styles.itemHeading} variant="h6" component="div">
                          Profile
                        </Typography>
                        <InfoIcon />
                      </Typography>
                    </item>
                  </ChildBox>
                </Grid>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Profile Name</StyledTableCell>
                        <StyledTableCell>Profile Status</StyledTableCell>
                        <StyledTableCell>ACTIONS</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        profiledata && profiledata?.map((item) => (
                          <StyledTableRow>
                            <StyledTableCell style={{ width: '34%' }}>{item.profileName}</StyledTableCell>
                            <StyledTableCell style={{ width: '34%' }}>{item.profileStatus}</StyledTableCell>
                            <StyledTableCell style={{ width: '32%' }}>
                              <Button style={{ background: 'blue', color: 'white', marginRight: '30px' }} onClick={() => viewProfileDetail(item.profileId)}> Profile Detail </Button>
                              <EditIcon style={{ cursor: 'pointer', marginRight: '30px', color: 'green' }} />
                              <DeleteIcon style={{ cursor: 'pointer', marginRight: '30px', color: 'red' }} />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </ParentBox>
            {profiledata?.length && <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              count={totalCount}
              component="div"
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />}
            {
        snackbar.state
        && (
          <NavigateSnackbar
            snackbarObj={snackbar}
            setSnackbar={setSnackbar}
          />
        )
      }
          </Box>
        )
      }
    </>
  );
}

export default Profile;
