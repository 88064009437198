import { styled } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import {
  Box,
  Grid,
} from '@mui/material';

const reportStyles = makeStyles(() => createStyles({
  parentBox: {
    width: 'auto',
    marginX: 'auto',
  },
  parentTableBox: {
    overflow: 'hidden',
    border: '2px solid',
    marginTop: 8,
  },
  appBar: {
    position: 'relative',
    background: 'linear-gradient(146deg,#3f6398 8%,#3f6398 93%)',
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  reportTextBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  reportTypo: {
    fontWeight: 600,
    padding: 10,
  },
  iconParentBox: {
    display: 'flex',
  },
  iconBox: {
    marginRight: 5,
    marginTop: 5,
    background: 'rgba(255, 255, 255, 0.15)',
    borderRadius: '30px',
    fontSize: 1,
  },
  reportGroupSuperParentBox: {
    position: 'relative',
  },
  reportGroupParentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 16,
    marginRight: 16,
    marginTop: 8,
    background: '#FFFFFF',
    border: '1px solid #3f639859',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  reportGroupTypo: {
    color: '#3f6398',
    fontWeight: 600,
    textTransform: 'none',
  },
  failedButton: {
    textTransform: 'none',
    height: 28,
    width: 90,
    background: 'red',
    marginTop: 5,
    marginRight: 6,
    ':hover': {
      backgroundColor: '#f04438',
    },
  },
  testButton: {
    backgroundColor: '#10b981',
    marginLeft: 3,
    '&:hover': {
      backgroundColor: '#10b981',
    },
  },
  testlistButton: {
    backgroundColor: '#10b981',
    marginLeft: 3,
    width: '180px',
    '&:hover': {
      backgroundColor: '#10b981',
    },
  },
  failedlistButton: {
    backgroundColor: '#f04438',
    marginLeft: 3,
    width: '180px',
    '&:hover': {
      backgroundColor: '#f04438',
    },
  },
  skippedButton: {
    backgroundColor: '#EEB347',
    '&:hover': {
      backgroundColor: '#EEB347',
    },
  },
  listData: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginRight: '10px',
  },
  accordian: {
    margin: '0px 10px -50px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  accordianTime: {
    display: 'flex',
    alignItems: 'center',
  },
  accordianHeader: {
    fontSize: '16px',
    marginRight: '70px',
  },
  header: {
    color: '#3f6398',
    fontSize: '15px',
  },
  ignoredButton: {
    textTransform: 'none',
    backgroundColor: '#3f6398',
    '&:hover': {
      backgroundColor: '#3f6398',
    },
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
  },
  searchicon: {
    marginRight: '10px',
    color: '#337ab7',
  },
  failIcon: {
    marginRight: '10px',
    color: '#f04438',
  },
  reportHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  reportTime: {
    fontSize: '16px',
    marginRight: '70px',
    cursor: 'pointer',
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  paddingLeft: '16px',
  marginTop: '49px',
  marginLeft: '40px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  alignItems: 'center',
}));
const InputParentGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 0,
    marginLeft: 0,
  },
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
}));

const ParentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(40),
  },
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const DashboardParentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(12),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));
export {
  ParentBox, reportStyles, InputParentGrid, Item, DashboardParentBox,
};
