import React, { useEffect, useState } from 'react';
import {
  Box,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { reportStyles } from './style';
import { FileType } from '../../utils/constants';
import XmlReport from './XmlReport';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import invitereportService from '../../services/invitereport.service';
import Loader from '../../utils/loader/Loader';
import { useCommonContext } from '../header/context';
import HtmlReport from './HtmlReport';

const ViewReport = () => {
  const { id, type } = useParams();
  const styles = reportStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const {
    user,
  } = useCommonContext();
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });

  const viewReport = async () => {
    try {
      setLoading(true);
      if (id && type) {
        const res = await invitereportService.viewTestReport(id, type);
        if (res) {
          setData(res);
        }
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    viewReport();
  }, [user?.role?.roleId]);

  return (
    <>
      {loading && <Loader />}
      <Box
        className={styles.parentBox}
        data-testid="report-box"
      >
        {
          data?.fileType === FileType.XML && <XmlReport data={data} />
        }
        {
          data?.fileType === FileType.HTML && <HtmlReport data={data} />
        }
      </Box>
      {snackbar.state && (
        <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
      )}
    </>
  );
};

export default ViewReport;
