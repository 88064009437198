/* eslint-disable-next-line */
import 'react-quill/dist/quill.snow.css';
import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import ResizeModule from '@botom/quill-resize-module';
import PropTypes from 'prop-types';
import { uploadAsset } from '../../services/uploadFile.service';

const BaseImage = Quill.import('formats/image');
const BaseVideo = Quill.import('formats/video');
const ATTRIBUTES = ['alt', 'height', 'width', 'style'];
const WHITE_STYLE = ['margin', 'display', 'float'];
const sanitizeStyle = (style) => {
  const styleArr = style.split(';');
  let allowStyle = '';
  styleArr.forEach((v) => {
    if (WHITE_STYLE.indexOf(v.trim().split(':')[0]) !== -1) {
      allowStyle += `${v};`;
    }
  });
  return allowStyle;
};
const formatsBuild = (domNode) => (ATTRIBUTES.reduce((formats, attribute) => {
  const formatsData = formats;
  if (domNode.hasAttribute(attribute)) {
    formatsData[attribute] = domNode.getAttribute(attribute);
  }
  return formatsData;
}, {}));

const formatsReturn = (domNode, name, value) => {
  let valueData = value;
  if (value) {
    if (name === 'style') {
      valueData = sanitizeStyle(value);
    }
    domNode.setAttribute(name, valueData);
  } else {
    domNode.removeAttribute(name);
  }
};

class Image extends BaseImage {
  static formats(domNode) {
    /* eslint-disable-next-line */
    return formatsBuild(domNode);
  }

  format(name, value) {
    /* eslint-disable-next-line */
    if (ATTRIBUTES.indexOf(name) > -1) {
      /* eslint-disable-next-line */
      formatsReturn(this.domNode, name, value);
    } else {
      super.format(name, value);
    }
  }
}

class Video extends BaseVideo {
  static formats(domNode) {
    /* eslint-disable-next-line */
    return formatsBuild(domNode);
  }

  format(fname, value) {
    /* eslint-disable-next-line */
    if (ATTRIBUTES.indexOf(fname) > -1) {
      /* eslint-disable-next-line */
      formatsReturn(this.domNode, fname, value);
    } else {
      super.format(fname, value);
    }
  }
}

Quill.register(Image, true);
Quill.register(Video, true);
Quill.register('modules/resize', ResizeModule);

function imageHandler() {
  const self = this;
  let fileInput = self.container.querySelector('input.ql-image[type=file]');
  if (fileInput == null) {
    fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');
    fileInput.classList.add('ql-image');
    fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
    fileInput.addEventListener('change', async () => {
      if (fileInput.files != null && fileInput.files[0] != null) {
        uploadAsset(fileInput.files[0])
          .then((imageUrl) => {
            const range = self.quill.getSelection(true);
            self.quill.insertEmbed(range.index, 'image', `${process.env.REACT_APP_BACKEND_BASE_URL}/user/getImage/REVIEW_IMAGE/${imageUrl}`);
            self.quill.setSelection(range.index + 1);
            fileInput.value = '';
          })
          .catch(() => {
            //  setError(error?.message)
            fileInput.value = '';
          });
      }
    });
  }
  fileInput.click();
}

function getVideoUrl(url) {
  let match = url?.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/)
    || url?.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/)
    /* eslint-disable-next-line */
    || url?.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/);
  if (match && match?.[2]?.length === 11) {
    return `https://www.youtube.com/embed/${match[2]}?showinfo=0`;
  }
  match = url?.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/);
  if (match && match?.length > 2) { /* eslint-disable-line no-cond-assign */
    return `${(match[1] || 'https')}://player.vimeo.com/video/${match[2]}/`;
  }
  return null;
}

function videoHandler() {
  const self = this;
  /* eslint-disable-next-line */
  let url = prompt('Enter Video URL: ');
  url = getVideoUrl(url);
  if (url != null) {
    const range = self.quill.getSelection(true);
    self.quill.insertEmbed(range.index, 'video', url);
    self.quill.setSelection(range.index + 1);
  }
}

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic'],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      [{ align: [] }],
      ['link', 'image', 'video'],
    ],
    handlers: { image: imageHandler, video: videoHandler },
  },
  clipboard: { matchVisual: false },
  resize: {
    showSize: true,
    showToolbar: true,
    locale: {},
  },
};

const Editor = ({ width, content, onUpdate }) => (
  <ReactQuill
    value={content ?? ''}
    onChange={(value) => onUpdate(value)} // Arrow function body is now concise
    style={{ width, minHeight: '150' }}
    modules={modules}
    placeholder="Write your comment here..."
    theme="snow"
  />
);

Editor.propTypes = {
  width: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  content: PropTypes.func.isRequired,
};

export default React.memo(Editor);
