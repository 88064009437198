import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ApartmentIcon from '@mui/icons-material/Apartment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import companyService from '../../services/company.service';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import {
  ParentBox,
  ChildBox,
  GroupPageStyle,
} from './style';
import Breadcrumb from '../breadcrumbs/Breadcrumb';

const GroupPage = () => {
  const styles = GroupPageStyle();
  const [selected, setSelected] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [companydata, setCompanyData] = useState(null);
  const [company, setCompany] = useState(null);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const handleChange = (event) => {
    setSelected(event.target.value);
    const value = companydata?.find((val) => val.companyName === event.target.value);
    setCompany(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = async () => {
    try {
      const res = await companyService.getAll();
      setCompanyData(res);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    }
    setOpen(true);
  };
  return (
    <>
      <Box
        sx={{
          marginTop: '100px',
          marginLeft: 4,
          marginRight: 4,
        }}
      >
        <ParentBox>
          <Grid container rowSpacing={3}>
            <Grid
              item
              xs={12}
            >
              <Breadcrumb data={['Groups']} />
              <ChildBox>
                <item>
                  <Typography className={styles.itemHeadingBox} component="div">
                    <ApartmentIcon />
                    <Typography className={styles.itemHeading} variant="h6" component="div">
                      Group Page
                    </Typography>
                    <InfoIcon />
                  </Typography>
                </item>
              </ChildBox>
            </Grid>
          </Grid>
        </ParentBox>
        <Box>
          <Button color="primary" sx={{ display: 'block', mt: 2 }} onClick={handleOpen}>
            Select a Company
          </Button>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-controlled-open-select-label">Company</InputLabel>
            <Select
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={selected}
              label="Company"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {companydata && companydata.map((menuitem) => (
                <MenuItem value={menuitem.companyName} key={menuitem.companyId}>
                  {menuitem.companyName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {
            company && <item>
              <Button variant="contained" style={{ margin: '12px', marginLeft: '83%' }} onClick={() => { navigate(`/createGroup/${company?.companyId}`); }}>
                Create Group
              </Button>
            </item>
          }

          {
            company && <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Group Name</StyledTableCell>
                    <StyledTableCell>Group Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                {company?.groups?.map((item) => (
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell align="left">{item?.groupName}</StyledTableCell>
                      <StyledTableCell align="left">{item?.groupStatus}</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                ))}
              </Table>
            </TableContainer>

          }
        </Box>
        {
          snackbar.state
          && (
            <NavigateSnackbar
              snackbarObj={snackbar}
              setSnackbar={setSnackbar}
            />
          )
        }
      </Box>
    </>
  );
};

export default GroupPage;
