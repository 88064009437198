import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';

const Button = styled('button')(({ theme }) => ({
  margin: theme.spacing(0.6),
  ...theme,
  background: '#3f6398',
  borderRadius: '40px',
  color: 'white',
  width: '60%',
  padding: '15px',
  fontSize: '15px',
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
}));
const WelcomeTextStyling = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.primary.main,
  display: 'block',
  textAlign: 'center',
  borderBottom: '2px solid',
  borderColor: '#e2e5e8',
  paddingBottom: '10px',
  fontSize: '16px',
  fontWeight: 'bold',
}));
const ButtonStyle = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
  background: '#3f6398',
  borderRadius: '4px',
  color: 'white',
  padding: '15px',
  fontSize: '15px',
}));
const TextFieldBox = styled('div')(({ theme }) => ({
  width: '100%',
  margin: theme.spacing(1.2, 0),
}));
const CustomButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '40%',
    marginRight: theme.spacing(3),
  },
  borderRadius: '40px',
  width: '40%',
  justifyContent: 'center',
  marginTop: theme.spacing(3),
  background: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  marginLeft: '20px',
  '&:hover': {
    background: theme.palette.primary.main,
  },
}));
const ButtonText = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(0.6),
  color: '#fff',
  textTransform: 'capitalize',
  fontWeight: '600',
}));
const StatusTextStyle = styled('span')(() => ({
  padding: '5px',
  borderRadius: '5px',
}));
const ParentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100vw',
  },
}));

export {
  Button,
  Item,
  WelcomeTextStyling,
  ButtonStyle,
  TextFieldBox,
  CustomButton,
  ButtonText,
  ParentBox,
  StatusTextStyle,
};
