const validateEmail = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const validatePassword = RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[a-z]).{10,}$/);
const validName = RegExp(/^[a-zA-Z '.-]*$/);
const validatePhone = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function isValidReport(report) {
  const isValid = !!report && !!report.testingResults && report?.testingResults['testng-results'] && report?.testingResults['testng-results']?.suite;
  return isValid;
}

function isValidHtmlReport(report) {
  const isValid = !!report && !!report.testingResults && report?.testingResults?.suite;
  return isValid;
}

function isValidPlaywrightReport(report) {
  const isValid = !!report && !!report.reportResult && report?.reportResult.files[0].tests.length;
  return isValid;
}

function isValidCypressReport(report) {
  const isValid = !!report && !!report.reportResult
    && report?.reportResult?.dataRow?.results.length;
  return isValid;
}
function isValidReportResonse(response) {
  return !!response && response?.content && Array.isArray(response.content);
}

function replaceAmpersandWithAnd(inputString) {
  return inputString.replace(/&/g, 'and');
}

function passwordValidate(password) {
  const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
  return passwordRegex.test(password);
}

export default {
  validateEmail,
  validatePassword,
  validName,
  validatePhone,
  months,
  passwordValidate,
  replaceAmpersandWithAnd,
  isValidReport,
  isValidReportResonse,
  isValidPlaywrightReport,
  isValidCypressReport,
  isValidHtmlReport,
};
