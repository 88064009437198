import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import PieChart from 'highcharts-react-official';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { FileType } from '../../utils/constants';
import useHandleChipClick from '../../utils/FilterChipHook';

const chartOptions = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
  },
  title: {
    text: '',
    align: 'center',
    verticalAlign: 'middle',
    y: -45,
    useHTML: true,
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
  },
  tooltip: {
    pointFormat: '{point.name}: <b>{point.y}</b>',
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      innerSize: '85%', // Create a thick donut
      dataLabels: {
        enabled: false,
      },
      colors: ['#5FD726', '#FF0000', '#FFC72B', '#000000'], // Green, Red, Yellow, Black
      startAngle: 0,
      endAngle: 360,
      showInLegend: true,
      center: ['50%', '50%'],
      size: '100%',
    },
  },
  legend: {
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'vertical', // Set the layout to vertical
    symbolRadius: 50, // Remove the rounded corners of the legend symbol
    itemMarginBottom: 10, // Add some spacing between items
    itemStyle: {
      fontWeight: 'normal',
      fontSize: '12px',
    },
    useHTML: true, // Enable HTML formatting
    labelFormatter() {
      return `<div style="display: flex; justify-content: space-between; width: 100px;">
                <span>${this.name}</span>
                <span>${this.y}</span>
              </div>`;
    },
  },
  series: [{
    name: 'Tests',
    data: [],
  }],
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
};

function BuildChart({
  data,
  loading,
  reportType,
}) {
  const [chartData, setChartData] = useState(null);
  const handleChipClick = useHandleChipClick();
  useEffect(() => {
    if (data) {
      const dataList = {
        ...chartOptions,
        series: [{
          name: 'Tests',
          data: (() => {
            switch (reportType) {
              case FileType.XML:
              case FileType.SELENIUM:
                return [
                  { name: 'Passed', y: data.passed || 0 },
                  { name: 'Failed', y: data.failed || 0 },
                  { name: 'Skipped', y: data.skipped || 0 },
                  { name: 'Ignored', y: data.ignored || 0 },
                ];
              case FileType.PLAYWRIGHT:
                return [
                  { name: 'Passed', y: data.passed || 0 },
                  { name: 'Failed', y: data.failed || 0 },
                  { name: 'Skipped', y: data.skipped || 0 },
                  { name: 'Flaky', y: data.flaky || 0 },
                ];
              case FileType.CYPRESS:
                return [
                  { name: 'Passed', y: data.passed || 0 },
                  { name: 'Failed', y: data.failed || 0 },
                  { name: 'Skipped', y: data.skipped || 0 },
                  { name: 'Pending', y: data.pending || 0 },
                ];
              default:
                return [
                  { name: 'Passed', y: data.passed || 0 },
                  { name: 'Failed', y: data.failed || 0 },
                  { name: 'Skipped', y: data.skipped || 0 },
                  { name: 'Retried', y: data.retried || 0 },
                ];
            }
          })(),
          point: {
            events: {
              click: ((event) => {
                const { name } = event?.target?.point;
                handleChipClick(name);
              }),
            },
          },
        }],
        title: {
          ...chartOptions.title,
          text: `<div style="text-align: center;"><span style="font-size: 30px; font-weight: bold;">${data.total}</span></div>`,
        },
      };
      setChartData(dataList);
    }
  }, [data]);

  return (
    <div style={{ textAlign: 'center' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <PieChart
          highcharts={Highcharts}
          options={chartData}
        />
      )}
    </div>
  );
}

BuildChart.propTypes = {
  data: PropTypes.shape({
    failed: PropTypes.number,
    ignored: PropTypes.number,
    passed: PropTypes.number,
    skipped: PropTypes.number,
    pending: PropTypes.number,
    flaky: PropTypes.number,
    retried: PropTypes.number,
    total: PropTypes.number,
  }),
  loading: PropTypes.bool,
  reportType: PropTypes.string.isRequired,
};

BuildChart.defaultProps = {
  data: {},
  loading: false,
};

export default BuildChart;
