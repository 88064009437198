export function readableTime(seconds) {
  const levels = [
    [Math.floor(seconds / 31536000), 'years'],
    [Math.floor((seconds % 31536000) / 86400), 'days'],
    [Math.floor(((seconds % 31536000) % 86400) / 3600), 'hrs'],
    [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'min'],
    [(((seconds % 31536000) % 86400) % 3600) % 60, 'sec'],
  ];
  let returntext = '';

  for (let i = 0, max = levels.length; i < max; i += 1) {
    /* eslint no-continue: 0 */
    if (levels[i][0] === 0) continue;
    returntext += ` ${levels[i][0]} ${(levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length - 1) : levels[i][1])}`;
  }
  return returntext.trim();
}

export const COLORS = {
  lightGreen: '#10b981',
  lightRed: '#f04438',
};

export default { readableTime };
