import { styled } from '@mui/material/styles';
import { Paper, Box } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

const groupListStyle = makeStyles(() => createStyles({
  itemHeadingBox: {
    display: 'flex',
    alignItems: 'center',
    color: '#3f6398',
  },
  itemHeading: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  itemRightButtons: {
    textTransform: 'capitalize',
    color: '#fff',
  },
}));

const TextFieldBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    paddingTop: '20px',
    marginRight: theme.spacing(3),
  },
  marginLeft: theme.spacing(3),
  marginTop: theme.spacing(3),
  width: '90%',
}));
const Button = styled('Button')(({ theme }) => ({
  margin: theme.spacing(0.6),
  ...theme,
  background: '#3f6398',
  borderRadius: '40px',
  color: 'white',
  width: '100%',
  padding: '15px',
  fontSize: '15px',
  textTransform: 'capitalize',
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
}));
const WelcomeTextStyling = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.primary.main,
  display: 'block',
  textAlign: 'left',
  borderBottom: '2px solid',
  borderColor: '#e2e5e8',
  paddingBottom: '10px',
  fontSize: theme.fontSize.headerFontSize,
}));
const ButtonStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
  background: '#3f6398',
  borderRadius: '4px',
  color: 'white',
  padding: '15px',
  fontSize: '15px',
}));
const ParentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(12),
    marginLeft: theme.spacing(40),
  },
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(12),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));
const ChildBox = styled('Box')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  border: '1px solid #3f639859',
  padding: theme.spacing(1),
}));

export {
  TextFieldBox,
  Button,
  Item,
  WelcomeTextStyling,
  ButtonStyle,
  ParentBox,
  ChildBox,
  groupListStyle,
};
