import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Select,
  MenuItem,
  Collapse,
  AppBar,
  Typography,
  IconButton,
  Toolbar,
  Button,
  Badge,
  CssBaseline,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../assets/images/logo.svg';
import NewDashboard from '../BuildRun/BuildRun';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid #ececec',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    paddingTop: theme.spacing(3),
  },
  drawerContainer: {
    overflow: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(7.5),
    cursor: 'pointer',
  },
  selectBox: {
    width: '75%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: theme.spacing(1, 2, 1, 4),
    '& .MuiSelect-select': {
      padding: theme.spacing(1, 1),
    },
  },
  inActive: {
    color: '#000',
    cursor: 'pointer',
    borderBottom: '1px solid #ececec',
    width: '80%',
    margin: theme.spacing(1, 2),
  },
  active: {
    cursor: 'pointer',
    fontWeight: 'bolder',
    color: '#000',
    width: '80%',
    borderBottom: '1px solid #ececec',
    margin: theme.spacing(1, 2),
  },
  activeIcon: {
    color: '#000',
    borderBottom: '0',
  },
  inActiveIcon: {
    color: '#aeaeae',
    borderBottom: '0',
  },
  itemIcon: {
    minWidth: theme.spacing(4),
  },
  appBarInside: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  logoImage: {
    marginRight: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  contactButton: {
    backgroundColor: '#1d6ae5',
    boxShadow: 'none',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#165bb5',
    },
  },
  content: {
    flexGrow: 1,
  },
}));

const Sidebar = () => {
  const classes = useStyles();
  const [project, setProject] = React.useState('');
  const [open, setOpen] = React.useState(true);
  const [activeItem, setActiveItem] = React.useState('');
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleProjectChange = (event) => {
    setProject(event.target.value);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <div className={classes.drawerContainer}>
        <List>
          <ListItem
            className={activeItem === 'Home' ? classes.active : classes.inActive}
            onClick={() => handleItemClick('Home')}
          >
            <ListItemIcon className={classes.itemIcon}>
              <HomeIcon className={activeItem === 'Home' ? classes.activeIcon : classes.inActiveIcon} />
            </ListItemIcon>
            <ListItemText
              primary="Home"
              sx={{
                '& .MuiListItemText-primary': {
                  fontWeight: activeItem === 'Home' ? 'bold' : 'normal',
                },
              }}
            />
          </ListItem>
          <ListItem
            className={activeItem === 'Dashboards' ? classes.active : classes.inActive}
            onClick={() => handleItemClick('Dashboards')}
          >
            <ListItemIcon className={classes.itemIcon}>
              <DashboardIcon className={activeItem === 'Dashboards' ? classes.activeIcon : classes.inActiveIcon} />
            </ListItemIcon>
            <ListItemText
              primary="Dashboards"
              sx={{
                '& .MuiListItemText-primary': {
                  fontWeight: activeItem === 'Dashboards' ? 'bold' : 'normal',
                },
              }}
            />
          </ListItem>
          <ListItem onClick={handleClick} sx={{ cursor: 'pointer', pl: 4 }}>
            <ListItemIcon className={classes.itemIcon}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemIcon>
            <ListItemText primary="Invited projects" />
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Select
              value={project}
              onChange={handleProjectChange}
              displayEmpty
              className={classes.selectBox}
            >
              <MenuItem value="" disabled>
                Project Name
              </MenuItem>
              <MenuItem value="Project1">Build Run</MenuItem>
              <MenuItem value="Project2">Test Health</MenuItem>
              <MenuItem value="Project3">Unique Error</MenuItem>
              <MenuItem value="Project4">Testing Trends</MenuItem>
              <MenuItem value="Project5">Settings</MenuItem>
            </Select>
            {['Build Run', 'Test Health', 'Unique Error', 'Testing Trends', 'Settings'].map((text) => (
              <ListItem
                key={text}
                className={`${classes.nested}`}
                onClick={() => handleItemClick(text)}
              >
                <ListItemText
                  primary={text}
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontWeight: activeItem === text ? 'bold' : 'normal',
                      color: activeItem === text ? '#1d6ae5' : '',
                    },
                  }}
                />
              </ListItem>
            ))}
          </Collapse>
          <ListItem
            className={activeItem === 'User' ? classes.active : classes.inActive}
            onClick={() => handleItemClick('User')}
          >
            <ListItemIcon className={classes.itemIcon}>
              <PersonIcon className={activeItem === 'User' ? classes.activeIcon : classes.inActiveIcon} />
            </ListItemIcon>
            <ListItemText
              primary="User"
              sx={{
                '& .MuiListItemText-primary': {
                  fontWeight: activeItem === 'User' ? 'bold' : 'normal',
                },
              }}
            />
          </ListItem>
          <ListItem
            className={activeItem === 'Team' ? classes.active : classes.inActive}
            onClick={() => handleItemClick('Team')}
          >
            <ListItemIcon className={classes.itemIcon}>
              <GroupIcon className={activeItem === 'Team' ? classes.activeIcon : classes.inActiveIcon} />
            </ListItemIcon>
            <ListItemText
              primary="Team"
              sx={{
                '& .MuiListItemText-primary': {
                  fontWeight: activeItem === 'Team' ? 'bold' : 'normal',
                },
              }}
            />
          </ListItem>
        </List>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.appBarInside}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.logo}>
            <img src={Logo} alt="Logo" className={classes.logoImage} />
            <Typography variant="h6" color="inherit">
              testreport
            </Typography>
          </div>
          <div className={classes.actions}>
            <IconButton color="warning">
              <AccountCircleIcon />
            </IconButton>
            <Button variant="contained" className={classes.contactButton}>
              Contact Sales
            </Button>
            <IconButton>
              <Badge badgeContent={5} color="warning">
                <NotificationsIcon color="action" />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <NewDashboard />
      </main>
    </div>
  );
};

export default Sidebar;
