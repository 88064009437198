import axiosInstance from './axiosInstance';

class InviteReportService {
  async inviteForReport(payload) {
    try {
      const response = await axiosInstance.post('/testReportInvite/send', payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async inviteGuestForReport(payload) {
    try {
      const response = await axiosInstance.post('/public/sendReport', payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async testReportList() {
    try {
      const response = await axiosInstance.get('/testReportInvite/testReportInviteList');
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async viewTestReport(id, type) {
    try {
      const response = await axiosInstance.get(`/testReportInvite/viewTestReport/${id}/${type}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async viewNotification(id, type) {
    try {
      const response = await axiosInstance.get(`qa/view/${id}/${type}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }
}
export default new InviteReportService();
