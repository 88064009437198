import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const useSidePanelStyle = makeStyles(() => createStyles({
  itemHeadingBox: {
    display: 'flex',
    alignItems: 'center',
    color: '#3f6398',
  },
  tableHeader: {
    background: 'linear-gradient(146deg,#3f6398 8%,#3f6398 93%)',
  },
  itemHeading: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  itemRightButtons: {
    fontWeight: 'bold',
    background: 'linear-gradient(146deg,#3f6398 8%,#3f6398 93%)',
    textTransform: 'capitalize',
    color: '#fff',
  },
}));

const ParentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));
const ChildBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: 4,
  justifyContent: 'space-between',
  border: '1px solid #3f639859',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1.3),
}));
const Form = styled('form')(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));
const DialogContent = styled('div')(() => ({
  padding: '10px',
  width: '95%',
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export {
  ParentBox,
  ChildBox,
  DialogContent,
  Form,
  icon,
  checkedIcon,
  useSidePanelStyle,
};
