import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Dialog,
  TablePagination,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  Divider,
  DialogContent,
  DialogContentText,
  DialogActions,
  Avatar,
  TextField,
  Stack,
  Pagination,
  Input,
} from '@mui/material';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CloseIcon from '@mui/icons-material/Close';
import { Container } from '@mui/system';
import { styled } from '@mui/material/styles';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import inviteUserService from '../../services/inviteUser.service';
import {
  InviteStatus,
  UserPermissionsObject,
  UserRolesOject,
  UserPermission,
} from '../../utils/constants';
import { IconButtonStyle } from '../Modal/style';
import { useCommonContext } from '../header/context';
import userService from '../../services/user.service';
import InviteDialog from './InviteDialog';
import companyService from '../../services/company.service';
import { projectStyles } from '../header/style';
import ActionModal from '../ActionModal/ActionModal';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    borderBottom: `3px solid ${theme.palette.warning.main}`,
    borderLeft: `3px solid ${theme.palette.warning.main}`,
  },
}));

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  border: '0',
  '& .MuiTablePagination-toolbar': {
    padding: '0',
    width: '100%',
  },
  '& .MuiPagination-root': {
    width: '50%',
  },
  '& .MuiTablePagination-input': {
    border: '1px solid #e3e3e3',
    borderRadius: '4px',
    marginRight: theme.spacing(0),
  },
  '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
    margin: theme.spacing(0, 2),
  },
  '& .MuiTablePagination-actions': {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPagination-ul': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 'max-content',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: '8px',
    margin: theme.spacing(0.5),
    padding: '8px 12px',
    border: '1px solid #e0e0e0',
    '&.Mui-selected': {
      backgroundColor: '#1976d2', // Primary color for selected page
      color: '#fff',
      fontWeight: 'bold',
    },
    '&:hover': {
      color: '#000',
      backgroundColor: '#f5f5f5', // Hover effect for buttons
    },
  },
  '& .MuiPaginationItem-ellipsis': {
    border: 'none', // No border for ellipsis
    background: 'none',
  },
  '& .MuiPaginationItem-previousNext': {
    minWidth: '32px', // Ensure uniform size for the previous/next buttons
  },
}));

function InviteUser() {
  const classes = projectStyles();
  const { user, setActiveItem } = useCommonContext();
  const isSuperAdmin = user?.role?.userPermissionsList?.includes(UserPermission.COMPANY);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [invitesData, setInvitesData] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [opend, setOpend] = React.useState(false);

  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [open, setOpen] = React.useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [roleIdForEdit, setRoleIdForEdit] = useState('');
  const [currentRole, setSelectedRole] = useState();
  const [currentStatus, setCurrentStatus] = useState();
  const [eventId, setEventId] = useState();
  const [isBlockUser, setIsBlockUser] = useState(false);
  const [isActiveUser, setIsActiveUser] = useState(false);
  const [currUser, setCurrUser] = React.useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [openSwitchCompany, setOpenSwitchCompany] = React.useState(false);
  const [companyIdForSwitch, setCompanyIdForSwitch] = React.useState(null);
  const [companyIdForFilter, setCompanyIdForFilter] = React.useState(null);
  const [filterValues, setFilterValues] = useState({});
  const selectRef = useRef(null);
  const [selectKey, setSelectKey] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [search, setSearch] = useState('');

  const getRoleDetailsForEdit = (id) => {
    const result = roleList.find((i) => i.roleId === id);
    setSelectedRole(result);
  };

  const generateQuery = (filterParams = {}) => {
    let filter = '';
    let params = {};

    if (filterParams.firstName) {
      filter += `firstName|like|${filterParams.firstName}`;
    }

    if (filterParams.lastName) {
      filter += `&lastName|like|${filterParams.lastName}`;
    }

    if (filterParams.email) {
      filter += `&email|like|${filterParams.email}`;
    }

    if (filterParams.companyId) {
      filter += `&companyId|like|${filterParams.companyId}`;
    }

    if (filter) {
      params = {
        pageNumber: `${page - 1}`,
        pageSize: `${rowsPerPage}`,
        filterAnd: filter,
      };
    } else {
      params = {
        pageNumber: `${page - 1}`,
        pageSize: `${rowsPerPage}`,
      };
    }

    return {
      query: new URLSearchParams(params).toString(),
    };
  };

  const handleFilter = (type) => {
    setPage(1);
    const filterParams = {};
    if (type === 'apply') {
      if (firstName) {
        filterParams.firstName = firstName;
      }
      if (lastName) {
        filterParams.lastName = lastName;
      }
      if (email) {
        filterParams.email = email;
      }
      if (companyIdForFilter) {
        filterParams.companyId = companyIdForFilter;
      }
    }
    setFilterValues(filterParams);
  };

  const invitedUsers = async (filter) => {
    try {
      setLoading(true);
      const { query } = generateQuery(filter);
      const response = await inviteUserService.getAllInvitedUser(query);
      if (response) {
        if (response?.content?.length) {
          setInvitesData(response.content);
        } else {
          setSnackbar({
            navigateText: '',
            severity: 'info',
            state: true,
            message: 'No User found',
          });
        }
        setTotalPages(response?.totalPages);
      }
    } catch (error) {
      setInvitesData([]);
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filterValues) {
      invitedUsers(filterValues);
    }
  }, [filterValues]);

  const handleRoleChange = (event) => {
    setRoleIdForEdit(event.target.value);
    getRoleDetailsForEdit(event.target.value);
  };

  const handleCompanyChange = (event) => {
    setCompanyIdForSwitch(event.target.value);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleOpen = async (uEmail) => {
    try {
      const res = await userService.getUserByEmail(uEmail);
      setUserDetails(res);
      setSelectedRole(res?.role);
      setRoleIdForEdit(res?.role?.roleId);
      setOpen(true);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.message}`,
      });
    }
  };

  const handleCompanyFilter = (event) => {
    setCompanyIdForFilter(event.target.value);
  };

  const handleSwitchCompanyOpen = async (userEmail) => {
    try {
      const res = await userService.getUserByEmail(userEmail);
      setCurrUser(res);
      setCompanyIdForSwitch(res?.company?.companyId);
      setOpenSwitchCompany(true);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.message}`,
      });
    }
  };

  const closeSwitchCompany = () => {
    setOpenSwitchCompany(false);
  };

  const fetchRoleList = async () => {
    try {
      const res = await userService.getAllRole();
      setRoleList(res);
    } catch (error) {
      setRoleList([]);
    }
  };

  const fetchCompanyList = async () => {
    try {
      const res = await companyService.getAll();
      setCompanyList(res);
    } catch (error) {
      setCompanyList([]);
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const rolePayload = {
      email: userDetails?.email,
      roleId: roleIdForEdit,
    };
    try {
      await userService.userUpdateRole(rolePayload);
      invitedUsers(filterValues);
      fetchRoleList();
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: error?.data?.message,
      });
    } finally {
      onClose();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmitCompany = async (e) => {
    e.preventDefault();
    const rolePayload = {
      userId: currUser?.id,
      companyId: companyIdForSwitch,
    };
    try {
      const response = await userService.userSwitchCompany(rolePayload);
      invitedUsers(filterValues);
      setSnackbar({
        navigateText: '',
        severity: 'success',
        state: true,
        message: response,
      });
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: error?.data?.message,
      });
    } finally {
      closeSwitchCompany();
    }
  };

  const blockUser = async (item) => {
    setOpend(true);
    setEventId(item);
    setIsBlockUser(item.inviteStatus === InviteStatus.ACCEPT
      || item.inviteStatus === InviteStatus.ACTIVE);
    setIsActiveUser(item.inviteStatus === InviteStatus.EXPIRED
      || item.inviteStatus === InviteStatus.BLOCK
      || item.inviteStatus === InviteStatus.BLOCKED
      || item.inviteStatus === InviteStatus.PENDING);
    setCurrentStatus(item.inviteStatus === InviteStatus.ACCEPT
      ? InviteStatus.BLOCK : InviteStatus.ACCEPT);
  };
  const onHandleBlock = async () => {
    if (eventId) {
      const payload = {
        email: eventId.email,
        status: eventId.inviteStatus === InviteStatus.ACCEPT
          ? InviteStatus.BLOCKED : InviteStatus.ACCEPT,
      };
      try {
        // setLoading(true);
        const response = await inviteUserService.updateStatus(payload);
        invitedUsers(filterValues);
        setSnackbar({
          navigateText: '',
          severity: 'success',
          state: true,
          message: `${response}`,
        });
        setOpend(false);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error.message}`,
        });
        setOpend(false);
      } finally {
        setOpend(false);
      }
    }
  };

  useEffect(() => {
    invitedUsers(filterValues);
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchRoleList();
  }, []);

  useEffect(() => {
    if (openSwitchCompany) {
      fetchCompanyList();
    }
  }, [openSwitchCompany]);

  const handleClose = () => {
    setOpend(false);
    setIsBlockUser(false);
    setIsActiveUser(false);
  };

  const handleClear = () => {
    setCompanyIdForFilter(null);
    setFirstName('');
    setLastName('');
    setEmail('');
    setSelectKey((prevKey) => prevKey + 1);
    handleFilter('clear');
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  useEffect(() => {
    setActiveItem('User');
    fetchCompanyList();
  }, []);

  return (
    <Container maxWidth={false} className={classes.root}>
      <CssBaseline />
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" marginBottom={2}>
        <Typography color="#666666">Dashboard</Typography>
        <Typography color="#666666">Users</Typography>
      </Breadcrumbs>
      <Box className={classes.heading}>
        <Typography className={classes.title} variant="h4">
          Users
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} mt={4}>
          <Paper className={classes.summaryBox}>
            <Box className={classes.summaryBoxInTeam}>
              <Grid item xs={12}>
                <Grid
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap={4}
                  sx={{
                    py: 4,
                    px: 1.5,
                    marginBottom: 2,
                    borderBottom: '1px solid #e2e5e8',
                  }}
                >
                  <FormControl>
                    <InputLabel size="small">First Name</InputLabel>
                    <Input
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel size="small">Last Name</InputLabel>
                    <Input
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel size="small">Email</InputLabel>
                    <Input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                  <FormControl style={{ width: '200px' }}>
                    <InputLabel size="small">Company</InputLabel>
                    <Select
                      ref={selectRef}
                      key={selectKey}
                      size="small"
                      value={companyIdForFilter || ''}
                      label="Select Company"
                      onChange={handleCompanyFilter}
                      sx={{ borderRadius: '20px' }}
                    >
                      { companyList?.length ? companyList?.map((menuitem) => (
                        <MenuItem value={menuitem?.companyId} key={menuitem?.companyId}>
                          {menuitem?.companyName}
                        </MenuItem>
                      )) : <MenuItem style={{ color: '#999' }}>
                        No Company Found
                      </MenuItem>}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <Box display="flex" justifyContent="flex-end" gap={2}>
                      <Button variant="contained" onClick={() => handleFilter('apply')}>Search</Button>
                      {(companyIdForFilter || firstName || lastName || email) && <Box display="flex" justifyContent="flex-end" mx={0} alignItems="center">
                        <Typography onClick={handleClear} style={{ fontSize: '13px', color: '#393939', cursor: 'pointer' }}>
                          Clear All
                        </Typography>
                      </Box>}
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
              <TableContainer component={Paper} className={classes.tableContainer}>
                {user?.role?.userPermissionsList.includes(UserPermission.INVITE_USER)
                  ? <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.headerCell} />
                        <TableCell className={classes.headerCell}>Name</TableCell>
                        <TableCell className={classes.headerCell}>Email</TableCell>
                        <TableCell className={classes.headerCell}>Company</TableCell>
                        <TableCell className={classes.headerCell}>Role</TableCell>
                        <TableCell className={classes.headerCell}>Status</TableCell>
                        {user?.role?.userPermissionsList.includes(UserPermission.INVITE_USER)
                          && <TableCell className={classes.headerCell}>Actions</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? <>
                        <TableRow>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                        </TableRow>
                      </> : <>
                        {invitesData && !!invitesData?.length && invitesData?.map((row) => (
                          <TableRow key={row?.projectId}>
                            <TableCell>
                              <Avatar src={row.image} />
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">{`${row?.firstName} ${row?.lastName}`}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">{row?.email}</Typography>
                            </TableCell>
                            <TableCell>{row?.company?.companyName}</TableCell>
                            <TableCell>{UserRolesOject[row?.role?.roleName]}</TableCell>
                            <TableCell>{row?.inviteStatus}</TableCell>
                            {user?.role?.userPermissionsList.includes(UserPermission.INVITE_USER)
                              && <TableCell>
                                <Box display="flex" justifyContent="flex-start">
                                  <Tooltip title="Assign Permission">
                                    <IconButton
                                      style={{ color: '#1D6AE5' }}
                                      onClick={() => {
                                        handleOpen(row.email);
                                      }}
                                      disabled={row.inviteStatus === InviteStatus.PENDING
                                        || row.inviteStatus === InviteStatus.BLOCKED}
                                    >
                                      <AssignmentIndOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={row.inviteStatus === InviteStatus.ACCEPT
                                    ? 'Block' : 'Accept'}
                                  >
                                    <IconButton
                                      style={{ color: '#FFC72B' }}
                                      onClick={() => blockUser(row)}
                                    >
                                      {
                                        row.inviteStatus === InviteStatus.ACCEPT
                                          ? <BlockOutlinedIcon color="error" /> : <CheckCircleOutlineIcon color="success" />
                                      }
                                    </IconButton>
                                  </Tooltip>
                                  {isSuperAdmin && <Tooltip title="Switch Company">
                                    <IconButton
                                      style={{ color: '#1D6AE5' }}
                                      onClick={() => {
                                        handleSwitchCompanyOpen(row.email);
                                      }}
                                      disabled={row.inviteStatus === InviteStatus.PENDING
                                        || row.inviteStatus === InviteStatus.BLOCKED}
                                    >
                                      <SwapVertIcon />
                                    </IconButton>
                                  </Tooltip>}
                                </Box>
                              </TableCell>}
                          </TableRow>
                        ))}
                      </>}
                      <StyledDialog
                        open={open}
                        onClose={onClose}
                      >
                        <DialogTitle>
                          Grant Permissions
                        </DialogTitle>
                        <Divider />
                        <IconButtonStyle
                          aria-label="close"
                          data-testid="UploadReport-box"
                          onClick={onClose}
                        >
                          <CloseIcon />
                        </IconButtonStyle>
                        <form onSubmit={(e) => onSubmit(e)}>
                          <DialogContent>
                            <DialogTitle sx={{ px: '0', pb: 1, mb: 0 }}>
                              {`${userDetails.firstName} ${userDetails.lastName}`}
                            </DialogTitle>
                            <DialogContentText sx={{ mb: 3, px: '0', mr: '0' }}>
                              {userDetails.email}
                            </DialogContentText>
                            <FormControl fullWidth>
                              <InputLabel>Permissions</InputLabel>
                              {userDetails && Object.keys(userDetails).length > 0
                              && <Select
                                fullWidth
                                value={roleIdForEdit}
                                onChange={handleRoleChange}
                                label="Permissions"
                              >
                                {roleList && roleList?.map((role) => (
                                  <MenuItem value={role?.roleId} key={role?.roleId}>
                                    {role?.roleName}
                                  </MenuItem>
                                ))}
                              </Select> }
                            </FormControl>
                            <Grid container sx={{ mt: 2 }}>
                              <Grid item lg={3} xs={12}>
                                <FormControlLabel control={<Checkbox name="VIEW_REPORT" checked={Boolean(currentRole?.userPermissionsList?.includes('VIEW_REPORT'))} />} label={UserPermissionsObject.VIEW_REPORT} />
                                <FormControlLabel control={<Checkbox name="UPLOAD_REPORT" checked={Boolean(currentRole?.userPermissionsList?.includes('UPLOAD_REPORT'))} />} label={UserPermissionsObject.UPLOAD_REPORT} />
                                <FormControlLabel control={<Checkbox name="INVITE_USER" checked={Boolean(currentRole?.userPermissionsList?.includes('INVITE_USER'))} />} label={UserPermissionsObject.INVITE_USER} />
                              </Grid>
                              <Grid item lg={3} xs={12}>
                                <FormControlLabel control={<Checkbox name="ROLE" checked={Boolean(currentRole?.userPermissionsList?.includes('ROLE'))} />} label={UserPermissionsObject.ROLE} />
                                <FormControlLabel control={<Checkbox name="COMPANY" checked={Boolean(currentRole?.userPermissionsList?.includes('COMPANY'))} />} label={UserPermissionsObject.COMPANY} />
                                <FormControlLabel control={<Checkbox name="PROJECT" checked={Boolean(currentRole?.userPermissionsList?.includes('PROJECT'))} />} label={UserPermissionsObject.PROJECT} />
                              </Grid>
                              <Grid item lg={3} xs={12}>
                                <FormControlLabel control={<Checkbox name="INVITE_PROJECT" checked={Boolean(currentRole?.userPermissionsList?.includes('INVITE_PROJECT'))} />} label={UserPermissionsObject.INVITE_PROJECT} />
                                <FormControlLabel control={<Checkbox name="MAVEN_PLUGIN" checked={Boolean(currentRole?.userPermissionsList?.includes('MAVEN_PLUGIN'))} />} label={UserPermissionsObject.MAVEN_PLUGIN} />
                                <FormControlLabel control={<Checkbox name="GUEST_USER" checked={Boolean(currentRole?.userPermissionsList?.includes('GUEST_USER'))} />} label={UserPermissionsObject.GUEST_USER} />
                              </Grid>
                              <Grid item lg={3} xs={12}>
                                <FormControlLabel control={<Checkbox name="TEAM" checked={Boolean(currentRole?.userPermissionsList?.includes('TEAM'))} />} label={UserPermissionsObject.TEAM} />
                                <FormControlLabel control={<Checkbox name="INVITE_REPORT" checked={Boolean(currentRole?.userPermissionsList?.includes('INVITE_REPORT'))} />} label={UserPermissionsObject.INVITE_REPORT} />
                                <FormControlLabel control={<Checkbox name="SLACK_INTEGRATION" checked={Boolean(currentRole?.userPermissionsList?.includes('SLACK_INTEGRATION'))} />} label={UserPermissionsObject.SLACK_INTEGRATION} />
                              </Grid>
                              <Grid item lg={3} xs={12}>
                                <FormControlLabel control={<Checkbox name="JIRA_INTEGRATION" checked={Boolean(currentRole?.userPermissionsList?.includes('JIRA_INTEGRATION'))} />} label={UserPermissionsObject.JIRA_INTEGRATION} />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <Divider />
                          <DialogActions>
                            <Button type="submit" autoFocus color="primary" variant="contained">
                              Submit
                            </Button>
                            <Button autoFocus color="primary" variant="text" onClick={onClose}>
                              Cancel
                            </Button>
                          </DialogActions>
                        </form>
                      </StyledDialog>
                      <StyledDialog
                        open={openSwitchCompany}
                        onClose={closeSwitchCompany}
                      >
                        <DialogTitle>
                          Switch Company
                        </DialogTitle>
                        <Divider />
                        <IconButtonStyle
                          onClick={closeSwitchCompany}
                        >
                          <CloseIcon />
                        </IconButtonStyle>
                        <form onSubmit={(e) => onSubmitCompany(e)}>
                          <DialogContent>
                            <Stack sx={{ minWidth: '480px' }}>
                              <DialogTitle sx={{ p: '0px', mb: '10px' }}>
                                {`${currUser?.firstName} ${currUser?.lastName}`}
                              </DialogTitle>
                              <DialogContentText sx={{ mb: '24px' }}>
                                {currUser?.email}
                              </DialogContentText>
                              <TextField
                                label="Current Company"
                                InputProps={{
                                  readOnly: true,
                                }}
                                sx={{ mb: '24px' }}
                                value={currUser?.company?.companyName}
                              />
                              <FormControl sx={{ mb: '10px' }}>
                                <InputLabel>Switch Company</InputLabel>
                                <Select
                                  value={companyIdForSwitch}
                                  onChange={handleCompanyChange}
                                  label="Switch Company"
                                >
                                  {companyList && companyList?.map((comp) => (
                                    <MenuItem value={comp?.companyId} key={comp?.companyId}>
                                      {comp?.companyName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Stack>
                          </DialogContent>
                          <Divider />
                          <DialogActions>
                            <Button type="submit" autoFocus color="primary" variant="contained">
                              Submit
                            </Button>
                            <Button autoFocus color="primary" variant="text" onClick={closeSwitchCompany}>
                              Cancel
                            </Button>
                          </DialogActions>
                        </form>
                      </StyledDialog>
                    </TableBody>
                  </Table>
                  : <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.headerCell} />
                        <TableCell className={classes.headerCell}>Name</TableCell>
                        <TableCell className={classes.headerCell}>Email</TableCell>
                        <TableCell className={classes.headerCell}>Company</TableCell>
                        <TableCell className={classes.headerCell}>Role</TableCell>
                        <TableCell className={classes.headerCell}>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invitesData && invitesData?.length ? invitesData?.map((row) => (
                        <TableRow key={row?.projectId}>
                          <TableCell>
                            <Avatar src={row.image} />
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">{`${row?.firstName} ${row?.lastName}`}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">{row?.email}</Typography>
                          </TableCell>
                          <TableCell>{row?.company?.companyName}</TableCell>
                          <TableCell>{UserRolesOject[row?.role?.roleName]}</TableCell>
                          <TableCell>{row?.inviteStatus}</TableCell>
                        </TableRow>
                      )) : <TableRow>
                        <TableCell><Skeleton animation="wave" /></TableCell>
                        <TableCell><Skeleton animation="wave" /></TableCell>
                        <TableCell><Skeleton animation="wave" /></TableCell>
                        <TableCell><Skeleton animation="wave" /></TableCell>
                      </TableRow>}
                    </TableBody>
                  </Table>}
                {invitesData?.length > 9 ? (
                  <Box className={classes.paginationContainer}>
                    <StyledTablePagination
                      count={totalPages * rowsPerPage}
                      totalPages={totalPages}
                      page={page}
                      onPageChange={handleChangePage}
                      ActionsComponent={() => <CustomPagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                      />}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Box>
                ) : <></>}
              </TableContainer>
              {!loading && invitesData === null
                && <Typography textAlign="center" variant="subtitle1" color="textSecondary">
                  No user Found
                </Typography>}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {opend && (
        <ActionModal
          handleClose={handleClose}
          opend={opend}
          action={currentStatus}
          handleAction={() => onHandleBlock()}
          isBlock={isBlockUser}
          isActive={isActiveUser}
        />
      )}
      <InviteDialog
        isOpen={isDialogOpen}
        roleList={roleList}
        onClose={() => setIsDialogOpen(false)}
        onSuccess={
          () => {
            invitedUsers(filterValues);
            setIsDialogOpen(false);
          }
        }
        setSnackbar={setSnackbar}
      />
      {
        snackbar.state
        && (
          <NavigateSnackbar
            snackbarObj={snackbar}
            setSnackbar={setSnackbar}
          />
        )
      }
    </Container>
  );
}

export default InviteUser;
