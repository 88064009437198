import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import projectService from '../../services/project.service';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import route from '../../utils/route';
import {
  StyledGrid,
  StyledBox,
  StyledTypography,
  StyledButton,
} from './style';

function AcceptProjectInvite() {
  const { id } = useParams();
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const navigate = useNavigate();

  const acceptInvite = async () => {
    try {
      await projectService.acceptProjectInvite(id);
      navigate(`/${route.USER_INVITE_PROJECT}`);
    } catch (err) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err.data.message}`,
      });
    }
  };

  return (
    <div>
      <StyledBox>
        <StyledGrid container>
          <StyledTypography>Do you want to accept the Project Invite?</StyledTypography>
          <StyledButton autoFocus color="primary" variant="contained" onClick={acceptInvite}>
            Accept
          </StyledButton>
        </StyledGrid>
      </StyledBox>
      {
        snackbar.state
        && (
          <NavigateSnackbar
            snackbarObj={snackbar}
            setSnackbar={setSnackbar}
          />
        )
      }
    </div>
  );
}

export default AcceptProjectInvite;
