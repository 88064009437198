import route from './route';

const selectDropdownValues = [
  { text: 'New Profile', icon: 'person' },
  { text: 'All Reports', icon: 'assessment' },
];

const avatarFields = [
  { text: 'My Profile', icon: 'person', href: `/${route.ROUTE_PERSONALINFO}` },
  {
    text: 'Log Out', icon: 'logout', href: `/${route.ROUTE_LOGIN}`, isLogout: true,
  },
];

export { selectDropdownValues, avatarFields };
