import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import projectService from '../../services/project.service';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    borderBottom: `3px solid ${theme.palette.warning.main}`,
    borderLeft: `3px solid ${theme.palette.warning.main}`,
    alignItems: 'center',
    textAlign: 'center',
    padding: '45px',
  },
  '& .MuiDialogTitle-root': {
    fontWeight: 600,
    fontSize: '24px',
  },
  '.action-button': {
    backgroundColor: '#1d6ae5',
    boxShadow: 'none',
    textTransform: 'capitalize',
    padding: '16px',
    fontSize: '16px',
    fontWeight: 600,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#165bb5',
    },
  },
}));

export default function WaitingListModal({
  open,
  handleClose,
}) {
  const [confirmWaitingList, setConfirmWaitingList] = useState(false);

  const handleJoinWaitingList = async () => {
    try {
      const res = await projectService.getProjectRequestWaitingList();
      if (res) {
        setConfirmWaitingList(true);
      }
    } catch (error) {
      console.error('error: ', error?.message);
    }
  };

  return (
    <StyledDialog
      className="upload-report"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    // sx={{ width: '100%', maxWidth: '60%' }}
    >
      {!confirmWaitingList ? <>
        <DialogTitle>
          Create More Projects
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography color="#666666">
            {`You can only create one project at a time. Join the waiting list,
            and we'll let you know when you can add more.`}
          </Typography>
          <Box textAlign="center" mt={3}>
            <Button
              variant="contained"
              color="primary"
              className="action-button"
              onClick={handleJoinWaitingList}
            >
              Join the Waiting List
            </Button>
          </Box>
        </DialogContent>
      </> : <>
        <DialogTitle>
          {`You're on the
          Waiting List!`}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography color="#666666">
            {`Thanks for joining!
            We’ll notify you as soon as you can create another project.`}
          </Typography>
          <Box textAlign="center" mt={3}>
            <Button
              variant="contained"
              color="primary"
              className="action-button"
              onClick={handleClose}
            >
              Okay, Got It
            </Button>
          </Box>
        </DialogContent>
      </>}
    </StyledDialog>
  );
}
WaitingListModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

WaitingListModal.defaultProps = {
  open: PropTypes.bool,
};
