import { styled } from '@mui/styles';
import {
  Avatar,
  Button,
  Grid,
  Box,
} from '@mui/material';

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 0,
  },
  marginTop: theme.spacing(1),
  height: 110,
  width: 110,
  borderRadius: '100px',
  border: '2px solid #FFC72B',
}));
const InputParentGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 0,
    marginLeft: 0,
  },
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
}));
const ButtonStyle = styled(Button)(() => ({
  background: '#3f6398',
  borderRadius: '4px',
  color: 'white',
  width: '20%',
  padding: '6px',
  fontSize: '13px',
  marginLeft: '8px',
}));
const ButtonsStyle = styled(Button)(() => ({
  position: 'absolute',
  top: '50%',
  left: '18%',
  color: 'white',
  minWidth: '36px',
  height: '36px',
  borderRadius: '50%',
  padding: 0,
  textAlign: 'center',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  textTransform: 'none',
  zIndex: '1',
  '&:hover': {
    background: '#3f6398',
  },
  cursor: 'pointer',
}));
const ParentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100vw',
  },
  marginTop: theme.spacing(5),
}));

export {
  AvatarStyle,
  InputParentGrid,
  ButtonStyle,
  ButtonsStyle,
  ParentBox,
};
