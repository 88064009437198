import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { useDropzone } from 'react-dropzone';
import { uploadFile } from '../../services/uploadFile.service';
import Loader from '../../utils/loader/ProgressBar';
import { FileType } from '../../utils/constants';

import {
  ParentBox,
  InputParentGrid,
  DialogContentStyle,
  DialogStyle,
  ParentGrid,
  IconButtonStyle,
  ButtonStyle,
} from './style';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import companyService from '../../services/company.service';
import reportService from '../../services/report.service';
import { useCommonContext } from '../header/context';
import route from '../../utils/route';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    width: '100%',
    borderBottom: `3px solid ${theme.palette.warning.main}`,
    borderLeft: `3px solid ${theme.palette.warning.main}`,
  },
}));

function UploadReport({ anchorUploadUser, setAnchorUploadUser }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const [projectId, setprojectId] = useState(null);
  const [project, setProject] = useState(null);
  const [progress, setProgress] = useState();
  const [fileError, setFileError] = useState(false);
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const {
    setReportType,
    countNotification,
    setCountNotification,
  } = useCommonContext();

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
  } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      'text/html': ['.html'],
      'text/xml': ['.xml'],
    },
  });
  const files = acceptedFiles.map((file) => (
    <div key={file.path}>
      {file.path}
    </div>
  ));
  async function uploadFilesFunction() {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    if (!acceptedFiles[0]) {
      setFileError(true);
      return;
    }
    try {
      setLoading(true);
      const response = await uploadFile(formData, companyId, projectId, (udata) => {
        setProgress(Math.round((100 * udata.loaded) / udata.total));
      });
      setReportType(response?.fileType);
      setCountNotification(countNotification + 1);
      if (response?.fileType === FileType.XML || response?.fileType === FileType.HTML) {
        // navigate(response?.fileType === FileType.XML ?
        // `/reports/${response.id}` : `/htmlreports/${response.id}`);
        navigate(`/${route.ROUTE_DASHBOARD}`);
      }
      setAnchorUploadUser({ uploaded: true, show: false });
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    const companyList = async () => {
      try {
        const res = await companyService.getAll();
        setCompanyId(res[0].companyId);
        setCompanyDropdown(res);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error.message}`,
        });
      }
    };
    companyList();
  }, []);

  const handleCompany = (e) => {
    const getCid = e.target.value;
    setCompanyId(getCid);
  };

  const handleProject = (e) => {
    const getProjectid = e.target.value;
    setprojectId(getProjectid);
  };
  useEffect(() => {
    const getProjectName = async () => {
      if (companyId) {
        try {
          const res = await reportService.getProjectById(companyId);
          setProject(res);
        } catch (error) {
          setSnackbar({
            navigateText: '',
            severity: 'error',
            state: true,
            message: `${error.data.message}`,
          });
        }
      }
    };
    getProjectName();
  }, [companyId]);
  return (
    <StyledDialog
      open={anchorUploadUser.show}
      onClose={(event, reason) => {
        if (loading) {
          if (reason === 'backdropClick') return;
        }
        setAnchorUploadUser({ ...anchorUploadUser, show: false });
      }}
    >
      <DialogTitle>Upload Report</DialogTitle>
      <Divider className={DialogStyle.divider} />
      <IconButtonStyle
        aria-label="close"
        data-testid="UploadReport-box"
        open={anchorUploadUser.show}
        onClick={() => {
          if (loading) return;
          setAnchorUploadUser({ ...anchorUploadUser, show: false });
        }}
      >
        <CloseIcon />
      </IconButtonStyle>
      <DialogContentStyle>
        <ParentBox>
          <ParentGrid container>
            <InputParentGrid item>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label" style={{ color: 'black' }}>
                  Company
                </InputLabel> */}
                <Select
                  defaultValue=""
                  // label="Auto Detect"
                  disabled={loading}
                  value={companyId}
                  onChange={(e) => { handleCompany(e); }}
                >
                  {companyDropdown && companyDropdown.map((menuitem) => (
                    <MenuItem value={menuitem.companyId} key={menuitem.companyId}>
                      {menuitem.companyName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </InputParentGrid>
            <InputParentGrid>
              <FormControl fullWidth>
                <InputLabel>
                  Projects
                </InputLabel>
                <Select
                  defaultValue=""
                  label="Projects"
                  disabled={loading}
                  onChange={(e) => { handleProject(e); }}
                >
                  {project && project.map((menuitem) => (
                    <MenuItem value={menuitem.projectId} key={menuitem.projectId}>
                      {menuitem.projectName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </InputParentGrid>
            <InputParentGrid item xs>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Auto Detect
                </InputLabel>
                <Select
                  defaultValue=""
                  label="Auto Detect"
                  disabled={loading}
                >
                  <MenuItem value="xs">Auto Detect</MenuItem>
                  <MenuItem value="sm">Burp Cybersecurity Software</MenuItem>
                  <MenuItem value="lg">Cucumber</MenuItem>
                </Select>
              </FormControl>
            </InputParentGrid>
            <InputParentGrid style={{ border: '2px dashed #c1c1c1', borderRadius: '4px', padding: '20px 0' }}>
              <label
                htmlFor="contained-button-file"
                style={{
                  position: 'relative',
                  textAlign: 'center',
                }}
              >
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <ButtonStyle type="button" onClick={open}>
                    <CloudUploadIcon sx={{ mr: 1 }} />
                    Choose File
                  </ButtonStyle>
                </div>
                <aside>
                  <Typography variant="body1">{files}</Typography>
                </aside>

                <div
                  className="text-center"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {loading && <Loader progress={progress} />}
                </div>
              </label>
            </InputParentGrid>
            <InputParentGrid>
              <label
                htmlFor="contained-button-file"
                style={{
                  position: 'relative',
                  textAlign: 'center',
                }}
              >
                <div
                  className="text-center"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    type="button"
                    size="large"
                    starticon={<UploadFileIcon />}
                    disabled={!(companyId && projectId)}
                    onClick={() => uploadFilesFunction()}
                  >
                    Upload
                  </Button>
                  {fileError ? <span style={{ color: 'red' }}> Select a File </span> : null}
                </div>
              </label>
            </InputParentGrid>
          </ParentGrid>
        </ParentBox>
      </DialogContentStyle>
      {
        snackbar.state
        && (
          <NavigateSnackbar
            snackbarObj={snackbar}
            setSnackbar={setSnackbar}
          />
        )
      }
    </StyledDialog>
  );
}
UploadReport.propTypes = {
  anchorUploadUser: PropTypes.objectOf({
    show: PropTypes.bool,
    uploaded: PropTypes.bool,
  }),
  setAnchorUploadUser: PropTypes.func,
  notificationUsers: PropTypes.func,
};
UploadReport.defaultProps = {
  anchorUploadUser: {},
  setAnchorUploadUser: PropTypes.func,
  notificationUsers: PropTypes.func,
};

export default UploadReport;
