import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import {
  Grid,
  Chip,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Paper,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SegmentIcon from '@mui/icons-material/Segment';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import CommentWrapper from '../commentWrapper';
import RenderReportList from './RenderReportList';
import commentService from '../../services/comment.service';
import { DashboardEnum, TestStatus } from '../../utils/constants';
import { TestsPropType } from '../../Types/test';
import JiraWarpper from '../jiraWrapper';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const tagEnum = {
  PRODUCT_BUG: 'Product Bug',
  AUTOMATION_BUG: 'Automation Bug',
  SYSTEM_ISSUE: 'System Issue',
  TO_INVESTIGATE: 'To Investigate',
};

const backgroundEnum = {
  PRODUCT_BUG: '#ec3900',
  AUTOMATION_BUG: '#ffb743',
  SYSTEM_ISSUE: '#0274d1',
  TO_INVESTIGATE: 'green',
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
}));

const FullScreenModal = ({
  tests,
  id,
  selectedTestName,
  tagVal,
  guestUser,
  handleClose,
  open,
  project,
  setSnackbarData,
}) => {
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedChip, setSelectedChip] = useState(tagVal?.tagName || 'TO_INVESTIGATE');
  const [jiraTickets, setJiraTickets] = useState([]);

  const createComment = async (chip) => {
    try {
      setLoading(true);
      const commentData = {
        testName: selectedTestName,
        testReportId: id,
        comment: commentText,
        tagName: chip,
      };
      await commentService.create(commentData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChipClick = (chipName) => {
    let chip = null;
    if (selectedChip !== chipName) {
      setSelectedChip(chipName);
      chip = chipName;
    } else {
      setSelectedChip(null);
      chip = null;
    }
    createComment(chip);
  };

  const RenderTime = ({ data }) => {
    let result = `${data} ms`;

    if (data >= 1000 && data < 60000) {
      result = `${(data / 1000).toFixed(1)} s`;
    }
    if (data >= 60000) {
      result = `${(data / 60000).toFixed(1)} mins`;
    }
    return result;
  };

  const hasFailed = tests.some((test) => test.status === TestStatus.FAIL);

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" fontWeight={600} component="div">
              Execution Summary
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ flexGrow: 1, m: 4 }}>
          {tests.length === 0 ? (
            <p style={{ marginLeft: '6%' }}>No data found</p>
          ) : (
            tests.filter((test) => {
              if (Object.keys(test).filter((val) => val === 'exception')?.length) {
                return true;
              }
              return test?.name !== 'fetchSuiteConfiguration' && test?.name !== 'setUp' && test?.name !== 'tearDown';
            }).map((test) => {
              const resultStep = test?.['reporter-output'].line;
              return (<Grid container spacing={4}>
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="stretch"
                  >
                    <Item sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} direction="column">
                      <Grid display="flex" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          fontFamily="Open Sans"
                          textAlign="left"
                          color="#323232"
                          padding={2}
                          width={hasFailed ? '400px' : '550px'}
                          sx={{ wordBreak: 'break-word' }}
                        >
                          {test.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: 'text.secondary',
                            fontSize: '12px',
                          }}
                        >
                          <RenderTime data={test['duration-ms']} />
                        </Typography>
                      </Grid>
                      {hasFailed && <Chip
                        label={tagEnum[selectedChip]}
                        style={{
                          margin: '5px',
                          width: '140px',
                          height: '40px',
                          fontFamily: 'Open Sans',
                          textTransform: 'capitalize',
                          borderRadius: '9px',
                          fontSize: '10px',
                          fontWeight: 600,
                          // background: '#ec3900',
                          // color: '#fffff',
                          background: backgroundEnum[selectedChip],
                          color: '#fff',
                        }}
                      />}
                    </Item>
                    {test.status === TestStatus.PASS
                      && <Grid sx={{ width: '100%' }}>
                        <RenderReportList
                          titleIcon={<FormatQuoteIcon color="primary" fontSize="large" />}
                          title="Description"
                          description={test?.signature}
                          resultStep={resultStep}
                        />
                      </Grid>}
                    {(test.status === TestStatus.FAIL || test.status === TestStatus.SKIP)
                      && <Grid sx={{ width: '100%' }}>
                        <RenderReportList
                          titleIcon={<FormatQuoteIcon color="primary" fontSize="large" />}
                          title="Result Output"
                          description=""
                          resultStep={resultStep}
                        />
                        <RenderReportList
                          titleIcon={<FormatQuoteIcon color="primary" fontSize="large" />}
                          title="Description"
                          description={test?.signature}
                        />
                        <RenderReportList
                          titleIcon={<SegmentIcon color="primary" fontSize="large" />}
                          title="Exception name"
                          description={test?.exception?.class}
                        />
                        <RenderReportList
                          titleIcon={<SegmentIcon color="primary" fontSize="large" />}
                          title="Exception description"
                          description={test?.exception?.message}
                        />
                        <RenderReportList
                          titleIcon={<SegmentIcon color="primary" fontSize="large" />}
                          title="Exception backtrace"
                          description={test?.exception?.['full-stacktrace']}
                        />
                      </Grid>}
                    {(test.status !== TestStatus.FAIL
                      && test.status !== TestStatus.SKIP
                      && test.status !== TestStatus.PASS)
                      && <Typography
                        variant="body2"
                        color="text.secondary"
                        fontFamily="Space Mono"
                      >
                        Unable to extract data
                      </Typography>}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {hasFailed && (
                    <>
                      <Item>
                        <Typography
                          variant="subtitle1"
                          fontSize="15px"
                          fontWeight="bold"
                          fontFamily="Open Sans"
                          textAlign="left"
                          color="#323232"
                          p={1.3}
                        >
                          Select Defect Manually
                        </Typography>
                        <Grid display="flex" flexDirection="row" p={1}>
                          <Chip
                            label="Product Bug"
                            disabled={guestUser}
                            onClick={() => handleChipClick('PRODUCT_BUG')}
                            style={{
                              margin: '5px',
                              width: '140px',
                              height: '40px',
                              fontFamily: 'Open Sans',
                              textTransform: 'capitalize',
                              borderRadius: '9px',
                              fontSize: '10px',
                              fontWeight: 600,
                              // background: '#ec3900',
                              // color: '#fffff',
                              background: `${selectedChip === 'PRODUCT_BUG' ? '#ec3900' : '#f5eced'}`,
                              color: `${selectedChip === 'PRODUCT_BUG' ? '#fff' : '#000'}`,
                            }}
                          />
                          <Chip
                            label="Automation Bug"
                            disabled={guestUser}
                            onClick={() => handleChipClick('AUTOMATION_BUG')}
                            style={{
                              margin: '5px',
                              width: '140px',
                              height: '40px',
                              fontFamily: 'Open Sans',
                              textTransform: 'capitalize',
                              borderRadius: '9px',
                              fontSize: '10px',
                              fontWeight: 600,
                              // background: '#ffb743',
                              // color: '#fffff',
                              background: `${selectedChip === 'AUTOMATION_BUG' ? '#ffb743' : '#f5eced'}`,
                              color: `${selectedChip === 'AUTOMATION_BUG' ? '#fff' : '#000'}`,
                            }}
                          />
                          <Chip
                            label="System Issue"
                            disabled={guestUser}
                            onClick={() => handleChipClick('SYSTEM_ISSUE')}
                            style={{
                              margin: '5px',
                              width: '140px',
                              height: '40px',
                              fontFamily: 'Open Sans',
                              textTransform: 'capitalize',
                              borderRadius: '9px',
                              fontSize: '10px',
                              fontWeight: 600,
                              // background: '#0274d1',
                              // color: '#fffff',
                              background: `${selectedChip === 'SYSTEM_ISSUE' ? '#0274d1' : '#f5eced'}`,
                              color: `${selectedChip === 'SYSTEM_ISSUE' ? '#fff' : '#000'}`,
                            }}
                          />
                          <Chip
                            label="To Investigate"
                            disabled={guestUser}
                            onClick={() => handleChipClick('TO_INVESTIGATE')}
                            style={{
                              margin: '5px',
                              width: '140px',
                              height: '40px',
                              fontFamily: 'Open Sans',
                              textTransform: 'capitalize',
                              borderRadius: '9px',
                              fontSize: '10px',
                              fontWeight: 600,
                              background: `${selectedChip === 'TO_INVESTIGATE' ? 'green' : '#f5eced'}`,
                              color: `${selectedChip === 'TO_INVESTIGATE' ? '#fff' : '#000'}`,
                            }}
                          />
                        </Grid>
                      </Item>
                      <Item>
                        <JiraWarpper
                          project={project}
                          test={test}
                          id={id}
                          selectedTestName={selectedTestName}
                          setSnackbarData={setSnackbarData}
                          jiraTickets={jiraTickets}
                          setJiraTickets={setJiraTickets}
                          reportType={DashboardEnum.SELENIUM}
                        />
                      </Item>
                    </>
                  )}
                  <Item>
                    <Typography
                      variant="subtitle1"
                      fontSize="15px"
                      fontWeight="bold"
                      fontFamily="Open Sans"
                      textAlign="left"
                      color="#323232"
                      px={1.3}
                      pt={1.3}
                    >
                      Post Comment
                    </Typography>
                    <CommentWrapper
                      selectedTestName={selectedTestName}
                      testReportId={id}
                      setComments={setComments}
                      comments={comments}
                      loading={loading}
                      setLoading={setLoading}
                      setCommentText={setCommentText}
                      commentText={commentText}
                      selectedChip={selectedChip}
                      guestUser={guestUser}
                      jiraTickets={jiraTickets?.content}
                      setSnackbarData={setSnackbarData}
                    />
                  </Item>
                </Grid>
              </Grid>);
            })
          )}
        </Box>
      </Dialog>
    </>
  );
};

FullScreenModal.propTypes = {
  tests: TestsPropType,
  id: PropTypes.string,
  selectedTestName: PropTypes.string,
  tagVal: PropTypes.string,
  guestUser: PropTypes.bool,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  project: PropTypes.string,
  setSnackbarData: PropTypes.func,
};

FullScreenModal.defaultProps = {
  tests: [],
  id: '-1',
  selectedTestName: '',
  tagVal: '', // Changed to string, assuming it's always passed as string
  guestUser: false,
  handleClose: () => {},
  open: false,
  project: '',
  setSnackbarData: PropTypes.func,
};

export default FullScreenModal;
