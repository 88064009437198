import React, { useEffect, useState } from 'react';
import {
  TextField,
  Grid,
  Paper,
  Box,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  BelowForgotText,
  ButtonForgotText,
  LoginFormBox,
  WelcomeTextStyling,
  TextFieldBox,
  CustomButton,
  BelowForgotBox,
} from './style';
import route from '../../utils/route';
import useValidation from '../validation/Validation';
import userService from '../../services/user.service';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';

function Forgot() {
  const [userDetail, setUserDetail] = useState({ email: '' });
  const [loading, setLoading] = useState(false);
  const [navigateSnackbarState, setNavigateSnackbarState] = useState({
    navigateText: '',
    severity: '',
    state: false,
    message: '',
  });
  const navigate = useNavigate();
  const { error } = useValidation({
    userDetail,
  });
  const [errors, setErrors] = useState('');
  useEffect(() => {
    setErrors(error);
  }, [error]);

  const handelSubmit = async () => {
    const payload = {
      email: userDetail?.email,
    };
    try {
      setLoading(true);
      await userService.forgotPassword(payload);
      setNavigateSnackbarState({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Check Your Email.!',
      });
      setUserDetail({ email: '' });
    } catch (err) {
      setNavigateSnackbarState({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err.data.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  document.body.classList.add('login-layout');
  return (
    <Box>
      <Grid
        container
        display="flex"
        data-testid="login-box"
        justifyContent="center"
        height="100vh"
        alignItems="center"
      >
        <Grid
          sm={4}
          xs={12}
        >
          <LoginFormBox
            item
          >
            <Paper elevation={3} sx={{ py: 4 }}>
              <WelcomeTextStyling>
                Forgot your password?
              </WelcomeTextStyling>
              <BelowForgotBox>
                <BelowForgotText>
                  Please enter the email address associated with your account and We
                  will email you a link to reset your password.
                </BelowForgotText>
              </BelowForgotBox>
              <TextFieldBox>
                <TextField
                  fullWidth
                  error={errors.email}
                  helperText={errors.email ? 'Required Email' : null}
                  value={userDetail.email}
                  label="Email Address"
                  id="EmailAddress"
                  inputProps={{ 'data-testid': 'email-field' }}
                  onChange={(e) => setUserDetail({ email: e.target.value })}
                />
              </TextFieldBox>
              <Grid display="flex" justifyContent="center">
                {loading && <CircularProgress />}
              </Grid>
              {!loading && <Grid display="flex" justifyContent="center">
                <CustomButton
                  onClick={handelSubmit}
                  data-testid="reset-box"
                  disabled={!(userDetail?.email) || errors?.email}
                >
                  <ButtonForgotText>
                    SUBMIT
                  </ButtonForgotText>
                </CustomButton>
              </Grid>}
              <Grid display="flex" justifyContent="center">
                <CustomButton
                  onClick={() => { navigate(`/${route.ROUTE_LOGIN}`); }}
                >
                  <span className="material-icons">arrow_back</span>
                  <ButtonForgotText>
                    Back to login
                  </ButtonForgotText>
                </CustomButton>
              </Grid>
            </Paper>
          </LoginFormBox>
        </Grid>
      </Grid>
      {
        navigateSnackbarState.state
        && (
          <NavigateSnackbar
            snackbarObj={navigateSnackbarState}
            setSnackbar={setNavigateSnackbarState}
          />
        )
      }
    </Box>
  );
}
export default Forgot;
