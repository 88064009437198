import { styled } from '@mui/material/styles';
import {
  Button,
  Checkbox,
  Grid,
  Link,
} from '@mui/material';

const LoginFormGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'left',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  marginTop: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: theme.spacing(1),
}));
const LoginFormBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0.6),
    display: 'grid',
    width: 'auto',
    justifyContent: 'flex-start',
    alignItems: 'start',
  },
  marginTop: theme.spacing(5),
  display: 'block',
  textAlign: '-webkit-center',
  justifyContent: 'center',
}));
const BelowWelcomeText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: '16px',
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
  color: theme.palette.text.ternary,
}));
const CreateAccountText = styled(Link)(({ theme }) => ({
  color: theme.palette.text.createAccount,
  marginLeft: theme.spacing(0.5),
  cursor: 'pointer',
}));
const WelcomeTextStyling = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.primary.main,
  display: 'block',
  margin: theme.spacing(2, 0, 0, 2),
  textAlign: 'center',
  fontSize: theme.fontSize.headerFontSize,
}));
const TextFieldBox = styled('div')(({ theme }) => ({
  margin: theme.spacing(5, 5),
}));
const LogoBox = styled('div')(({ theme }) => ({
  height: '100vh',
  background: theme.palette.primary.main,
}));
const CheckboxBox = styled('div')(({ theme }) => ({
  width: '80%',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: theme.spacing(3),
  },
}));
const CheckBoxStyle = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.primary.CheckboxColor,
  '&.Mui-checked': {
    color: theme.palette.primary.CheckboxColor,
  },
}));
const CheckboxGroup = styled('div')(() => ({
  display: 'flex',
}));
const CheckboxLabel = styled('span')(({ theme }) => ({
  fontSize: theme.fontSize.subHeaderFontSize,
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
}));
const ForgotPassword = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.CheckboxColor,
  [theme.breakpoints.down('sm')]: {
    marginRight: theme.spacing(3),
  },
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  fontSize: theme.typography.fontSize,
}));
const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  justifyContent: 'center',
  background: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  margin: theme.spacing(0, 5),
  '&:hover': {
    background: theme.palette.primary.main,
  },
}));
const ButtonText = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(0.6),
}));
const LogoGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  display: 'block',
}));
export {
  BelowWelcomeText,
  LogoBox,
  ButtonText,
  LoginFormGrid,
  LogoGrid,
  WelcomeTextStyling,
  CreateAccountText,
  TextFieldBox,
  CheckboxLabel,
  CustomButton,
  CheckboxBox,
  CheckboxGroup,
  LoginFormBox,
  ForgotPassword,
  CheckBoxStyle,
};
