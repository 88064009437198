import axiosInstance from './axiosInstance';

class DashboardService {
  async getSummaryData(query) {
    try {
      const response = await axiosInstance.get(`/dashboard/getSummaryData?${query}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getUnstableTest(query) {
    try {
      const response = await axiosInstance.get(`/dashboard/getTopUnstableTest?${query}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getBuildSummary(query) {
    try {
      const response = await axiosInstance.get(`/dashboard/getBuildSummaryGraph?${query}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getPerformanceData(query) {
    try {
      const response = await axiosInstance.get(`/dashboard/getBuildPerformanceGraph?${query}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getStabilityData(query) {
    try {
      const response = await axiosInstance.get(`/dashboard/getStabilityGraph?${query}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getFlakinessData(query) {
    try {
      const response = await axiosInstance.get(`/dashboard/getFlakinessGraph?${query}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getTestStatusData(query) {
    try {
      const response = await axiosInstance.get(`/dashboard/getTestByStatusGraph?${query}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getFailureCategoriesData(query) {
    try {
      const response = await axiosInstance.get(`/dashboard/getFailureCategoriesGraph?${query}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }
}
export default new DashboardService();
