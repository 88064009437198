import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper, Box, Typography, Chip,
  Tooltip,
} from '@mui/material';
import CancelIcon from '../../assets/circle-xmark.svg';
import CheckIcon from '../../assets/check-circle.svg';
import { CustomPagination, homeStyles, StyledTablePagination } from '../header/style';
import useHandleChipClick from '../../utils/FilterChipHook';

const TestExecutionList = ({
  tests,
  totalPages,
  page,
  onTestClick,
  onPageChange,
  isPagination,
  handleChangeRowsPerPage,
  rowsPerPage,
}) => {
  const classes = homeStyles();
  const handleChipClick = useHandleChipClick();
  return (<>
    {tests?.length ? (
      tests.map((test) => {
        const chips = [
          { label: 'Passed', value: test.passed, className: classes.chipSuccess },
          { label: 'Failed', value: test.failed, className: classes.chipFailure },
          { label: 'Ignored', value: test.ignored, className: classes.chipIgnored },
          { label: 'Skipped', value: test.skipped, className: classes.chipSkipped },
        ];
        return (<Paper
          className={classes.latestTestExecution}
          sx={{ cursor: 'pointer' }}
          key={test.id}
          onClick={() => onTestClick(test.id, test.fileType)}
        >
          <Box className={classes.details}>
            <Box display="flex">
              <Typography variant="body1" className={classes.closeIcon}>
                {test.failed ? <img style={{ width: '20px' }} src={CancelIcon} alt="cancel-icon" /> : <img style={{ width: '20px' }} src={CheckIcon} alt="check-icon" />}
              </Typography>
              <Box>
                <Typography variant="body1" fontWeight={600}>
                  {test.testReportName}
                </Typography>
                <Typography variant="body2" color="textSecondary" my={1}>
                  Ran By
                  {` ${test?.customUserResponse?.username} `}
                  on
                  {` ${test.startedAt} `}
                </Typography>
                <Typography sx={{ display: 'flex', justifyContent: 'space-between' }} variant="body2" color="textSecondary" my={1}>
                  <span>
                    <strong>Company: </strong>
                    {test.companyName}
                  </span>
                  {' '}
                  <span>
                    <strong>Project: </strong>
                    {test.projectName}
                  </span>
                </Typography>

              </Box>
            </Box>
            <Box className={classes.chips}>
              {chips && chips?.map((item) => (
                <Tooltip key={item.label} title={item.label}>
                  <Chip
                    onClick={item.value > 0 ? () => handleChipClick(item.label) : undefined}
                    variant="outlined"
                    label={item.value}
                    className={item.className}
                  />
                </Tooltip>
              ))}
            </Box>
          </Box>
        </Paper>);
      })
    ) : (
      <Typography variant="body1" color="textSecondary" align="center">
        No Data Found
      </Typography>
    )}
    {!!tests?.length && isPagination && (
      <Box className={classes.paginationContainer}>
        <StyledTablePagination
          count={totalPages * rowsPerPage}
          totalPages={totalPages}
          page={page}
          onPageChange={onPageChange}
          ActionsComponent={() => <CustomPagination
            count={totalPages}
            page={page}
            onChange={onPageChange}
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
          />}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    )}
  </>);
};

export default TestExecutionList;

TestExecutionList.propTypes = {
  tests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      testReportName: PropTypes.string.isRequired,
      customUserResponse: PropTypes.shape({
        username: PropTypes.string.isRequired,
      }),
      startedAt: PropTypes.string.isRequired,
      passed: PropTypes.number.isRequired,
      failed: PropTypes.number.isRequired,
      skipped: PropTypes.number.isRequired,
      ignored: PropTypes.number.isRequired,
    }),
  ),
  totalPages: PropTypes.number,
  page: PropTypes.number,
  isPagination: PropTypes.bool,
  onTestClick: PropTypes.func,
  onPageChange: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
};

TestExecutionList.defaultProps = {
  tests: [],
  totalPages: 1,
  page: 1,
  isPagination: false,
  onTestClick: () => { },
  onPageChange: () => { },
  handleChangeRowsPerPage: () => { },
  rowsPerPage: 1,
};
