import React, { useState } from 'react';
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import commonUtils from '../../utils/common/common';

const PasswordDialog = ({ onSetPassword }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    if (password !== event.target.value) {
      setPasswordsMatch(false);
      setErrorMessage('Passwords not match');
    } else if (!commonUtils.passwordValidate(password)) {
      setPasswordsMatch(false);
      setErrorMessage('Password must be at least 8 characters long, contain an uppercase letter, and a symbol');
    } else {
      setPasswordsMatch(true);
      setErrorMessage('');
    }
  };

  const handleSetPassword = () => {
    onSetPassword(password, confirmPassword);
    setPassword('');
    setConfirmPassword('');
    setPasswordsMatch(true);
    setErrorMessage('');
  };

  return (
    <>
      <Grid container sx={{ p: 3 }}>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Typography variant="h6">
            Change Password
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              background: '#3f639838',
              padding: '15px',
              borderRadius: '8px',
              marginBottom: '10px',
            }}
          >
            <Typography variant="body1">
              <strong>Ensure that these requirements are met</strong>
              <br />
              Minimum 8 characters long, uppercase & symbol
            </Typography>
          </div>
        </Grid>
        <Grid container gap={2} spacing={2} mt={2}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <TextField
              sx={{ width: '50%' }}
              label="Password"
              value={password}
              type={showPassword ? 'text' : 'password'}
              onChange={handlePasswordChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <TextField
              sx={{ width: '50%' }}
              label="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={!passwordsMatch}
              helperText={errorMessage}
              type={showPassword ? 'text' : 'password'}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: '50%', marginInline: 'auto' }}>
              <Button variant="contained" onClick={handleSetPassword} disabled={!(password && confirmPassword) || !passwordsMatch}>
                Confirm Password
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PasswordDialog;
PasswordDialog.propTypes = {
  onSetPassword: PropTypes.func.isRequired,
};
