import axiosInstance from './axiosInstance';

class CompanyService {
  async addCompany(companyDetail) {
    try {
      const response = await axiosInstance.post('/company/createCompany', companyDetail);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getAllCompany(page, size) {
    try {
      const response = await axiosInstance.get(`/company/getAllCompany?pageNo=${page}&pageSize=${size}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getAll() {
    try {
      const response = await axiosInstance.get('/company/getAllByUser');
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getAllCompanyByCurrentUser() {
    try {
      const response = await axiosInstance.get('/projectInvite/getAllCompanyByCurrentUser');
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async deleteCompany(id) {
    try {
      const response = await axiosInstance.delete(`/company/delete/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getCompany(id) {
    try {
      const response = await axiosInstance.get(`/company/get/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async updateCompany(companydetail) {
    try {
      const response = await axiosInstance.post('/company/updateCompany', companydetail);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async updateCompanyStatus(payload) {
    try {
      const response = await axiosInstance.patch('/company/update/status', payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }
}
export default new CompanyService();
