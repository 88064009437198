import axiosInstance from './axiosInstance';

class GroupService {
  async inviteUserByEmail(inviteUser) {
    try {
      const response = await axiosInstance.post('/invite/teamMember', inviteUser);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async assignPermissions(payload) {
    try {
      const response = await axiosInstance.post('/roles/assignPermissions', payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async updateRoles(payload) {
    try {
      const response = await axiosInstance.post('/roles/update', payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getAllInvitedUser(query) {
    try {
      const response = await axiosInstance.get(`/invite/getAllInvitedUser?${query}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getInvitedUsersDistinct(page, size) {
    try {
      const response = await axiosInstance.get(`/projectInvite/projectInviteListByCurrentUser?pageNumber=${page}&pageSize=${size}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async verifyInvitedUser(id) {
    try {
      const response = await axiosInstance.get(`/invite/verifyLink=${id}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async accessToken(cid, pid) {
    try {
      const response = await axiosInstance.get(`/generate/pluginAccess/token?companyId=${cid}&projectId=${pid}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async updateStatus(payload) {
    try {
      const response = await axiosInstance.patch('/invite/user/update/status', payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }
}
export default new GroupService();
