import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (errors) => (
    Promise.reject(errors)
  ),
);

axiosInstance.interceptors.response.use((response) => response, (errors) => {
  if (errors?.response?.status === 401) {
    localStorage.removeItem('token');
    window.location.href = window.location.pathname;
  }
  return Promise.reject(errors);
});

axiosInstance.interceptors.request.use(
  (config) => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    // eslint-disable-next-line no-param-reassign
    config.headers['user-timezone'] = timeZone;

    return config;
  },
);

export default axiosInstance;
