import React, { useState, useEffect } from 'react';
import {
  Box,
  Breadcrumbs,
  CssBaseline,
  Grid,
  CardContent,
  Button,
  Typography,
  Card,
  Chip,
} from '@mui/material';
import { Container } from '@mui/system';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import reportservice from '../../services/report.service';
import { useCommonContext } from '../header/context';
import {
  UserPermission,
} from '../../utils/constants';
import { projectStyles } from '../header/style';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import SlackIcon from '../../assets/slack-svgrepo-com.svg';
import JiraIcon from '../../assets/images/jira-logo.png';

function Integrations() {
  const {
    user,
    setNotification,
    setActiveItem,
  } = useCommonContext();
  const classes = projectStyles();
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [page, setPage] = React.useState(0);
  // const [totalCount, setTotalCounts] = useState(0);
  const page = 0;
  const rowsPerPage = 10;
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });

  const projectInviteCurrentUser = async () => {
    try {
      const res = await reportservice.projectsInviteByCurrentUser(page, rowsPerPage);
      if (res) {
        // setProjectsName(res.content);
        // setCompanysId(res.content);
        setNotification(res.content);
        // setTotalCounts(res.totalElements);
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    }
  };

  const getAllProjects = async () => {
    try {
      const res = await reportservice.getAllProjects(page, rowsPerPage);
      if (res) {
        // setProjectsName(res.content);
        // setCompanysId(res.content);
        // setTotalCounts(res.totalElements);
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    }
  };

  useEffect(() => {
    if (user?.role?.userPermissionsList.includes(UserPermission.PROJECT)) {
      getAllProjects(page, rowsPerPage);
    } else if (user?.role?.userPermissionsList) {
      projectInviteCurrentUser(page, rowsPerPage);
    }
  }, [user?.role?.roleId, page, rowsPerPage]);

  useEffect(() => {
    if (user?.role?.userPermissionsList.includes(UserPermission.PROJECT)) {
      getAllProjects();
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    setActiveItem('Integration');
  }, []);

  return (
    <>
      <Container maxWidth={false} className={classes.root}>
        <CssBaseline />
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" marginBottom={2}>
          <Typography color="#666666">Dashboard</Typography>
          <Typography color="#666666">Integration</Typography>
        </Breadcrumbs>
        <Box className={classes.heading}>
          <Typography className={classes.title} variant="h4">
            Integration
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={4}>
            <Box sx={{ padding: '20px' }}>
              <Grid container spacing={2}>
                {/* Slack Card */}
                <Grid item xs={12} sm={2}>
                  <Card variant="outlined">
                    <Chip
                      label="Coming Soon"
                      color="warning"
                      size="small"
                      sx={{
                        // position: 'absolute',
                        top: 10,
                        right: 10,
                        fontWeight: 600,
                      }}
                    />
                    <CardContent>
                      <Grid container direction="column" alignItems="flex-start" pl={1}>
                        <img width="50px" height="50px" src={SlackIcon} alt="slack" />
                        <Typography variant="h6" fontWeight={600} align="center" mt={1}>
                          Slack
                        </Typography>
                        <Typography variant="body2" align="center" color="textSecondary">
                          Not Configured
                        </Typography>
                        <Button disabled variant="contained" color="primary" sx={{ marginTop: '10px' }}>
                          Configure
                        </Button>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Jira Card */}
                <Grid item xs={12} sm={2}>
                  <Card variant="outlined">
                    <Chip
                      label="Coming Soon"
                      color="warning"
                      size="small"
                      sx={{
                        // position: 'absolute',
                        top: 10,
                        right: 10,
                        fontWeight: 600,
                      }}
                    />
                    <CardContent>
                      <Grid container direction="column" alignItems="flex-start">
                        <img width="50px" height="50px" src={JiraIcon} alt="jira" />
                        <Typography variant="h6" fontWeight={600} align="center" mt={1}>
                          Jira
                        </Typography>
                        <Typography variant="body2" align="center" color="textSecondary">
                          Not Configured
                        </Typography>
                        <Button disabled variant="contained" color="warning" sx={{ marginTop: '10px' }}>
                          Configure
                        </Button>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {
          snackbar.state
          && (
            <NavigateSnackbar
              snackbarObj={snackbar}
              setSnackbar={setSnackbar}
            />
          )
        }
      </Container>
    </>
  );
}

export default Integrations;
