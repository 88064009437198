import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogTitle,
  Divider,
  TableContainer,
  Paper,
  TableRow,
  TableHead,
  TableBody,
  Table,
  Chip,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import {
  StyledTableCell, StyledTableRow, StyledDailogAction,
} from './style';
import { IconButtonStyle } from '../Modal/style';
import Loader from '../../utils/loader/Loader';
import projectService from '../../services/project.service';
import { InviteStatus } from '../../utils/constants';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import { useCommonContext } from '../header/context';

export default function ScrollDialog(
  {
    openDailog,
    projectList,
    onOpen,
    projectsLoading,
    setISUserStatusChanged,
    isUserStatusChanged,
  },
) {
  const { user } = useCommonContext();
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [currUserProjects, setCurrUserProject] = useState([]);
  const onClose = () => {
    onOpen(false);
  };
  const descriptionElementRef = React.useRef(null);
  useEffect(() => {
    if (openDailog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (Array.isArray(user?.company?.projectResponses)) {
      setCurrUserProject(user?.company?.projectResponses?.map((i) => i.projectId));
    }
  }, [openDailog]);
  const blockProjectForUser = async (item) => {
    const payload = {
      projectInviteStatus: item.projectInviteStatus === InviteStatus.ACCEPT
        ? InviteStatus.BLOCKED : InviteStatus.ACCEPT,
    };
    try {
      onOpen(false);
      const response = await projectService.blockProjectForUser(item.projectInviteId, payload);
      setISUserStatusChanged(!isUserStatusChanged);
      setSnackbar({
        navigateText: '',
        severity: 'success',
        state: true,
        message: `${response}`,
      });
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.message}`,
      });
    }
  };

  return (
    <div>
      <Dialog
        open={openDailog}
        fullWidth
        onClose={onClose}
      >
        <DialogTitle>
          Invited Projects
        </DialogTitle>
        <Divider />
        <DialogContent>
          {projectList && <TableContainer component={Paper}>
            <Table>
              {projectsLoading ? <Loader /> : <TableHead>
                <TableRow sx={{ background: 'linear-gradient(146deg,#3f6398 8%,#3f6398 93%)' }}>
                  <StyledTableCell>Project</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>}
              <TableBody>
                <>
                  {
                    projectList && projectList?.map((item) => (
                      <StyledTableRow key={item.userId}>
                        <StyledTableCell>{item.projectName}</StyledTableCell>
                        <StyledTableCell>
                          <Chip label={item?.projectInviteStatus} sx={{ width: '90px' }} style={{ backgroundColor: item.projectInviteStatus === 'ACCEPT' ? '#10b981' : '#f04438', color: '#fff' }} />
                        </StyledTableCell>
                        {user?.role?.userPermissionsList.includes('INVITE_USER')
                          || (user?.role?.userPermissionsList.includes('PROJECT')
                            && (currUserProjects?.length > 0
                              && currUserProjects.includes(item.projectId))
                            && item.invitedById === user?.id)
                          ? <StyledTableCell>
                            <Button
                              style={{
                                cursor: 'pointer',
                              }}
                              startIcon={
                                item.projectInviteStatus === InviteStatus.ACCEPT
                                  ? <BlockIcon /> : <CheckCircleOutlineIcon />
                              }
                              color={item.projectInviteStatus
                                === InviteStatus.BLOCKED ? 'success' : 'error'}
                              variant="outlined"
                              disabled={item?.projectStatus === InviteStatus.BLOCKED}
                              onClick={
                                () => blockProjectForUser(item)
                              }
                            >
                              {
                                item.projectInviteStatus === InviteStatus.ACCEPT
                                  ? InviteStatus.BLOCK : InviteStatus.ACCEPT
                              }
                            </Button>
                          </StyledTableCell> : <StyledTableCell />}
                      </StyledTableRow>
                    ))
                  }
                </>
              </TableBody>
            </Table>
          </TableContainer>}
        </DialogContent>
        <IconButtonStyle
          onClick={() => onOpen(false)}
        >
          <CloseIcon />
        </IconButtonStyle>
        <StyledDailogAction>
          <Button autoFocus color="primary" variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </StyledDailogAction>
        <DialogContent />
      </Dialog>
      {
        snackbar.state
        && (
          <NavigateSnackbar
            snackbarObj={snackbar}
            setSnackbar={setSnackbar}
          />
        )
      }
    </div>
  );
}

ScrollDialog.propTypes = {
  openDailog: PropTypes.bool,
  projectsLoading: PropTypes.bool,
  projectList: PropTypes.instanceOf(Array),
  onOpen: PropTypes.func,
  setISUserStatusChanged: PropTypes.bool,
  isUserStatusChanged: PropTypes.bool,
};

ScrollDialog.defaultProps = {
  openDailog: false,
  projectsLoading: PropTypes.bool,
  onOpen: PropTypes.func,
  projectList: PropTypes.instanceOf(Array),
  setISUserStatusChanged: PropTypes.bool,
  isUserStatusChanged: PropTypes.bool,
};
