import axiosInstance from './axiosInstance';

const fetchKeyInsightsSeleniumXML = async (query) => {
  try {
    const response = await axiosInstance.get(`/qa/keyInsight/selenium-xml?${query}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const fetchKeyInsightsSeleniumHTML = async (query) => {
  try {
    const response = await axiosInstance.get(`/qa/keyInsight/selenium-html?${query}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const fetchKeyInsightsPlaywright = async (query) => {
  try {
    const response = await axiosInstance.get(`/playwright/keyInsight/playwright?${query}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const fetchKeyInsightsCypress = async (query) => {
  try {
    const response = await axiosInstance.get(`cypress/keyInsight/cypress?${query}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

export {
  fetchKeyInsightsSeleniumXML,
  fetchKeyInsightsPlaywright,
  fetchKeyInsightsCypress,
  fetchKeyInsightsSeleniumHTML,
};
