import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Breadcrumb from '../breadcrumbs/Breadcrumb';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import {
  ParentBox,
  ChildBox,
  GroupDetailStyle,
  ButtonStyle,
} from './style';
import route from '../../utils/route';
import groupService from '../../services/group.service';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function GroupDetail() {
  const { id } = useParams();
  const styles = GroupDetailStyle();
  const [groupDetail, setGroupDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const navigate = useNavigate();

  useEffect(() => {
    async function getGroupDetail() {
      try {
        setLoading(true);
        const res = await groupService.getGroupById(id);
        setGroupDetail(res);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error.message}`,
        });
      } finally {
        setLoading(false);
      }
    }
    getGroupDetail();
  }, [id]);
  return (
    <>
      (
      <Box
        sx={{
          marginTop: '100px',
          marginLeft: 4,
          marginRight: 4,
        }}
      >
        <ParentBox>
          <Grid container rowSpacing={3}>
            <Grid
              item
              xs={12}
            >
              <Breadcrumb data={['GroupDetail']} />
              <item>
                <Button variant="contained" style={{ margin: '12px', marginLeft: '83%' }} startIcon={<AddIcon />} onClick={() => { navigate(`/createProfile/${id}`); }}>
                  Create Profile
                </Button>
              </item>
              <ChildBox>
                <item>
                  <Typography className={styles.itemHeadingBox} component="div">
                    <ApartmentIcon />
                    <Typography className={styles.itemHeading} variant="h6" component="div">
                      Group Detail
                    </Typography>
                    <InfoIcon />
                  </Typography>
                </item>
              </ChildBox>
            </Grid>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Group Name</StyledTableCell>
                    <StyledTableCell>Group Created</StyledTableCell>
                    <StyledTableCell>Group Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left">{groupDetail?.groupName}</StyledTableCell>
                    <StyledTableCell align="left">{groupDetail?.createAt}</StyledTableCell>
                    <StyledTableCell align="left">{groupDetail?.groupStatus}</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <ButtonStyle variant="contained" onClick={() => { navigate(`/${route.GROUP_LIST}`); }}> BACK </ButtonStyle>
          </Grid>
        </ParentBox>
        {loading && <CircularProgress style={{ marginLeft: '75px' }} />}
        {
          snackbar.state
          && (
            <NavigateSnackbar
              snackbarObj={snackbar}
              setSnackbar={setSnackbar}
            />
          )
        }
      </Box>
      )
    </>
  );
}

export default GroupDetail;
