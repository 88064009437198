import * as React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getDuration } from '../Home/TestData';

const useStyles = makeStyles((theme) => ({
  iconError: {
    fontSize: '12px',
    color: '#FF0000',
    marginRight: theme.spacing(1),
  },
  iconSuccess: {
    fontSize: '12px',
    color: '#5FD726',
    marginRight: theme.spacing(1),
  },
}));

const renderSteps = (isError, steps) => steps?.map((step) => (
  <Accordion sx={{ '&:before': { backgroundColor: 'transparent' } }} key={step.title} elevation={0}>
    <AccordionSummary
      disabled={!step.steps.length}
      sx={{
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg) !important',
          minHeight: '0px !important',
        },
        flexDirection: 'row-reverse',
        alignItems: 'center',
        opacity: '1 !important',
      }}
      expandIcon={step.steps.length ? <ChevronRightIcon /> : null}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '80%' }}>
          <CircleIcon
            className={isError ? useStyles().iconError : useStyles().iconSuccess}
          />
          <Typography ml={0.5}>{step?.title}</Typography>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails sx={{ p: 0, pl: 1 }}>
      {step.steps && step.steps.length > 0 && renderSteps(isError, step.steps)}
    </AccordionDetails>
  </Accordion>
));

const renderAccordionForTest = (steps) => {
  if (!Array.isArray(steps)) {
    return null;
  }
  return <div>
    {steps && steps?.map((item) => (
      <Accordion sx={{ '&:before': { backgroundColor: 'transparent' } }} key={item.title} elevation={0}>
        <AccordionSummary
          disabled={!item.steps.length}
          sx={{
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(90deg) !important',
              minHeight: '0px !important',
            },
            flexDirection: 'row-reverse',
            alignItems: 'center',
            opacity: '1 !important',
          }}
          expandIcon={item.steps.length ? <ChevronRightIcon /> : null}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '80%' }}>
              <CircleIcon
                className={item.error ? useStyles().iconError : useStyles().iconSuccess}
              />
              <Typography ml={0.5}>
                {item?.title}
              </Typography>
            </Box>
            <Typography>
              {getDuration(item.duration || 0)}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, pl: 1 }}>
          {item.steps && item.steps.length > 0 && renderSteps(!!item.error, item.steps)}
        </AccordionDetails>
      </Accordion>
    ))}
  </div>;
};
const renderScreenShots = (screenshot) => (
  <Box>
    <a href={`${process.env.REACT_APP_BACKEND_BASE_URL}/user/getImage/SCREENSHOT/${screenshot?.path?.split('data/')[1]}`} style={{ textDecoration: 'none', color: '#333' }}>
      <Box sx={{ maxWidth: '100%', marginTop: 1 }}>
        <img width="100%" src={`${process.env.REACT_APP_BACKEND_BASE_URL}/user/getImage/SCREENSHOT/${screenshot?.path?.split('data/')[1]}`} alt={screenshot.name} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', m: 2 }}>
        <SummarizeIcon sx={{ color: '#1d6ae5', mr: 2 }} />
        <Typography variant="body1" sx={{ textDecoration: 'underline', color: 'blue', ml: 0.5 }}>
          {screenshot?.name}
        </Typography>
      </Box>
    </a>
  </Box>
);

const renderAccordinForAttachment = (attachment) => (<Accordion sx={{ '&:before': { backgroundColor: 'transparent' } }} elevation={0}>
  <AccordionDetails sx={{ p: 0 }}>
    <Box sx={{}}>
      {attachment?.body?.split('\n')?.map((line) => (
        <Typography
          variant="body2"
          sx={{
            color: '#333',
          }}
        >
          {line}
        </Typography>
      ))}
    </Box>
  </AccordionDetails>
</Accordion>
);

export { renderAccordionForTest, renderScreenShots, renderAccordinForAttachment };
