import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { fetchGoogleData } from '../../utils/google';
import socialLogin from '../../services/socialLogin';
import OnBoardingPage from '../onBoarding/OnBoarding';
import Loader from '../../utils/loader/Loader';
import route from '../../utils/route';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';

const Redirect = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const code = searchParams.get('code');
  const error = searchParams.get('error');
  const [navigateSnackbarState, setNavigateSnackbarState] = useState({
    navigateText: '',
    severity: '',
    state: false,
    message: '',
  });

  const googledata = async (codex) => {
    try {
      setLoading(true);
      const res = await fetchGoogleData(codex);
      if (res) {
        const gUser = jwtDecode(res.id_token);
        const googleResp = await socialLogin({ googleId: gUser?.sub, googleToken: res?.id_token });
        if (googleResp?.userResponse?.firstLogin) {
          setIsFirstLogin(true); // Set first login state
        } else {
          navigate(`/${route.ROUTE_HOME_PAGE}`);
        }
      }
    } catch (err) {
      setNavigateSnackbarState({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err?.data?.message || 'An error occurred'}`,
      });
      navigate(`/${route.ROUTE_LOGIN}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (code) {
      googledata(code);
    }
    if (error) {
      navigate(`/${route.ROUTE_LOGIN}`);
    }
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      {isFirstLogin && <OnBoardingPage />}
      {
        navigateSnackbarState.state
        && (
          <NavigateSnackbar
            snackbarObj={navigateSnackbarState}
            setSnackbar={setNavigateSnackbarState}
          />
        )
      }
    </>
  );
};

export default Redirect;
